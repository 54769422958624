import axios from 'axios';

// a lookup of our keys to junip definition ids
const ATTRIBUTE_RECORD_LOOKUP = {
	heightWeight: [874],
	sizePurchased: [875, 888, 890, 892, 894, 898, 900],
	usuallyWears: [876, 889, 891, 893, 895, 897, 899, 901],
};

const TRAIT_RATING_LOOKUP = {
	fit: ['fit'],
};

const formatAttributeRecord = (attributeRecords, key) => {
	const definitionIds = ATTRIBUTE_RECORD_LOOKUP[key];

	const foundRecord = attributeRecords.find((attributeRecord) => {
		return definitionIds.includes(attributeRecord.definition_id);
	});

	return foundRecord?.value;
};

const formatLinearTrait = (traitRatings, key) => {
	const traitRatingNames = TRAIT_RATING_LOOKUP[key];

	const foundTrait = traitRatings.find((traitRating) => {
		if (!traitRating?.name) {
			return false;
		}

		return traitRatingNames.includes(traitRating.name.toLowerCase());
	});

	// for some reason accessing properties on this object crashes?
	const uhReformat = { ...foundTrait };

	const rating = uhReformat.rating || uhReformat.rating_average;

	if (typeof rating !== 'number') {
		return null;
	}

	if (rating < 2) {
		return uhReformat.label_left;
	}

	if (rating > 4) {
		return uhReformat.label_right;
	}

	return uhReformat.label_center;
};

const formatReview = (review) => {
	const formatted = {
		attributeRecords: {},
		body: review.body,
		createdAt: review.created_at,
		customer: {},
		id: review.id,
		isVerified: review.verified_buyer === 'Verified Buyer',
		rating: review.rating,
		productTitle: review.target_title,
		title: review.title,
		traitRatings: {},
	};

	if (review.customer) {
		formatted.customer.nameFirst = review.customer.first_name;
		formatted.customer.nameLast = review.customer.last_name;
	}

	if (review.attribute_records) {
		formatted.attributeRecords.heightWeight = formatAttributeRecord(
			review.attribute_records,
			'heightWeight',
		);

		formatted.attributeRecords.sizePurchased = formatAttributeRecord(
			review.attribute_records,
			'sizePurchased',
		);

		formatted.attributeRecords.usuallyWears = formatAttributeRecord(
			review.attribute_records,
			'usuallyWears',
		);
	}

	if (review.trait_ratings) {
		formatted.traitRatings.fit = formatLinearTrait(review.trait_ratings, 'fit');
	}

	return formatted;
};

const formatStars = (productReview) => {
	const formatted = {
		ratingAverage: productReview.rating_average,
		ratingCount: productReview.rating_count,
		ratingDistribution: productReview.rating_distribution,
	};

	if (productReview.active_traits) {
		formatted.fit = formatLinearTrait(productReview.active_traits, 'fit');
	}

	return formatted;
};

const getStars = (productId) => {
	return axios({
		headers: {
			'Junip-Store-Key': process.env.NEXT_PUBLIC_JUNIP_STORE_KEY,
		},
		method: 'get',
		params: { include: 'active_traits' },
		url: `https://api.juniphq.com/v1/products?filter[remote_ids]=${productId}`,
	})
		.then(handleStarsSuccess)
		.catch(handleError);
};

// reviewId is used for pagination
const getReviews = (productId, direction, reviewId) => {
	const params = {
		include: 'attribute_records,customer,trait_ratings',
		'page[size]': 10,
	};

	if (reviewId) {
		params[`page[${direction}]`] = reviewId;
	}

	return axios({
		headers: {
			'Junip-Store-Key': process.env.NEXT_PUBLIC_JUNIP_STORE_KEY,
		},
		method: 'get',
		params,
		url: `https://api.juniphq.com/v1/product_reviews?filter[product_remote_id]=${productId}`,
	})
		.then(handleReviewsSuccess)
		.catch(handleError);
};

const handleError = () => {
	return {};
};

const handleReviewsSuccess = (response) => {
	let formattedReviews = [];

	if (response.data?.product_reviews) {
		formattedReviews = response.data.product_reviews.map((review) => {
			return formatReview(review);
		});
	}

	return {
		meta: response.data?.meta || {},
		reviews: formattedReviews,
	};
};

const handleStarsSuccess = (response) => {
	if (!response.data?.products || response.data.products.length !== 1) {
		return {};
	}

	return formatStars(response.data.products[0]);
};

module.exports = {
	getStars,
	getReviews,
};
