import { gql } from '@apollo/client';

const GET_PRODUCT_BY_ID = gql`
	query GetProduct($productId: ID!) {
		product(id: $productId) {
			availableForSale
			compareAtPriceRange {
				maxVariantPrice {
					amount
				}
				minVariantPrice {
					amount
				}
			}
			description
			handle
			id
			images(first: 10) {
				edges {
					node {
						src
						altText
						width
						height
					}
				}
			}
			options {
				id
				name
				values
			}
			priceRange {
				minVariantPrice {
					amount
				}
				maxVariantPrice {
					amount
				}
			}
			productType
			seo {
				description
				title
			}
			sellingPlanGroups(first: 20) {
				edges {
					node {
						name
						sellingPlans(first: 20) {
							edges {
								node {
									checkoutCharge {
										type
										value {
											... on MoneyV2 {
												amount
												currencyCode
											}
										}
									}
									id
									name
									options {
										name
										value
									}
									priceAdjustments {
										adjustmentValue {
											# ... on SellingPlanFixedAmountPriceAdjustment {
											#     adjustmentAmount {
											#         ... on MoneyV2 {
											#         amount
											#         currencyCode
											#     }}
											# }
											... on SellingPlanFixedPriceAdjustment {
												price {
													... on MoneyV2 {
														amount
														currencyCode
													}
												}
											}
											# ... on SellingPlanPercentagePriceAdjustment {
											#     adjustmentPercentage
											# }
										}
										orderCount
									}
									recurringDeliveries
								}
							}
						}
					}
				}
			}
			tags
			title
			variants(first: 100) {
				edges {
					node {
						barcode
						currentlyNotInStock
						id
						priceV2 {
							amount
						}
						sellingPlanAllocations(first: 10) {
							edges {
								node {
									sellingPlan {
										id
										name
										options {
											name
											value
										}
									}
								}
							}
						}
						quantityAvailable
						selectedOptions {
							name
							value
						}
						dcInventory: metafield(namespace: "denver_inventory", key: "quantity") {
							value
						}
						sku
						title
					}
				}
			}
			vendor
		}
	}
`;

export default GET_PRODUCT_BY_ID;
