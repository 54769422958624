import Cookies from 'js-cookie';
import axios from 'axios';
import { getElevarCookies } from '/services/analytics/elevar';
import { v4 as uuidv4 } from 'uuid';

const CLIENT_ID_KEY = 'shinesty_analytics_clientId';
const ENVIRONMENT_KEY = 'shinesty_analytics_environment';
const SESSION_ID_KEY = 'shinesty_analytics_sessionId';

const addClientIdToRollbar = (clientId) => {
	if (
		typeof window === 'undefined' ||
		typeof window.Rollbar === 'undefined' ||
		!clientId ||
		clientId === ''
	) {
		return;
	}

	try {
		window.Rollbar.configure({
			payload: {
				person: {
					id: clientId,
					email: clientId,
				},
			},
		});
	} catch (e) {
		console.log(e);
	}
};
const getShinestyClientId = (forcedClientId) => {
	if (forcedClientId) {
		Cookies.set(CLIENT_ID_KEY, forcedClientId);
		addClientIdToRollbar(forcedClientId);
		return forcedClientId;
	}

	const clientId = Cookies.get(CLIENT_ID_KEY);

	if (clientId) {
		addClientIdToRollbar(clientId);
		return clientId;
	}

	const newClientId = uuidv4();
	Cookies.set(CLIENT_ID_KEY, newClientId);
	addClientIdToRollbar(newClientId);

	return newClientId;
};

const getShinestyCheckoutData = (utms) => {
	let attributes = {
		shinestyClientId: Cookies.get(CLIENT_ID_KEY),
		shinestyEnvironment: Cookies.get(ENVIRONMENT_KEY),
		shinestyOrderId: uuidv4(),
		shinestySessionId: Cookies.get(SESSION_ID_KEY),
		shinestyFriendbuyAttrId: Cookies.get('friendbuy_attribution_id'),
	};

	const shinestyUtms = utms.getUtmParams('shinesty');

	if (shinestyUtms) {
		attributes = {
			...attributes,
			...shinestyUtms,
		};
	}

	return [
		{
			key: '_shinesty_info',
			value: JSON.stringify(attributes),
		},
	];
};

const init = (utms, query) => {
	getShinestyClientId(query.clientId);

	Cookies.set(SESSION_ID_KEY, uuidv4());
	Cookies.set(ENVIRONMENT_KEY, process.env.NEXT_PUBLIC_ANALYTICS_ENVIRONMENT);

	submitClient(utms);
};

const submitClient = (utms) => {
	const clientId = Cookies.get(CLIENT_ID_KEY);
	const sessionId = Cookies.get(SESSION_ID_KEY);

	const utmParams = utms.getUtmParams();
	const elevarParams = getElevarCookies();

	return axios({
		method: 'post',
		url: `${process.env.NEXT_PUBLIC_SHINESTY_ANALYTICS_PLATFORM}/client`,
		data: {
			clientId,
			environment: process.env.NEXT_PUBLIC_ANALYTICS_ENVIRONMENT,
			sessionId,
			...utmParams,
			...elevarParams,
		},
	});
};

const submitEvent = (body, eventId, eventType) => {
	const clientId = Cookies.get(CLIENT_ID_KEY);
	const sessionId = Cookies.get(SESSION_ID_KEY);

	return axios({
		method: 'post',
		url: `${process.env.NEXT_PUBLIC_SHINESTY_ANALYTICS_PLATFORM}/event`,
		data: {
			body: JSON.stringify(body),
			clientId,
			environment: process.env.NEXT_PUBLIC_ANALYTICS_ENVIRONMENT,
			eventId,
			eventType,
			sessionId,
		},
	});
};

module.exports = {
	CLIENT_ID_KEY,
	getShinestyCheckoutData,
	getShinestyClientId,
	init,
	submitClient,
	submitEvent,
};
