import client from '/utils/apollo-client';
import { gql } from '@apollo/client';

const addCartAttributes = async (cartId, attributes) => {
	const mutation = gql`
		mutation cartAttributesUpdate($cartId: ID!, $attributes: [AttributeInput!]!) {
			cartAttributesUpdate(cartId: $cartId, attributes: $attributes) {
				cart {
					id
					attributes {
						key
						value
					}
				}
			}
		}
	`;

	const variables = {
		cartId,
		attributes,
	};

	const { data } = await client.mutate({
		mutation,
		variables,
	});

	return data;
};

const addDiscountCode = async (cartId, discountCode) => {
	const mutation = gql`
		mutation cartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!]!) {
			cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
				cart {
					id
					discountCodes {
						applicable
						code
					}
				}
			}
		}
	`;

	const variables = {
		cartId,
		discountCodes: [discountCode],
	};

	const { data } = await client.mutate({
		mutation,
		variables,
	});

	return data;
};

const addItemsToCart = async (cartId, lines) => {
	const mutation = gql`
		mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
			cartLinesAdd(cartId: $cartId, lines: $lines) {
				cart {
					id
					checkoutUrl
					lines(first: 10) {
						edges {
							node {
								id
								quantity
								merchandise {
									... on ProductVariant {
										id
										sku
									}
								}
							}
						}
					}
				}
				userErrors {
					field
					message
				}
			}
		}
	`;

	const variables = {
		cartId,
		lines,
	};

	const { data } = await client.mutate({
		mutation,
		variables,
	});

	return data;
};

const createCart = async () => {
	const mutation = gql`
		mutation {
			cartCreate {
				cart {
					id
					checkoutUrl
				}
			}
		}
	`;
	const { data } = await client.mutate({
		mutation,
	});
	return data;
};

const getCart = async (id) => {
	const query = gql`
		query GetCart($id: ID!) {
			cart(id: $id) {
				id
				buyerIdentity {
					email
					phone
					customer {
						id
					}
					countryCode
				}
				checkoutUrl
				estimatedCost {
					subtotalAmount {
						amount
					}
					totalAmount {
						amount
					}
					totalTaxAmount {
						amount
					}
				}
				lines(first: 100) {
					edges {
						node {
							attributes {
								key
								value
							}
							estimatedCost {
								subtotalAmount {
									amount
								}
								totalAmount {
									amount
								}
							}
							id
							merchandise {
								... on ProductVariant {
									compareAtPriceV2 {
										amount
									}
									id
									image {
										altText
										url
									}
									priceV2 {
										amount
									}
									product {
										id
										handle
										productType
										tags
										title
										vendor
									}
									sku
									title
								}
							}
							quantity
							attributes {
								key
								value
							}
							sellingPlanAllocation {
								sellingPlan {
									id
								}
							}
						}
					}
				}
			}
		}
	`;
	const { data } = await client.query({
		query: query,
		variables: { id },
		fetchPolicy: 'network-only',
	});
	return data;
};

const updateItemsInCart = async (cartId, lines) => {
	const mutation = gql`
		mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
			cartLinesUpdate(cartId: $cartId, lines: $lines) {
				cart {
					id
					checkoutUrl
					lines(first: 10) {
						edges {
							node {
								id
								merchandise {
									... on ProductVariant {
										id
									}
								}
								quantity
								attributes {
									key
									value
								}
							}
						}
					}
				}
				userErrors {
					field
					message
				}
			}
		}
	`;

	const variables = {
		cartId,
		lines,
	};
	const { data } = await client.mutate({
		mutation,
		variables,
	});
	return data;
};

const removeItemsFromCart = async (cartId, lineIds) => {
	if (!lineIds || lineIds.length === 0) {
		return;
	}

	const mutation = gql`
		mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
			cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
				cart {
					id
					checkoutUrl
				}
				userErrors {
					field
					message
				}
			}
		}
	`;

	const variables = {
		cartId,
		lineIds,
	};
	const { data } = await client.mutate({
		mutation,
		variables,
	});
	return data;
};

module.exports = {
	addCartAttributes,
	addDiscountCode,
	addItemsToCart,
	createCart,
	getCart,
	removeItemsFromCart,
	updateItemsInCart,
};
