import trackAddToCart from './track-add-to-cart';
import trackAddToPack from './track-add-to-pack';
import trackBackInStock from './track-back-in-stock';
import trackCartEmpty from './track-cart-empty';
import trackCartUpdate from './track-cart-update';
import trackSearch from './track-search';
import trackSubscriptionSelect from './track-subscription-select';
import trackVariantSelect from './track-variant-select';
import trackViewItem from './track-view-item';
import trackViewItemList from './track-view-item-list';

module.exports = {
	trackAddToCart,
	trackAddToPack,
	trackBackInStock,
	trackCartEmpty,
	trackCartUpdate,
	trackSearch,
	trackSubscriptionSelect,
	trackVariantSelect,
	trackViewItem,
	trackViewItemList,
};
