import Button from '/components/Button';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { reactChildren } from '/utils/prop-types';
import withAnalytics from '/components/hocs/WithAnalytics';

export const Clickable = forwardRef(
	(
		{
			buttonType = 'button',
			children,
			className,
			disabled,
			handleTracking, // from withAnalytics
			id,
			linkToExternal,
			linkToInternal,
			onClick,
			style,
			target = '_self',
			title,
			...rest
		},
		ref,
	) => {
		const handleOnClick = (event) => {
			// handleTracking from withAnalytics
			if (handleTracking && typeof handleTracking === 'function') {
				handleTracking();
			}

			if (onClick && typeof onClick === 'function') {
				onClick(event);
			}
		};

		// disabled or no linking, this is a button
		if (disabled || (!linkToExternal && !linkToInternal)) {
			const { color, size, variant } = rest;
			return (
				<Button
					id={id}
					className={className}
					color={color}
					disabled={disabled}
					onClick={handleOnClick}
					ref={ref}
					style={style}
					size={size}
					title={title}
					type={buttonType}
					variant={variant}>
					{children}
				</Button>
			);
		}

		// internal link (react page)
		if (linkToInternal) {
			// extract props that should live on Link, others will go on the anchor
			const { as, prefetch } = rest;

			return (
				<Link as={as} href={linkToInternal} passHref prefetch={prefetch}>
					<a className={className} onClick={handleOnClick} ref={ref} style={style} title={title}>
						{children}
					</a>
				</Link>
			);
		}

		// external link
		return (
			<Link href={linkToExternal} passHref>
				<a
					className={className}
					onClick={handleOnClick}
					ref={ref}
					style={style}
					target={target}
					title={title}>
					{children}
				</a>
			</Link>
		);
	},
);

Clickable.displayName = 'Clickable';

Clickable.propTypes = {
	buttonType: PropTypes.string,

	// content of the item
	children: reactChildren.isRequired,

	// className(s), usually used for styling
	className: PropTypes.string,

	// button disabled state
	disabled: PropTypes.bool,

	// passed in from withAnalytics
	handleTracking: PropTypes.func,

	id: PropTypes.string,
	// linkToExternal should be used when item takes the user to another page OUTSIDE the current domain
	linkToExternal: PropTypes.string,

	// linkToInternal should be used when item takes the user to another page WITHIN the current domain
	linkToInternal: PropTypes.string,

	// function to be called when item is clicked
	onClick: PropTypes.func,

	// hardcoded styles (for dynamic bgs mostly)
	style: PropTypes.object,

	// ahref target
	target: PropTypes.string,

	// title for button or anchor
	title: PropTypes.string,

	//add functionality for button variations
	variant: PropTypes.string,
};

Clickable.defaultProps = {
	className: null,
	disabled: false,
	linkToExternal: null,
	linkToInternal: null,
	onClick: null,
	title: null,
	style: null,
	variant: 'none',
	id: null,
};

export default withAnalytics(Clickable);
