import { parseGid } from '/utils/graph-ql';
import { tryParseFloat, tryParseInt } from '/utils/parsers';

const formatProducts = (products) => {
	if (!Array.isArray(products)) {
		return;
	}

	return products.reduce(
		(memo, product) => {
			if (!product) {
				return memo;
			}

			const content = {
				category: product.productType,

				title: product.title,
			};

			if (product.image) {
				content.image = product.image;
			}

			if (product.handle) {
				content.url = `${process.env.NEXT_PUBLIC_BASE_DOMAIN}/products/${product.handle}`;
			}

			// override the subscription 'fake' products with the all subscriptions page
			if (content.category === 'Subscription') {
				content.url = `${process.env.NEXT_PUBLIC_BASE_DOMAIN}/pages/all-subscriptions`;
			}

			if (product.shopifyProductId) {
				content.id = parseGid(product.shopifyProductId);
				memo.productIds.push(content.id);
			}

			let parsedPrice = 0;
			let parsedQuantity;

			if (product.price) {
				parsedPrice = tryParseFloat(product.price);
			}

			if (product.quantity) {
				parsedQuantity = tryParseInt(product.quantity);
			}

			if (Number.isFinite(parsedPrice)) {
				content.item_price = parsedPrice;
			}

			if (Number.isFinite(parsedQuantity)) {
				content.quantity = parsedQuantity;
			}

			if (Number.isFinite(parsedPrice) && Number.isFinite(parsedQuantity)) {
				memo.totalValue += parsedPrice * parsedQuantity;
			} else if (Number.isFinite(parsedPrice)) {
				memo.totalValue += parsedPrice;
			}

			memo.contents.push(content);

			return memo;
		},
		{ contents: [], productIds: [], totalValue: 0 },
	);
};

module.exports = {
	formatProducts,
};
