import { gql } from '@apollo/client';

const GET_SELLING_PLANS = gql`
	query GetProduct($productId: ID!) {
		product(id: $productId) {
			handle
			id
			sellingPlanGroups(first: 20) {
				edges {
					node {
						name
						sellingPlans(first: 20) {
							edges {
								node {
									id
									name
								}
							}
						}
					}
				}
			}
			title
			variants(first: 20) {
				edges {
					node {
						id
						sku
						title
					}
				}
			}
			vendor
		}
	}
`;

export default GET_SELLING_PLANS;
