import { CLIENT_ID_KEY } from '/services/analytics/shinesty';
import resolveConfig from 'tailwindcss/resolveConfig';
const tailwindConfigFile = require('/tailwind.config');

const arrayDifference = (arr1, arr2) => {
	return arr1.filter((x) => !arr2.includes(x));
};

const arrayRemove = (arr, value) => {
	return arr.filter(function (ele) {
		return ele !== value;
	});
};

// removes a number (limit) of items equal to value from array
// if: arr = ['mac', 'bruce', 'bruce', 'bruce', 'nick', 'kandy'], value =  'bruce', limit = 2
// then: return ['mac', 'bruce', 'nick', 'kandy']
const arrayRemoveItems = (arr, value, limit) => {
	let counter = 0;
	return arr.filter(function (ele) {
		if (ele === value && counter < limit) {
			counter++;
			return false;
		}

		return true;
	});
};

const booleanFormat = (value) => {
	if (typeof value == 'string') {
		return Boolean(value);
	}
	return value;
};

const checkProductVariantsSku = (product, sku) => {
	let hasSku = false;
	product?.variants?.forEach((variant) => {
		if (variant?.sku.includes(sku)) {
			hasSku = true;
		}
	});
	return hasSku;
};

const createEmptyArray = (length, defaultValue) => {
	const formatted = [];
	for (let i = 0; i < length; i++) {
		formatted.push(defaultValue);
	}
	return formatted;
};

const createUriWithParam = (baseUrl, params) => {
	const Url = new URL(baseUrl);
	const urlParams = new URLSearchParams(Url.search);
	for (const key in params) {
		if (params[key] !== undefined) {
			urlParams.set(key, params[key]);
		}
	}
	Url.search = urlParams.toString();
	return Url.toString();
};

const firstElement = (arr) => {
	return arr && arr.length > 0 ? arr[0] : undefined;
};

const firstElementProperty = (arr, propertyName) => {
	const first = firstElement(arr);
	return first && first[propertyName] ? first[propertyName] : undefined;
};

const getFileExtension = (fileName) => {
	if (typeof fileName !== 'string') {
		return fileName;
	}

	return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
};

/**
 * I know there's an eaiser way to do this...
 * if y == 100
 * 5 = 0, 30 = 0, 100 = 1, 101 = 1, 200 = 2
 */
const getFullAmountsInValue = (x, y) => {
	if (!x || x < y) {
		return 0;
	}

	return Math.floor(x / y);
};

/**
 * @param {number} num the number we have (5)
 * @param {number} ceiling the number to round up to (100)
 * @returns the next ceiling for that number ( 0 => 100, 5 => 100, 100 => 200, 101 => 200, 155 => 200)
 */
const getNextCeiling = (num, ceiling) => {
	if (!num) {
		return ceiling;
	}

	const nextCeiling = Math.ceil(num / ceiling) * ceiling;

	if (nextCeiling === 0) {
		return ceiling;
	}

	if (nextCeiling === num) {
		return nextCeiling + ceiling;
	}

	return nextCeiling;
};

const getServersideClientId = (req, res) => {
	let clientIdRaw;

	req?.rawHeaders?.map((item, i) => {
		if (item.toLowerCase() == CLIENT_ID_KEY.toLowerCase()) {
			clientIdRaw = req.rawHeaders[i + 1];
		}
	});

	const clientIdHeader = res?.getHeader(CLIENT_ID_KEY);
	const clientId = clientIdRaw || clientIdHeader || req?.cookies[CLIENT_ID_KEY];
	return clientId;
};

const pluck = (arr, key) => {
	if (!arr || !Array.isArray(arr)) {
		return arr;
	}

	return arr.reduce((memo, obj) => {
		if (obj?.[key]) {
			memo.push(obj[key]);
		}

		return memo;
	}, []);
};

const removeFalsy = (arr) => {
	return arr.filter(Boolean);
};

const sortBy = (originalArray, field, reverse) => {
	if (!Array.isArray(originalArray)) {
		return originalArray;
	}

	const arr = [...originalArray];

	if (typeof reverse === 'undefined') {
		reverse = false;
	}

	arr.sort((a, b) => {
		let fieldA = field ? a[field] : a;
		let fieldB = field ? b[field] : b;

		if (typeof fieldA === 'string') {
			fieldA = fieldA.toLowerCase();
		}

		if (typeof fieldB === 'string') {
			fieldB = fieldB.toLowerCase();
		}

		if ((reverse && fieldA > fieldB) || (!reverse && fieldA < fieldB)) {
			return -1;
		}

		if ((reverse && fieldA < fieldB) || (!reverse && fieldA > fieldB)) {
			return 1;
		}
		return 0;
	});

	return arr;
};

/**
 * @param {array} originalArray the collection of data to sort
 * @param {array} sortingOrder the preferred custom order to sort on
 * @param {string} propertyKey if sorting an array of objects, provide a property key
 * @returns the sorted array
 */
const sortByOrder = (originalArray, sortingOrder, propertyKey) => {
	return sortingOrder.reduce((memo, key) => {
		const origValue = originalArray.find((v) => {
			if (typeof v === 'string') {
				return v.toLowerCase() === key.toLowerCase();
			}

			return v[propertyKey]?.toLowerCase() === key.toLowerCase();
		});

		if (origValue) {
			memo.push(origValue);
		}

		return memo;
	}, []);
};

const tailwindConfig = () => {
	// Tailwind config
	return resolveConfig(tailwindConfigFile);
};

const toString = (input) => {
	if (typeof input === 'string') {
		return input;
	}

	if (typeof input == 'number') {
		return input.toString();
	}

	return input;
};

const toTitleCase = (str) => {
	return str.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
};

const validateEmail = (email) => {
	const reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
	return reg.test(email);
};

module.exports = {
	arrayDifference,
	arrayRemove,
	arrayRemoveItems,
	booleanFormat,
	checkProductVariantsSku,
	createEmptyArray,
	createUriWithParam,
	firstElement,
	firstElementProperty,
	getFileExtension,
	getFullAmountsInValue,
	getNextCeiling,
	getServersideClientId,
	pluck,
	removeFalsy,
	sortBy,
	sortByOrder,
	tailwindConfig,
	toTitleCase,
	toString,
	validateEmail,
};
