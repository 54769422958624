export const getItemHref = (item) => {
	if (!item) {
		return;
	}

	let href = item.href;

	if (item.shopifyCollectionHandle) {
		href = `/collections/${item.shopifyCollectionHandle}`;
	} else if (item.shopifyProductHandle) {
		href = `/products/${item.shopifyProductHandle}`;
	}

	return href;
};
