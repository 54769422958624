import Button from '/components/Button';
import Image from '/components/Image';
import Link from 'next/link';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';

import { footerLinks } from '/services/static/404-footer-link';

const NickGetsLashed = ({ title }) => {
	return (
		<div className="text-center">
			<Typography className="text-secondary-dark py-4" component="h1" variant="heading-lg">
				{title}
			</Typography>
			<Typography className="max-w-sm m-auto pb-4" component="p" variant="body-heavy">
				Every time someone finds this page, our IT guy gets a lashing... and he&apos;s been{' '}
				<Typography className="uppercase">super</Typography> naughty
			</Typography>
			<Image
				alt="Error page"
				src="https://cdn.shopify.com/s/files/1/0234/5963/t/24/assets/404Image.jpg?v=13475489384273018591"
				width={500}
				height={325}
			/>
			<Typography className="py-4" component="p" variant="body-heavy">
				number of lashings given:
			</Typography>
			<Image
				alt="number"
				src="https://cdn.shopify.com/s/files/1/0234/5963/t/24/assets/404Image-Counter.png?v=14676980092665547400"
				width={250}
				height={90}
			/>
			<Typography className="py-4" component="p" variant="body-heavy">
				Things you won&apos;t see the IT guy in:
			</Typography>

			<div className="max-w-lg mx-auto pb-8">
				{footerLinks.map((footer, i) => {
					return (
						<Link key={i} href={footer.link} passHref>
							<a>
								<Button type="button" className="m-2" variant="outlined">
									{footer.title}
								</Button>
							</a>
						</Link>
					);
				})}
			</div>
		</div>
	);
};

NickGetsLashed.propTypes = {
	title: PropTypes.string,
};

export default NickGetsLashed;
