import { createGid } from '/utils/graph-ql';
/**
 *
 * @param {Array} products - a list of all possible products
 * @param {Array} selectedOptions - a list of the products the user selected
 * @returns a list of productId and variant to add to the cart
 */
export const findMatchingVariants = (products, selectedOptions = []) => {
	// the products this person has selected
	const selectedProductIds = selectedOptions.map((selectedOption) => {
		return selectedOption.productId;
	});

	// a trimmed down list of products
	const filteredProducts = products.filter((product) => {
		return selectedProductIds.includes(product.id);
	});

	// loop through the list of products and get the variant in that product
	// that matches what the user has selected
	return filteredProducts.reduce((memo, product) => {
		// the product variants for that product
		const productVariant = product.variants.find((productVariant) => {
			// all the selected options passed to the function
			const selectedVariant = selectedOptions.find((selectedOption) => {
				return (
					product.id === selectedOption.productId && productVariant.title === selectedOption.value
				);
			});

			// we found one that matched, return true
			if (selectedVariant) {
				return true;
			}
		});

		// there is indeed a product variant, return it to the matching variants call
		if (productVariant) {
			memo.push({ productId: product.id, variant: productVariant });
		}

		return memo;
	}, []);
};

export const findSizeVariant = (product, size) => {
	return product.variants.find((variant) => {
		return variant.selectedOptions.find((selectedOption) => {
			return selectedOption.name === 'Size' && selectedOption.value === size;
		});
	});
};

export const getFormattedOptionName = (variantName) => {
	const blacklist = ['US Jacket', 'Waist'];

	blacklist.forEach((blacklistWord) => {
		const reggie = new RegExp(blacklistWord, 'gi');
		variantName = variantName.replace(reggie, '');
	});

	return variantName;
};

export const getProductOptions = (product) => {
	const options = [];
	product.variants.forEach((variant) => {
		const optionNames = variant.selectedOptions.reduce((memo, option) => {
			memo.push(option.value);
			return memo;
		}, []);

		options.push({
			label: optionNames.join(' - '),
			value: variant.id,
			disabled: variant.quantityAvailable < 1,
		});
	});
	return options;
};

export const getProductOptionsVue = (product) => {
	const options = [];
	let encodedVariant = '';
	product.variants.forEach((variant) => {
		encodedVariant = createGid('ProductVariant', variant.id);
		options.push({
			label: variant.title,
			value: encodedVariant,
			disabled: variant.inventory_quantity < 1,
		});
	});
	return options;
};

export const linesFromVariants = (variants, quantity = 1) => {
	return variants.map((variant) => {
		if (typeof variant === 'string' || !variant.quantity) {
			return { merchandiseId: variant.variantId, quantity, attributes: [] };
		}

		return {
			merchandiseId: variant.variantId,
			quantity: variant.quantity,
			attributes: variant.attributes ? variant.attributes : [],
		};
	});
};

export const concatSubscriptionProdImages = (arr) => {
	const shortestIndex = arr.reduce(
		(maxI, el, i, arr) => (el.length < arr[maxI].length ? i : maxI),
		0,
	);

	const length = arr[shortestIndex].length;
	const arr1 = arr[0].slice(0, length);
	const arr2 = arr[1].slice(0, length);
	return arr1.map((el, i) => [el, arr2[i]]);
};
