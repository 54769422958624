module.exports = {
	content: [
		'./components/**/*.{js,ts,jsx,tsx}',
		'./layouts/**/*.{js,ts,jsx,tsx}',
		'./pages/**/*.{js,ts,jsx,tsx}',
	],
	theme: {
		fontFamily: {
			sans: ['"Calibre Regular"', 'system-ui'],
			serif: ['ui-serif', 'Georgia'],
			mono: ['ui-monospace', 'SFMono-Regular'],
			display: ['Oswald'],
			body: ['"Calibre Regular"'],
			bold: ['"Calibre Bold"', 'system-ui'],
			semibold: ['"Calibre Semibold"', 'system-ui'],
		},
		fontSize: {
			xs: '.75rem',
			sm: '.875rem',
			base: '1rem',
			lg: '1.25rem',
			xl: '1.5rem',
			'2xl': '1.75rem',
			'3xl': '2.25rem',
			'4xl': '2.5rem',
			'5xl': '4rem',
		},
		letterSpacing: {
			tightest: '-.075em',
			tighter: '-.05em',
			tight: '-.025em',
			normal: '0',
			wide: '.025em',
			wider: '.05em',
			widest: '.1em',
			max: '.25em',
		},
		container: {
			center: true,
		},
		extend: {
			colors: {
				primary: '#E4002B',
				'primary-dark': '#aa0017',
				'primary-darker': '#660013',
				'primary-content': '#ffffff',

				secondary: '#0076d8',
				'secondary-dark': '#006bbb',
				'secondary-darker': '#00477b',
				'secondary-content': '#ffffff',

				tertiary: '#97ead2',
				'tertiary-dark': '#56dcb6',
				'tertiary-darker': '#26ba90',
				'tertiary-content': '#2f3337',

				accent: '#fabc3c',
				'accent-dark': '#efa106',
				'accent-darker': '#9f6804',
				'accent-content': '#2f3337',

				gray: {
					100: '#f8f7f5',
					200: '#ededed',
					300: '#cccccc',
					400: '#a6a6a6',
					500: '#b4b4b4',
					600: '#2f3337',
				},

				pink: '#f7a59d',

				'v2-brown': '#816e66',
				'v2-cream': '#f9efe1',
				'v2-cream-2': '#f8efe1',
				'v2-off-white': '#fdf8f2',
				'v2-orange-burnt': '#d9a54f',
			},
			animation: {
				modal: 'modal 0.5s',
				'drawer-right': 'drawer-right 0.3s',
				'drawer-left': 'drawer-left 0.3s',
				'drawer-top': 'drawer-top 0.3s',
				'drawer-bottom': 'drawer-bottom 0.3s',
				'quick-shop': 'quick-shop 0.4s forwards',
				'spin-slow': 'spin 2s linear infinite',
				'bounce-slow': 'bounce-slow 6s ease infinite',
				'enter-top': 'enter-top .4s ease-in-out',
			},
			keyframes: {
				modal: {
					'0%, 100%': { opacity: 0 },
					'100%': { opacity: 1 },
				},
				'drawer-right': {
					'0%, 100%': { right: '-500px' },
					'100%': { right: '0' },
				},
				'drawer-left': {
					'0%, 100%': { left: '-500px' },
					'100%': { left: '0' },
				},
				'drawer-top': {
					'0%, 100%': { innerHeight: 0 },
					'100%': { innerHeight: 100 },
				},
				'enter-top': {
					'0%': { transform: 'translate(0%,-150%) skewX(0deg)' },
					'100%': { transform: 'translate(0%,0%) skewX(0deg)' },
				},
				'exit-top': {
					'100%': { transform: 'translate(0%,-150%) skewX(0deg)' },
					'0%': { transform: 'translate(0%,0%) skewX(0deg)' },
				},
				'drawer-bottom': {
					'0%, 100%': { bottom: '-500px' },
					'100%': { bottom: '0' },
				},
				'quick-shop': {
					'0%': { bottom: '-30px' },
					'100%': { bottom: '0' },
				},
				'bounce-slow': {
					'50%': { transform: 'translateY(-8px)' },
				},
			},
			boxShadow: {
				inner: '-3px 3px 9px -2px rgba(228, 0, 43, 0.6);',
			},
			backgroundImage: {
				'laundry-sheets': "url('https://cdn.shinesty.com/2024-01-22/laundry-sheets.svg')",
				'laundry-sheets-hover':
					"url('https://cdn.shinesty.com/2024-01-22/laundry-sheets-filled.svg')",
				'mens-underwear': "url('https://cdn.shinesty.com/2022-11-07/standard-line.svg')",
				'mens-underwear-hover': "url('https://cdn.shinesty.com/2022-11-07/standard-filled.svg')",
				'womens-underwear': "url('https://cdn.shinesty.com/2022-11-07/cheeky-line.svg')",
				'womens-underwear-hover': "url('https://cdn.shinesty.com/2022-11-07/cheeky-filled.svg')",
				socks: "url('https://cdn.shinesty.com/2023-11-27/crew-outline.svg')",
				'socks-hover': "url('https://cdn.shinesty.com/2023-11-27/crew-filled.svg')",
				'couples-underwear': "url('https://cdn.shinesty.com/2022-11-07/couples-outline.svg')",
				'couples-underwear-hover': "url('https://cdn.shinesty.com/2022-11-07/couples-filled.svg')",
				'gift-boxer': "url('https://cdn.shinesty.com/2022-11-07/giftbox-outline.svg')",
				'gift-boxer-hover': "url('https://cdn.shinesty.com/2022-11-07/giftbox-filled.svg')",
			},
		},
	},
	plugins: ['@tailwindcss/aspect-ratio', '@tailwindcss/tailwindcss-animate'],
};
