import { createGid } from '/utils/graph-ql';

const transformAlgoliaProduct = (algoliaProduct) => {
	if (!algoliaProduct) {
		return {};
	}
	const formatted = {
		handle: algoliaProduct.handle,
		image: algoliaProduct.image || algoliaProduct.imageSrc,
		price: algoliaProduct.price,
		productType: algoliaProduct.product_type || algoliaProduct.productType,
		title: algoliaProduct.title || algoliaProduct.name,
	};

	if (algoliaProduct.id) {
		formatted.shopifyProductId = createGid('Product', algoliaProduct.id, true);
	}

	return formatted;
};

export default transformAlgoliaProduct;
