import ProductCarousel from './ProductCarousel';
import PropTypes from 'prop-types';

const validComponentKeys = {
	'product-carousel': ProductCarousel,
};

const KeyedComponent = ({ item }) => {
	if (!item.componentKey || !validComponentKeys[item.componentKey]) {
		return null;
	}

	const Component = validComponentKeys[item.componentKey];
	return <Component item={item} />;
};

KeyedComponent.propTypes = {
	className: PropTypes.string,
	item: PropTypes.object,
	menuStyle: PropTypes.string,
	navGroup: PropTypes.string,
	tier: PropTypes.string,
	typographyVariant: PropTypes.string,
};

export default KeyedComponent;
