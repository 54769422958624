import PropTypes from 'prop-types';
import { checkFunctionality } from '/services/static/countries';
import { observer } from 'mobx-react-lite';
import { reactChildren } from '/utils/prop-types';
import { useGlobalStore } from '../state/global-context';
import { useEffect, useState } from 'react';

const CountryFunctionality = ({ children, functionalityKey, showIfDisabled = false }) => {
	const globalStore = useGlobalStore();
	const currentCountry = globalStore.getCurrentCountry();
	const [showFunctionality, setShowFunctionality] = useState(true);

	useEffect(() => {
		if (!currentCountry) {
			return;
		}

		const countryHasFunctionality = checkFunctionality(
			currentCountry.countryCode,
			functionalityKey,
		);

		setShowFunctionality(
			// the country doesn't have the functionality, and we are showing if it doesn't
			(!countryHasFunctionality && showIfDisabled) ||
				// OR the country has the functionality, and we are showing if it does
				(countryHasFunctionality && !showIfDisabled),
		);
	}, [currentCountry]);

	if (!showFunctionality) {
		return null;
	}

	return <>{children}</>;
};

CountryFunctionality.propTypes = {
	children: reactChildren,
	functionalityKey: PropTypes.string,
	showIfDisabled: PropTypes.bool,
};

export default observer(CountryFunctionality);
