import { gql } from '@apollo/client';

const GET_RECOMMENDED_PRODUCTS = gql`
	query productRecommendations($productId: ID!) {
		productRecommendations(productId: $productId) {
			description
			handle
			id
			images(first: 10) {
				edges {
					node {
						originalSrc
						altText
					}
				}
			}
			priceRange {
				maxVariantPrice {
					amount
				}
				minVariantPrice {
					amount
				}
			}
			sizeCurveWeight: metafield(
				namespace: "denver_inventory"
				key: "size_curve_weights_in_stock"
			) {
				value
			}
			productType
			tags
			title
			variants(first: 100) {
				edges {
					node {
						barcode
						currentlyNotInStock
						id
						priceV2 {
							amount
						}
						sellingPlanAllocations(first: 10) {
							edges {
								node {
									sellingPlan {
										id
										name
										options {
											name
											value
										}
									}
								}
							}
						}
						quantityAvailable
						selectedOptions {
							name
							value
						}
						dcInventory: metafield(namespace: "denver_inventory", key: "quantity") {
							value
						}
						sku
						title
					}
				}
			}
		}
	}
`;

export default GET_RECOMMENDED_PRODUCTS;
