import { ProductSwap } from './static-data';
import { findConfigForCode } from './utils';

export const findSwapCode = (
	discountCode?: string,
	productTypes?: string[],
): ProductSwap | undefined => {
	// if no discount code, early exit -- nothing will be able to compare
	if (!discountCode || !productTypes || productTypes.length === 0) {
		return;
	}

	// find the config...
	const config = findConfigForCode(discountCode);

	// if there's no config, early exit -- nothing will be able to compare
	if (!config) {
		return;
	}

	// you've got a config and a list of product types you need a code for, lets look
	const foundSwap = findSwap(config.productTypeSwaps, productTypes);

	if (foundSwap) {
		return foundSwap;
	}

	// we didnt get a swap code back, lets munge the data to see if we can find one
	const swappedProductTypes = productTypes.reduce((memo: string[], productType: string) => {
		if (typeof productType !== 'string') {
			return memo;
		}

		productType = productType.toLowerCase();

		if (
			[
				'boxers',
				'boxers - brief',
				'boxers - fly',
				'boxers - long - fly',
				'boxers - trunk',
			].includes(productType)
		) {
			memo.push('mens');
			return memo;
		}

		if (['bikini', 'boyshort', 'cheeky', 'thongs - modal'].includes(productType)) {
			memo.push('womens');
			return memo;
		}

		if (['socks - ankle', 'socks - crew'].includes(productType)) {
			memo.push('socks');
			return memo;
		}

		memo.push(productType);

		return memo;
	}, []);

	return findSwap(config.productTypeSwaps, swappedProductTypes);
};

/**
 * takes a list of swaps from the config and a list of product types you have and finds the
 * swap that you need. swap. swamp.
 */
const findSwap = (swaps?: ProductSwap[], productTypes?: string[]) => {
	// early exit if your data is hosed.
	if (!productTypes || productTypes.length === 0 || !swaps || swaps.length === 0) {
		return;
	}

	// find the swap where the array of product types is equal to what you're looking for.
	return swaps.find((swap) => {
		return arraysEqual(productTypes, swap.productTypes);
	});
};

/**
 * used to compare to arrays -- sorts the arrays so that you don't care about order.
 */
const arraysEqual = (a: string[], b: string[]) => {
	if (a.length !== b.length) {
		return false;
	}

	const sortedA = a
		.map((i) => {
			return typeof i === 'string' ? i?.toLowerCase() : i;
		})
		.sort();

	const sortedB = b
		.map((i) => {
			return typeof i === 'string' ? i?.toLowerCase() : i;
		})
		.sort();

	// loop through each of the properties and see if they match.
	for (let i = 0; i < sortedA.length; ++i) {
		if (sortedA[i] !== sortedB[i]) {
			return false;
		}
	}

	return true;
};
