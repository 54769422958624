import axios from 'axios';

const convertValue = (baseCurrencyCode, price, targetCountry) => {
	if (!targetCountry) {
		return { hasError: true, message: 'must provide country' };
	}

	const targetCurrency = `${targetCountry.countryCode}-${targetCountry.currencyCode}`;

	return makeRequest('/pss/catalog/prices/conversion/latest', {
		baseCurrency: baseCurrencyCode,
		price: price,
		targetCurrencies: [targetCurrency],
	})
		.then((response) => {
			if (!response?.data) {
				return { hasError: true, message: 'unknown error' };
			}

			if (response.data.isError) {
				return { hasError: true, message: response.data?.message || 'unknown error' };
			}

			if (
				!response.data?.result?.conversions ||
				!response.data.result.conversions[targetCurrency]
			) {
				return { hasError: true, message: 'no conversion returned' };
			}

			return { value: response.data.result.conversions[targetCurrency] };
		})
		.catch(handleError);
};

const getPrice = (countryCode, variantId) => {
	return makeRequest('/pss/catalog/prices', {
		countries: [countryCode],
		productVariantIds: [variantId],
	});
};

const handleError = (e) => {
	return { error: e, hasError: true };
};

const makeRequest = (endpoint, payload) => {
	return axios({
		data: payload,
		headers: {
			'x-api-key': process.env.NEXT_PUBLIC_OPENBORDER_API_KEY,
		},
		method: 'post',
		url: `${process.env.NEXT_PUBLIC_OPENBORDER_API}${endpoint}`,
	});
};

module.exports = {
	convertValue,
	getPrice,
};
