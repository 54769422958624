import { arrayRemoveItems } from '/utils/common-utils';
import { calculatePrice as calculateMysteryPackPrice } from '/services/mystery-pack';
import { checkProductTags } from '/utils/product-tags';
import { getMultiProductDetails } from '/services/static/promotions/multi-product';
import { getPackDiscount } from '/services/static/packs/pack-price';
import { parseGid } from '/utils/graph-ql';

import {
	ADD_TO_CART_TIME,
	BUILD_A_PACK,
	DISCOUNTED_LINE_PRICE,
	DISCOUNTED_SUBSCRIPTION_PROMOTION,
	FORTY_OFF_PACK_DISCOUNT,
	FROM_MIXED_PACK_PRODUCT,
	FROM_PACK_PRODUCT,
	FROM_PACK_SKU,
	IS_HIDDEN,
	IS_OPTED_OUT,
	IS_SUBSCRIPTION,
	IS_SUBSCRIPTION_PARENT,
	IS_SUB_UP,
	MENU_UPSELL,
	MINI_CART_UPSELL,
	MIXED_PACK_BUILDER,
	MIXED_PACK_PRODUCT,
	MYSTERY_PACK_ITEM,
	PACK_ID,
	PACK_PRODUCT,
	POST_SUB_UPSELL,
	PREBUILT_PACK_TYPE,
	PROMOTIONAL_ITEM,
	PROMOTIONAL_PRICE,
	SINGLE_ITEM_PRICE,
	SITEWIDE_PACK_BUILDER,
	SUBSCRIPTION_FREQUENCY,
	SUBSCRIPTION_TITLE,
	SUB_BUY_NOW,
} from '/services/static/attribute-keys';

import {
	DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_MONTHLY_DECIMAL,
	DEFAULT_PRICE_SUBSCRIPTION_WOMENS_THONG_MONTHLY_DECIMAL,
} from '/services/static/default-prices';
import {
	PRODUCT_TYPE_BIKINI,
	PRODUCT_TYPE_BOXERS_COOLING,
	PRODUCT_TYPE_BOYSHORT,
	PRODUCT_TYPE_BOYS_UNDERWEAR,
	PRODUCT_TYPE_CHEEKY,
	PRODUCT_TYPE_LOUNGE_MENS_HOODIE,
	PRODUCT_TYPE_LOUNGE_MENS_JOGGER,
	PRODUCT_TYPE_LOUNGE_WOMENS_HOODIE,
	PRODUCT_TYPE_LOUNGE_WOMENS_JOGGER,
	PRODUCT_TYPE_SLEEP_MENS_BOTTOMS,
	PRODUCT_TYPE_SLEEP_MENS_TOPS,
	PRODUCT_TYPE_SOCKS_ANKLE,
	PRODUCT_TYPE_SOCKS_CREW,
	PRODUCT_TYPE_THONGS,
	PRODUCT_TYPE_THONGS_COOLING,
	PRODUCT_TYPE_THONGS_MODAL,
	PRODUCT_TYPE_WOMENS_BOXER,
} from '../services/static/product-types';

class LineItem {
	constructor(lineItem) {
		Object.keys(lineItem).forEach((key) => {
			this[key] = lineItem[key];
		});
		this.hasChanged = false;
		this.linePrice = parseFloat(lineItem.estimatedCost.subtotalAmount.amount);
		this.attributes = this.attributes.reduce((obj, x) => {
			obj[x.key] = x.value;
			return obj;
		}, {});
		this.showDiscount = false;
	}

	changeLinePrice(newPrice, oldPrice) {
		this.hasChanged = true;
		this.showDiscount = true;
		this.originalPrice = oldPrice || this.estimatedCost.subtotalAmount.amount;
		this.linePrice = newPrice > 0 ? newPrice : 0;
	}

	setSplitItemsQuantity(quantity) {
		this.splitItemsQuantity = quantity;
	}
}

// the discounts available based on quantity
export const defaultDiscounts = {
	joinedBowTies: 5.0,
	joinedLoungewearBottoms: 40.0,
	joinedSleepBottoms: 4.99,
	joinedSuitPantsBlackLabel: 40.0,
	joinedSuitPants: 20.0,
	joinedTies: 5.0,
	mixedPack: [1, 0.95, 0.9, 0.85, 0.8, 0.75, 0.73, 0.7, 0.67],
};

// const availableDisountsB3G1 = {
// 	joinedSuitPantsBlackLabel: 40.0,
// 	joinedSuitPants: 20.0,
// 	joinedTies: 5.0,
// 	joinedBowTies: 5.0,
// 	mixedPack: [1, 0.95, 0.9, 0.85, 0.8, 0.75, 0.73, 0.7, 0.67],
// };

let availableDiscounts = { ...defaultDiscounts };

export const preBuiltPacks = {
	margaritaville: {
		freeItem: {
			variantId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTMyNzkzNjQ0NjUzMw==',
			quantity: 1,
		},
		productCount: 6,
		discount: 0.7501945093,
	},
};

export const getUpsellDiscount = () => {
	return 1;
};

const productTypes = {
	blazers: [
		'Blazers',
		'Suits',
		'SS Blazers',
		'Sleeveless Blazers',
		'Blazers - NCAA',
		'Blazers - NFL',
	],
	bowTies: ['Bow Ties', 'Bow Ties - NCAA', 'Bow Ties - NFL'],
	loungeBottoms: [PRODUCT_TYPE_LOUNGE_MENS_JOGGER, PRODUCT_TYPE_LOUNGE_WOMENS_JOGGER],
	loungeBottomsMens: [PRODUCT_TYPE_LOUNGE_MENS_JOGGER],
	loungeBottomsWomens: [PRODUCT_TYPE_LOUNGE_WOMENS_JOGGER],
	loungeTops: [PRODUCT_TYPE_LOUNGE_MENS_HOODIE, PRODUCT_TYPE_LOUNGE_WOMENS_HOODIE],
	loungeTopsMens: [PRODUCT_TYPE_LOUNGE_MENS_HOODIE],
	loungeTopsWomens: [PRODUCT_TYPE_LOUNGE_WOMENS_HOODIE],
	sleepBottoms: [PRODUCT_TYPE_SLEEP_MENS_BOTTOMS],
	sleepTops: [PRODUCT_TYPE_SLEEP_MENS_TOPS],
	suitPants: ['Suit Pants', 'Suit Shorts', 'Pants - NCAA'],
	ties: ['Ties', 'Ties - NFL'],
};

const duplicateObject = (object) => {
	return Object.assign(Object.create(Object.getPrototypeOf(object)), object);
};

/**
 * handles creating a duplicate line item with the maximum count
 */

const splitLineItem = (lineItem, maxCount, discountDollars, fullPriceDiscount) => {
	const data = {
		discountQuantity: 0,
		items: [],
		quantity: 0,
	};

	if (!fullPriceDiscount) {
		fullPriceDiscount = 0;
	}

	const discountedLineItem = duplicateObject(lineItem);

	const fullItemPrice = discountedLineItem.merchandise.priceV2.amount;

	discountedLineItem.quantity = maxCount;

	discountedLineItem.changeLinePrice(
		(fullItemPrice - discountDollars) * discountedLineItem.quantity,
		fullItemPrice * discountedLineItem.quantity,
	);

	discountedLineItem.setSplitItemsQuantity(lineItem.quantity);
	data.items.push(discountedLineItem);
	data.discountQuantity = discountedLineItem.quantity;
	data.quantity += discountedLineItem.quantity;

	// if the line item quantity is over the max count, we need to create a second pair of full price

	if (lineItem.quantity > maxCount) {
		const fullPriceItem = duplicateObject(lineItem);
		fullPriceItem.quantity = lineItem.quantity - maxCount;
		fullPriceItem.originalPrice = fullItemPrice * fullPriceItem.quantity;
		fullPriceItem.changeLinePrice(
			(fullItemPrice - fullPriceDiscount) * fullPriceItem.quantity,
			fullItemPrice * fullPriceItem.quantity,
		);
		fullPriceItem.showDiscount = false;
		fullPriceItem.setSplitItemsQuantity(lineItem.quantity);
		data.items.push(fullPriceItem);
		data.quantity += fullPriceItem.quantity;
	}

	return data;
};
/**
 * takes a quantity and an array of available discounts, returns the appropriate discount
 */
const getDiscount = (quantity, availableDiscounts) => {
	if (quantity > availableDiscounts.length) {
		return availableDiscounts[availableDiscounts.length - 1];
	}

	return availableDiscounts[quantity - 1];
};

export const getFromPrice = (product) => {
	const isCloseout = checkProductTags(product.tags, 'capsule: Closeout');

	if (isCloseout) {
		return product;
	}

	const updatedProduct = { ...product };

	if (productTypes.loungeBottoms.includes(updatedProduct.productType)) {
		updatedProduct.fromPrice = updatedProduct.prices[0] - availableDiscounts.loungeWear;
		return updatedProduct;
	}

	if (productTypes.sleepBottoms.includes(updatedProduct.productType)) {
		updatedProduct.fromPrice = updatedProduct.prices[0] - availableDiscounts.joinedSleepBottoms;
		return updatedProduct;
	}

	if (productTypes.suitPants.includes(updatedProduct.productType)) {
		if (updatedProduct.tags.includes('feature: Black Label')) {
			updatedProduct.fromPrice =
				updatedProduct.prices[0] - availableDiscounts.joinedSuitPantsBlackLabel;
		} else {
			updatedProduct.fromPrice = updatedProduct.prices[0] - availableDiscounts.joinedSuitPants;
		}

		return updatedProduct;
	}

	if (updatedProduct.productType === 'Ties' || updatedProduct.productType === 'Ties - NFL') {
		updatedProduct.fromPrice = updatedProduct.prices[0] - availableDiscounts.joinedTies;
		return updatedProduct;
	}

	if (productTypes.bowTies.includes(updatedProduct.productType)) {
		updatedProduct.fromPrice = updatedProduct.prices[0] - availableDiscounts.joinedBowTies;
		return updatedProduct;
	}

	return updatedProduct;
};

export const processor = (inCart, currentPromotion) => {
	const cart = inCart.plain();

	const lineItems = (cart.shopifyCart?.lines?.edges || [])
		.map((e) => {
			return new LineItem(e.node);
		})
		.sort((first, second) => {
			const firstDate = first.attributes[ADD_TO_CART_TIME];
			const secondDate = second.attributes[ADD_TO_CART_TIME];

			if (!firstDate || !secondDate) {
				return 0;
			}

			return firstDate < secondDate ? 1 : -1;
		});

	// Shopify used to have a character limit on this file && to fit everything we had to shorten many of our variable names. They've changed that but we haven't changed the variable names
	// Underwear note: We have 4 non-standard product page templates for underwear. Some of these handle bulk discounting differently, hence why we handle underwear in multiple locations
	// 1. same type pack PDP (usually refered to just as a pack)
	// Standard bulk discounting by counting number of products w/ a max discount %
	// 2. mixed type pack PDP (multiple product types on 1 product page w/ multiple selects)
	// Applies set discount to products only if 2+ products w/ mixed pack property. Discount is Money amount not %
	// 3. same type pack builder (boxer pack builder && womens underwear pack builder)
	// Stadard bulk discounting by counting number of products from page
	// 4. mixed type pack builder (boxer && womens underwear all on same page)
	// Stadard bulk discounting by counting number of products from page but using a different percent calculation than same type
	// Variables below are flags for different discounts.
	let hasSubscriptionItem;
	let hasMonthlySubscriptionItem;
	let hasConjuredSubscription = false;
	let hasNonCartItem = false;
	let isActiveSubscriber = false;
	let isHotTubClubMember = false;
	let isSuitDiscountSet = false;
	let coolingBoxerSub = false;
	let coolingThongSub = false;

	// counts for products in the cart.
	const counts = {
		blazers: 0,
		loungeBottomsMens: 0,
		loungeBottomsWomens: 0,
		loungeTopsMens: 0,
		loungeTopsWomens: 0,
		mensHygiene: 0,
		miniCartUpsells: 0,
		mixedPackBuilder: 0,
		mixedPackProducts: 0,
		nightCaps: 0,
		nightGowns: 0,
		preBuiltPacks: {}, // this is an object that gets populated with values as the count loop runs
		prePackedUnderwear: 0,
		sleepBottoms: 0,
		sleepTops: 0,
		socks: 0,
		underwearPackBikinis: 0,
		underwearPackBoxers: 0,
		underwearPackBoysUnderwear: 0,
		underwearPackBoyshorts: 0,
		underwearPackCheekies: 0,
		underwearPackThongs: 0,
		underwearPackThongsModal: 0,
		underwearPackWomensBoxer: 0,
		womensSkirtsOrPants: 0,
	};

	// these counts ensure additional products aren't added.
	// for example, we don't want to discount two pairs of suit pants if only one blazer is in the cart
	const maxJoinedProductCounts = {
		bowTies: 0,
		loungeMens: 0,
		loungeWomens: 0,
		nightGowns: 0,
		pants: 0,
		sleepwear: 0,
		ties: 0,
		womensBlazers: 0,
	};

	// the discounts this cart is eligible for
	const cartDiscounts = {
		default: 1,
		mixedPack: 1,
		prePackedUnderwear: 1,
		underwearPackBoxers: 1,
		underwearPackBoysUnderwear: 1,
		underwearPackCheekies: 1,
		underwearPackBikinis: 1,
		underwearPackBoyshorts: 1,
		underwearPackThongs: 1,
		underwearPackThongsModal: 1,
		underwearPackWomensBoxer: 1,
	};

	// existing ids for subscription parents
	// const subscriptionIds = [];

	// lookup of mystery packs in the cart and their counts
	const mysteryPacks = {};
	const sitewidePackEnable = true;

	// **********
	// Loop to count number of products by type && property in cart, also sets discount amounts to use in the next loop
	// **********

	lineItems.forEach((lineItem) => {
		let product = lineItem.merchandise.product;
		let productType = product.productType;

		// this stays in after subscriptopn
		if (productType === 'Conjured Subscription') {
			hasConjuredSubscription = true;
		}
		if (
			[PRODUCT_TYPE_SOCKS_ANKLE, PRODUCT_TYPE_SOCKS_CREW].includes(productType) &&
			!lineItem.attributes[PROMOTIONAL_ITEM]
		) {
			counts.socks += lineItem.quantity;
		}

		// Has subscription item in cart
		if (lineItem.attributes[IS_SUBSCRIPTION_PARENT] === 'true') {
			hasSubscriptionItem = true;
			hasNonCartItem = true;
			if (lineItem.attributes[SUBSCRIPTION_FREQUENCY] === 'monthly') {
				hasMonthlySubscriptionItem = true;
			}
			// subscriptionIds.push(lineItem.attributes[SUBSCRIPTION_ID]);
			return;
		}

		if (sitewidePackEnable && lineItem.attributes[SITEWIDE_PACK_BUILDER]) {
			counts.mixedPackBuilder += lineItem.quantity;
		} else if (
			lineItem.attributes[MIXED_PACK_PRODUCT] ||
			lineItem.attributes[FROM_MIXED_PACK_PRODUCT]
		) {
			if (
				!productType.includes('Kimono') &&
				!productType.includes('Hygiene') &&
				!productType.includes('Socks')
			) {
				counts.mixedPackProducts += lineItem.quantity;
			}
		} else if (lineItem.attributes[MIXED_PACK_BUILDER]) {
			// Count number of products added from a mixed pack builder page
			counts.mixedPackBuilder += lineItem.quantity;
		} else if (!(lineItem.attributes[MINI_CART_UPSELL] || lineItem.attributes[MENU_UPSELL])) {
			hasNonCartItem = true;
		}

		// prebuilt packs like margaritaville
		const preBuiltPackType = lineItem.attributes[PREBUILT_PACK_TYPE];
		if (preBuiltPackType && preBuiltPacks[preBuiltPackType]) {
			if (!counts.preBuiltPacks[preBuiltPackType]) {
				counts.preBuiltPacks[preBuiltPackType] = 0;
			}
			counts.preBuiltPacks[preBuiltPackType] += lineItem.quantity;
		}

		if (lineItem.attributes[PACK_PRODUCT] && !lineItem.attributes[BUILD_A_PACK]) {
			counts.prePackedUnderwear += lineItem.quantity;
		}

		// Allow for suit pant discount later, only if any blazer in the cart was added from a page that contains a pant product on the same product page
		// Also count number of blazers from these pages so we know the max number of pants
		// No idea why the above ifs aren't 1 line. I'm embarrassed.// blazers
		if (productTypes.blazers.includes(productType) && product.tags.includes('joinedproduct')) {
			counts.blazers += lineItem.quantity;
			isSuitDiscountSet = true;
		} else if (productType === 'Dresses' || productType === 'Jumpsuits') {
			// womdress = true;
		} else if (
			(productType === 'Skirts' || productType === "Women's Pants") &&
			product.tags.includes('joinedproduct')
		) {
			// same as above suit blazer && pants but applies to womens suits. Discounting is applied to womens blazers though so counting is based on pant number.
			// womsuitdiscset = true;
			counts.womensSkirtsOrPants += lineItem.quantity;
		} else if (productType.includes('Boxers')) {
			if (lineItem.attributes[BUILD_A_PACK] || product.tags.includes('brand: Margaritaville')) {
				// Bulk boxers discount is the same if they're added from either pack builder page (Underwear note //3,//4) || from a same type pack PDP (Underwear note //1)
				counts.underwearPackBoxers += lineItem.quantity;
			}

			if (productType.includes('Cooling') && !lineItem.attributes[BUILD_A_PACK]) {
				coolingBoxerSub = true;
			}
		} else if (productType === PRODUCT_TYPE_BOYS_UNDERWEAR && lineItem.attributes[BUILD_A_PACK]) {
			counts.underwearPackBoysUnderwear += lineItem.quantity;
		} else if (productType === PRODUCT_TYPE_CHEEKY && lineItem.attributes[BUILD_A_PACK]) {
			counts.underwearPackCheekies += lineItem.quantity;
		} else if (productType.includes('Thongs') && productType !== PRODUCT_TYPE_THONGS_MODAL) {
			if (productType.includes('Cooling') && !lineItem.attributes[BUILD_A_PACK]) {
				coolingThongSub = true;
			}

			if (lineItem.attributes[BUILD_A_PACK]) {
				counts.underwearPackThongs += lineItem.quantity;
			}
		} else if (productType === PRODUCT_TYPE_BIKINI) {
			if (lineItem.attributes[BUILD_A_PACK]) {
				counts.underwearPackBikinis += lineItem.quantity;
			}
		} else if (productType === PRODUCT_TYPE_WOMENS_BOXER && lineItem.attributes[BUILD_A_PACK]) {
			counts.underwearPackWomensBoxer += lineItem.quantity;
		} else if (productType === PRODUCT_TYPE_THONGS_MODAL && lineItem.attributes[BUILD_A_PACK]) {
			counts.underwearPackThongsModal += lineItem.quantity;
		} else if (productType === PRODUCT_TYPE_BOYSHORT && lineItem.attributes[BUILD_A_PACK]) {
			counts.underwearPackBoyshorts += lineItem.quantity;
		} else if (productType === 'Pajamas') {
			// Will explain Nightgown/nightcap discount in next loop
			if (
				product.tags.includes('product type:Nightgowns') &&
				product.tags.includes('product type:Night Cap')
			) {
				counts.nightCaps += lineItem.quantity;
			} else if (product.tags.includes('product type:Nightgowns')) {
				counts.nightGowns += lineItem.quantity;
			}
		} else if (productType === "Men's Hygiene" && lineItem.attributes[PACK_PRODUCT]) {
			counts.mensHygiene += lineItem.quantity;
		} else if (productTypes.loungeBottomsMens.includes(productType)) {
			counts.loungeBottomsMens += lineItem.quantity;
		} else if (productTypes.loungeBottomsWomens.includes(productType)) {
			counts.loungeBottomsWomens += lineItem.quantity;
		} else if (productTypes.loungeTopsMens.includes(productType)) {
			counts.loungeTopsMens += lineItem.quantity;
		} else if (productTypes.loungeTopsWomens.includes(productType)) {
			counts.loungeTopsWomens += lineItem.quantity;
		} else if (productTypes.sleepBottoms.includes(productType)) {
			counts.sleepBottoms += lineItem.quantity;
		} else if (productTypes.sleepTops.includes(productType)) {
			counts.sleepTops += lineItem.quantity;
		}

		if (lineItem.attributes[MYSTERY_PACK_ITEM]) {
			if (!mysteryPacks[lineItem.attributes[PACK_ID]]) {
				mysteryPacks[lineItem.attributes[PACK_ID]] = {
					quantity: 0,
					productType: product.productType,
				};
			}

			mysteryPacks[lineItem.attributes[PACK_ID]]['quantity']++;
		}
	});

	// **********
	// Use the above counts to set logic checks for each line item below.
	// **********

	// See comment above where maxJoinedProductCounts is defined
	maxJoinedProductCounts.bowTies = counts.blazers;
	maxJoinedProductCounts.loungeMens = counts.loungeTopsMens;
	maxJoinedProductCounts.loungeWomens = counts.loungeTopsWomens;
	maxJoinedProductCounts.nightGowns = counts.nightGowns;
	maxJoinedProductCounts.pants = counts.blazers;
	maxJoinedProductCounts.sleepwear = counts.sleepTops;
	maxJoinedProductCounts.ties = counts.blazers;
	maxJoinedProductCounts.womensBlazers = counts.womensSkirtsOrPants;

	// multi product promotion gives a customer x free pairs when they purchase y other pairs, where the
	// cheapest pairs of underwear are free.
	const multiProductPromotionDetails = getMultiProductDetails(lineItems, currentPromotion);
	let multiProductPromotionPrices = multiProductPromotionDetails?.pricesFree || [];

	Object.keys(mysteryPacks).forEach((key) => {
		mysteryPacks[key].price = calculateMysteryPackPrice(
			mysteryPacks[key].productType,
			mysteryPacks[key].quantity,
		);
	});

	if (isHotTubClubMember) {
		cartDiscounts.default = 0.7;
	} else {
		availableDiscounts = {
			loungeWear: 40.0,
			joinedSleepBottoms: 4.99,
			joinedSuitPantsBlackLabel: 40.0,
			joinedSuitPants: 20.0,
			joinedTies: 5.0,
			joinedBowTies: 5.0,
			mixedPack: [1, 0.975, 0.952, 0.9249, 0.8999, 0.8749, 0.8542, 0.8349, 0.8166],
			prePackedUnderwear: [1, 0.9425, 0.8976, 0.8464, 0.8002, 0.7502, 0.7309, 0.7021, 0.6712],
		};

		// TODO (PACK BUILDER): getPackPrice needs updated to use `builderType` instead of `productType`?? I think the only thing needed here is the discount percentage?!
		if (counts.underwearPackBikinis > 0) {
			cartDiscounts.underwearPackBikinis = getPackDiscount(
				'underwear',
				counts.underwearPackBikinis,
			);
		}

		if (counts.underwearPackBoxers > 0) {
			cartDiscounts.underwearPackBoxers = getPackDiscount('underwear', counts.underwearPackBoxers);
		}

		if (counts.underwearPackBoysUnderwear > 0) {
			cartDiscounts.underwearPackBoysUnderwear = getPackDiscount(
				'underwear',
				counts.underwearPackBoysUnderwear,
			);
		}

		if (counts.underwearPackBoyshorts > 0) {
			cartDiscounts.underwearPackBoyshorts = getPackDiscount(
				'underwear',
				counts.underwearPackBoyshorts,
			);
		}

		if (counts.underwearPackCheekies > 0) {
			cartDiscounts.underwearPackCheekies = getPackDiscount(
				'underwear',
				counts.underwearPackCheekies,
			);
		}

		if (counts.underwearPackThongs > 0) {
			cartDiscounts.underwearPackThongs = getPackDiscount('underwear', counts.underwearPackThongs);
		}

		if (counts.underwearPackThongsModal > 0) {
			cartDiscounts.underwearPackThongsModal = getPackDiscount(
				'underwear',
				counts.underwearPackThongsModal,
			);
		}

		if (counts.underwearPackWomensBoxer > 0) {
			cartDiscounts.underwearPackWomensBoxer = getPackDiscount(
				'underwear',
				counts.underwearPackWomensBoxer,
			);
		}

		if (counts.mixedPackBuilder > 0) {
			cartDiscounts.mixedPack = getPackDiscount('underwear', counts.mixedPackBuilder);
		}

		cartDiscounts.mixedPackProducts = getDiscount(
			counts.mixedPackProducts,
			availableDiscounts.mixedPack,
		);

		cartDiscounts.prePackedUnderwear = getDiscount(
			counts.prePackedUnderwear,
			availableDiscounts.prePackedUnderwear,
		);
	}

	// **********
	// Loop through each line item and discount that line item
	//
	// NOTE: Discounts apply in order of priority, usually that's highest possible discount amount to lowest
	// but some cases where we want the discount to overrule others also apply (ie: checkout upsell should apply
	// before bulk boxer discount due to display price being static on front end)
	//
	// **********
	const discountedTypes = {};
	let indexesToRemove = [];
	lineItems.forEach((lineItem, index) => {
		let product = lineItem.merchandise.product;
		let productType = product.productType;

		const onSale =
			lineItem?.merchandise?.priceV2?.amount !== lineItem?.merchandise?.compareAtPriceV2?.amount;

		const isCloseout = checkProductTags(product.tags, 'capsule: Closeout');

		const isPackProduct = lineItem.attributes[PACK_PRODUCT] || lineItem.attributes[BUILD_A_PACK];
		const isJoinedProduct = product.tags.includes('joinedproduct');

		// never discount giftcards, free items
		if (product.gift_card || parseFloat(lineItem.linePrice) < 0.01) {
			return;
		}

		if (currentPromotion && currentPromotion.promotionType === 'subscription-discount') {
			const DISCOUNT_AMOUNT = 0.5;
			if (
				!discountedTypes[productType] &&
				lineItem.attributes[IS_SUBSCRIPTION] &&
				lineItem?.attributes[SUBSCRIPTION_TITLE]?.includes('Monthly')
			) {
				discountedTypes[productType] = true;
				lineItem.changeLinePrice(lineItem.linePrice * DISCOUNT_AMOUNT);
				lineItem.attributes[DISCOUNTED_SUBSCRIPTION_PROMOTION] = 'true';
			}
		}
		if (
			currentPromotion &&
			currentPromotion.promotionType === 'multi-product' &&
			currentPromotion.productTypes.includes(productType) &&
			multiProductPromotionPrices.length > 0 &&
			lineItem?.merchandise?.priceV2?.amount &&
			multiProductPromotionPrices.includes(parseFloat(lineItem.merchandise.priceV2.amount))
		) {
			const pricePromotionsAvailable = multiProductPromotionPrices.filter((price) => {
				return price === parseFloat(lineItem.merchandise.priceV2.amount);
			});

			const bogoLineItems = splitLineItem(
				lineItem,
				lineItem.quantity >= pricePromotionsAvailable.length
					? pricePromotionsAvailable.length
					: lineItem.quantity,
				parseFloat(lineItem.merchandise.priceV2.amount) * 1,
				parseFloat(lineItem.merchandise.priceV2.amount) *
					cartDiscounts.underwearPackBoxers.discountPercentInverse,
			);

			// // remove the discounts from multiProductPromotionPrices
			multiProductPromotionPrices = arrayRemoveItems(
				multiProductPromotionPrices,
				parseFloat(lineItem.merchandise.priceV2.amount),
				bogoLineItems.discountQuantity,
			);

			//store indexes we want to discard later
			indexesToRemove.push(index);
			// add the split line items
			lineItems.push(...bogoLineItems.items);
			return;
		}

		if (lineItem.attributes[MYSTERY_PACK_ITEM]) {
			const mysteryPackPrice = mysteryPacks[lineItem.attributes[PACK_ID]].price;
			lineItem.changeLinePrice(mysteryPackPrice.each, mysteryPackPrice.eachFull);
			return;
		}

		//
		// men's jogger bottoms get discounted with men's hoodies.
		//
		if (
			isCloseout === false &&
			isJoinedProduct &&
			productTypes.loungeBottomsMens.includes(productType)
		) {
			if (maxJoinedProductCounts.loungeMens > 0) {
				if (lineItem.quantity > maxJoinedProductCounts.loungeMens) {
					const discountedLounge = splitLineItem(
						lineItem,
						maxJoinedProductCounts.loungeMens,
						availableDiscounts.loungeWear,
					);
					maxJoinedProductCounts.loungeMens -= discountedLounge.quantity;
					lineItems.splice(index, 1);
					lineItems.push(...discountedLounge.items);
				} else {
					lineItem.changeLinePrice(
						lineItem.linePrice - availableDiscounts.loungeWear * lineItem.quantity,
					);
					maxJoinedProductCounts.loungeMens -= lineItem.quantity;
				}

				return;
			}
		}
		// end mens lounge

		//
		// women's jogger bottoms get discounted with women's hoodies.
		//
		if (
			isCloseout === false &&
			isJoinedProduct &&
			productTypes.loungeBottomsWomens.includes(productType)
		) {
			if (maxJoinedProductCounts.loungeWomens > 0) {
				if (lineItem.quantity > maxJoinedProductCounts.loungeWomens) {
					const discountedLounge = splitLineItem(
						lineItem,
						maxJoinedProductCounts.loungeWomens,
						availableDiscounts.loungeWear,
					);
					maxJoinedProductCounts.loungeWomens -= discountedLounge.quantity;
					lineItems.splice(index, 1);
					lineItems.push(...discountedLounge.items);
				} else {
					lineItem.changeLinePrice(
						lineItem.linePrice - availableDiscounts.loungeWear * lineItem.quantity,
					);
					maxJoinedProductCounts.loungeWomens -= lineItem.quantity;
				}

				return;
			}
		}
		// end women's lounge

		//
		// sleep bottoms get discounted with sleep tops
		//
		if (
			isCloseout === false &&
			isJoinedProduct &&
			productTypes.sleepBottoms.includes(productType)
		) {
			if (maxJoinedProductCounts.sleepwear > 0) {
				if (lineItem.quantity > maxJoinedProductCounts.sleepwear) {
					const discountedSleep = splitLineItem(
						lineItem,
						maxJoinedProductCounts.sleepwear,
						availableDiscounts.joinedSleepBottoms,
					);
					maxJoinedProductCounts.sleepwear -= discountedSleep.quantity;
					lineItems.splice(index, 1);
					lineItems.push(...discountedSleep.items);
				} else {
					lineItem.changeLinePrice(
						lineItem.linePrice - availableDiscounts.joinedSleepBottoms * lineItem.quantity,
					);
					maxJoinedProductCounts.sleepwear -= lineItem.quantity;
				}

				return;
			}
		}
		// end sleepwear

		// Suit discounting below
		if (
			isSuitDiscountSet &&
			isCloseout === false &&
			isJoinedProduct &&
			(productTypes.suitPants.includes(productType) ||
				productTypes.ties.includes(productType) ||
				productTypes.bowTies.includes(productType))
		) {
			// Black label suit pants more expensive && get a bigger discount
			let pantDiscountDollars = product.tags.includes('feature: Black Label')
				? availableDiscounts.joinedSuitPantsBlackLabel
				: availableDiscounts.joinedSuitPants;

			// if there are more pairs of pants in the cart than jackets,
			// create one line item for the maximum number of pants
			// and one line item for the rest
			if (productTypes.suitPants.includes(productType) && maxJoinedProductCounts.pants > 0) {
				if (lineItem.quantity > maxJoinedProductCounts.pants) {
					const discountedPants = splitLineItem(
						lineItem,
						maxJoinedProductCounts.pants,
						pantDiscountDollars,
					);
					maxJoinedProductCounts.pants -= discountedPants.quantity;
					lineItems.splice(index, 1);
					lineItems.push(...discountedPants.items);
				} else {
					lineItem.changeLinePrice(lineItem.linePrice - pantDiscountDollars * lineItem.quantity);
					maxJoinedProductCounts.pants -= lineItem.quantity;
				}

				// escape the loop
				return;
			} else if (
				(productType === 'Ties' || productType === 'Ties - NFL') &&
				maxJoinedProductCounts.ties > 0
			) {
				// See line 272 but w/ ties
				if (lineItem.quantity > maxJoinedProductCounts.ties) {
					const discountedTies = splitLineItem(
						lineItem,
						maxJoinedProductCounts.ties,
						availableDiscounts.joinedTies,
					);
					maxJoinedProductCounts.ties -= discountedTies.quantity;
					lineItems.splice(index, 1);
					lineItems.push(...discountedTies.items);
				} else {
					lineItem.changeLinePrice(
						lineItem.linePrice - availableDiscounts.joinedTies * lineItem.quantity,
					);
					maxJoinedProductCounts.ties -= lineItem.quantity;
				}

				// escape the loop
				return;
			} else if (productTypes.bowTies.includes(productType) && maxJoinedProductCounts.bowTies > 0) {
				if (lineItem.quantity > maxJoinedProductCounts.bowTies) {
					const discountedBowTies = splitLineItem(
						lineItem,
						maxJoinedProductCounts.bowTies,
						availableDiscounts.joinedBowTies,
					);
					maxJoinedProductCounts.bowTies -= discountedBowTies.quantity;
					lineItems.splice(index, 1);
					lineItems.push(...discountedBowTies.items);
				} else {
					lineItem.changeLinePrice(
						lineItem.linePrice - availableDiscounts.joinedBowTies * lineItem.quantity,
					);
					maxJoinedProductCounts.bowTies -= lineItem.quantity;
				}

				// escape the loop
				return;
			} else if (
				!lineItem.hasChanged &&
				(isHotTubClubMember || isActiveSubscriber || hasSubscriptionItem)
			) {
				// Old membership discounting for remaning suit pants && ties, legacy discount
				lineItem.changeLinePrice(lineItem.linePrice * cartDiscounts.default);

				// escape the loop
				return;
			}
		}
		if (
			hasSubscriptionItem &&
			coolingBoxerSub &&
			!onSale &&
			productType === PRODUCT_TYPE_BOXERS_COOLING &&
			!lineItem.attributes[BUILD_A_PACK]
		) {
			if (lineItem.quantity > 1) {
				const discountedCooling = splitLineItem(
					lineItem,
					1,
					DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_MONTHLY_DECIMAL,
				);
				lineItems.splice(index, 1);
				lineItems.push(...discountedCooling.items);
			} else {
				lineItem.changeLinePrice(
					lineItem.linePrice - DEFAULT_PRICE_SUBSCRIPTION_MENS_UNDERWEAR_MONTHLY_DECIMAL,
				);
			}
			coolingBoxerSub = false;
		}
		if (
			hasSubscriptionItem &&
			coolingThongSub &&
			productType === PRODUCT_TYPE_THONGS_COOLING &&
			!onSale &&
			!lineItem.attributes[BUILD_A_PACK]
		) {
			if (lineItem.quantity > 1) {
				const discountedCooling = splitLineItem(
					lineItem,
					1,
					DEFAULT_PRICE_SUBSCRIPTION_WOMENS_THONG_MONTHLY_DECIMAL,
				);
				lineItems.splice(index, 1);
				lineItems.push(...discountedCooling.items);
			} else {
				lineItem.changeLinePrice(
					lineItem.linePrice - DEFAULT_PRICE_SUBSCRIPTION_WOMENS_THONG_MONTHLY_DECIMAL,
				);
			}
			coolingThongSub = false;
		}

		// Subscription Account Page buy now property. Offers 20% discount from quickbuy functionality on customer account dashboard
		if (lineItem.attributes[IS_SUB_UP] || lineItem.attributes[SUB_BUY_NOW]) {
			lineItem.changeLinePrice(lineItem.linePrice * 0.8);

			// escape the loop
			return;
		}

		// Subscription Checkout offers post purchase upsell w/ discount of 35% at checkout
		if (lineItem.attributes[POST_SUB_UPSELL] && hasSubscriptionItem) {
			lineItem.changeLinePrice(lineItem.linePrice * 0.6537);

			// escape the loop
			return;
		}

		// Minicart logic: If a customer adds a product from the minicart to their cart, they
		// must have another item that was not added from the minicart to get the minicart discount on their item(s)
		if (
			(lineItem.attributes[MINI_CART_UPSELL] || lineItem.attributes[MENU_UPSELL]) &&
			hasNonCartItem
		) {
			const upsellDiscount = getUpsellDiscount(product.vendor);
			if (!product.tags.includes('mystery') && product.productType !== 'Gift Cards') {
				lineItem.changeLinePrice(lineItem.linePrice * upsellDiscount);
			}

			// escape the loop
			return;
		}

		if (isHotTubClubMember) {
			if (!lineItem.variant.compare_at_price || lineItem.variant.compare_at_price === 0) {
				lineItem.changeLinePrice(lineItem.linePrice * cartDiscounts.default);
				return;
			}

			if (
				!(
					((lineItem.variant.price.cents - lineItem.variant.compare_at_price.cents) /
						lineItem.variant.compare_at_price.cents) *
					100
				) < -20
			) {
				lineItem.changeLinePrice(
					lineItem.variant.compare_at_price * cartDiscounts.default * lineItem.quantity,
				);
				return;
			}

			if (
				!(
					((lineItem.variant.price.cents - lineItem.variant.compare_at_price.cents) /
						lineItem.variant.compare_at_price.cents) *
					100
				) < -30
			) {
				lineItem.changeLinePrice(
					lineItem.variant.compare_at_price * cartDiscounts.default * lineItem.quantity,
				);
				return;
			}
		}

		if (productType === "Women's Blazers" && isJoinedProduct && counts.womensSkirtsOrPants > 0) {
			// See line 272 but w/ womens suits. discount is inverted for womens suits as the blazer is discounted based on the number of skirts/womens pants in cart.
			if (lineItem.quantity > maxJoinedProductCounts.womensSkirtsOrPants) {
				const discountedBottoms = splitLineItem(
					lineItem,
					maxJoinedProductCounts.womensSkirtsOrPants,
					10.0,
				);
				maxJoinedProductCounts.womensSkirtsOrPants -= discountedBottoms.quantity;
				lineItems.splice(index, 1);
				lineItems.push(...discountedBottoms.items);
			} else {
				lineItem.changeLinePrice(lineItem.linePrice - 10.0 * lineItem.quantity);
				maxJoinedProductCounts.womensSkirtsOrPants -= lineItem.quantity;
			}

			// escape the loop
			return;
		}

		if (
			productType === 'Pajamas' &&
			product.tags.includes('product type:Night Cap') &&
			counts.nightGowns > 0
		) {
			// Pajama nightgown && caps are shown as a single product on PDP but split in cart (like a same type pack)
			// Following discounts will apply $5 off both nightgowns && caps based on the lowest number of either product
			// type. This splits the cart items of both product types similar to suit pant discounting if necessary
			if (lineItem.quantity > counts.nightGowns) {
				const discountedNightGowns = splitLineItem(
					lineItem,
					maxJoinedProductCounts.nightGowns,
					5.0,
				);
				maxJoinedProductCounts.nightGowns -= discountedNightGowns.quantity;
				lineItems.splice(index, 1);
				lineItems.push(...discountedNightGowns.items);
			} else {
				lineItem.changeLinePrice(lineItem.linePrice - 4.99 * lineItem.quantity);
				maxJoinedProductCounts.nightGowns -= lineItem.quantity;
			}

			// escape the loop
			return;
		}

		if (
			productType === 'Pajamas' &&
			product.tags.includes('product type:Nightgowns') &&
			!product.tags.includes('product type:Night Cap') &&
			counts.nightCaps > 0
		) {
			if (lineItem.quantity > counts.nightCaps) {
				const discountedNightCaps = splitLineItem(lineItem, maxJoinedProductCounts.nightCaps, 5.0);
				maxJoinedProductCounts.nightCaps -= discountedNightCaps.quantity;
				lineItems.splice(index, 1);
				lineItems.push(...discountedNightCaps.items);
			} else {
				lineItem.changeLinePrice(lineItem.linePrice - 5.0 * lineItem.quantity);
				counts.nightCaps -= lineItem.quantity;
			}

			// escape the loop
			return;
		}

		if (
			(lineItem.attributes[MIXED_PACK_BUILDER] || lineItem.attributes[SITEWIDE_PACK_BUILDER]) &&
			counts.mixedPackBuilder > 1 &&
			cartDiscounts.mixedPack?.discountPercentInverse
		) {
			// Apply mixed pack builder discount to all items added from the Mixed pack builder page if more than 1 are in the cart
			lineItem.changeLinePrice(lineItem.linePrice * cartDiscounts.mixedPack.discountPercentInverse);

			// escape the loop
			return;
		}

		const preBuiltPackType = lineItem.attributes[PREBUILT_PACK_TYPE];

		if (preBuiltPackType && preBuiltPacks[preBuiltPackType]) {
			lineItem.changeLinePrice(lineItem.linePrice * preBuiltPacks[preBuiltPackType].discount);
			return;
		}
		//if more than 2 socks make them 10 dollars
		if (
			productType.includes('Sock') &&
			!lineItem.attributes[IS_SUBSCRIPTION] &&
			!lineItem.attributes[PROMOTIONAL_ITEM]
		) {
			if (counts.socks > 2) {
				lineItem.changeLinePrice(lineItem.linePrice * 0.66644);
			}
		}

		if (lineItem.attributes[FROM_PACK_SKU] && lineItem.attributes[SINGLE_ITEM_PRICE]) {
			lineItem.changeLinePrice(lineItem.attributes[SINGLE_ITEM_PRICE] * lineItem.quantity);
		}

		if (
			lineItem.attributes[PACK_PRODUCT] &&
			!lineItem.attributes[BUILD_A_PACK] &&
			!lineItem.attributes[FROM_PACK_SKU]
		) {
			lineItem.changeLinePrice(lineItem.linePrice * cartDiscounts.prePackedUnderwear);
		}

		// Apply bulk discount to any boxer that was added from the build a boxer page || a same type pack PDP
		if (productType.includes('Boxers') && counts.underwearPackBoxers > 1 && isPackProduct) {
			if (lineItem.attributes[FROM_PACK_PRODUCT] && lineItem.attributes[FORTY_OFF_PACK_DISCOUNT]) {
				// Outdate AB test to test discounting
				lineItem.changeLinePrice(lineItem.linePrice * 0.6);
				return;
			}

			// Discount normal boxers added from packs || pack builder
			if (
				lineItem.attributes[BUILD_A_PACK] &&
				cartDiscounts.underwearPackBoxers?.discountPercentInverse
			) {
				lineItem.changeLinePrice(
					lineItem.linePrice * cartDiscounts.underwearPackBoxers.discountPercentInverse,
				);

				return;
			}
		}

		if (
			productType === PRODUCT_TYPE_BOYS_UNDERWEAR &&
			counts.underwearPackBoysUnderwear > 0 &&
			isPackProduct
		) {
			if (
				lineItem.attributes[BUILD_A_PACK] &&
				cartDiscounts.underwearPackBoysUnderwear?.discountPercentInverse
			) {
				lineItem.changeLinePrice(
					lineItem.linePrice * cartDiscounts.underwearPackBoysUnderwear.discountPercentInverse,
				);

				return;
			}

			// escape the loop
			return;
		}

		if (
			(lineItem.attributes[MIXED_PACK_PRODUCT] || lineItem.attributes[FROM_MIXED_PACK_PRODUCT]) &&
			!productType.includes('Kimono') &&
			!productType.includes('Hygiene') &&
			!productType.includes('Socks') &&
			counts.mixedPackProducts > 1
		) {
			lineItem.changeLinePrice(lineItem.linePrice * cartDiscounts.mixedPackProducts);
			// escape the loop
			return;
		}

		if (
			[
				PRODUCT_TYPE_BIKINI,
				PRODUCT_TYPE_BOYSHORT,
				PRODUCT_TYPE_CHEEKY,
				PRODUCT_TYPE_THONGS,
				PRODUCT_TYPE_WOMENS_BOXER,
			].includes(productType) &&
			isPackProduct
		) {
			// Hanldes build a womens underwear pack page && same type pack PDP discounting
			if (
				productType === PRODUCT_TYPE_CHEEKY &&
				counts.underwearPackCheekies > 1 &&
				cartDiscounts.underwearPackCheekies?.discountPercentInverse
			) {
				lineItem.changeLinePrice(
					lineItem.linePrice * cartDiscounts.underwearPackCheekies?.discountPercentInverse,
				);
				return;
			} else if (
				productType === PRODUCT_TYPE_BIKINI &&
				counts.underwearPackBikinis > 1 &&
				cartDiscounts.underwearPackBikinis?.discountPercentInverse
			) {
				lineItem.changeLinePrice(
					lineItem.linePrice * cartDiscounts.underwearPackBikinis.discountPercentInverse,
				);
			} else if (
				productType === PRODUCT_TYPE_BOYSHORT &&
				counts.underwearPackBoyshorts > 1 &&
				cartDiscounts.underwearPackBoyshorts?.discountPercentInverse
			) {
				lineItem.changeLinePrice(
					lineItem.linePrice * cartDiscounts.underwearPackBoyshorts.discountPercentInverse,
				);
			} else if (
				productType === PRODUCT_TYPE_THONGS &&
				counts.underwearPackThongs > 1 &&
				cartDiscounts.underwearPackThongs?.discountPercentInverse
			) {
				lineItem.changeLinePrice(
					lineItem.linePrice * cartDiscounts.underwearPackThongs.discountPercentInverse,
				);
			} else if (
				productType === PRODUCT_TYPE_THONGS_MODAL &&
				counts.underwearPackThongsModal > 1 &&
				cartDiscounts.underwearPackThongsModal?.discountPercentInverse
			) {
				lineItem.changeLinePrice(
					lineItem.linePrice * cartDiscounts.underwearPackThongsModal.discountPercentInverse,
				);
			} else if (
				productType === PRODUCT_TYPE_WOMENS_BOXER &&
				counts.underwearPackWomensBoxer > 1 &&
				cartDiscounts.underwearPackWomensBoxer?.discountPercentInverse
			) {
				lineItem.changeLinePrice(
					lineItem.linePrice * cartDiscounts.underwearPackWomensBoxer.discountPercentInverse,
				);
			}

			// escape the loop
			return;
		}

		if (productType === "Men's Hygiene") {
			if (parseGid(product.id) === 4666284474437 && counts.mensHygiene > 2) {
				// Bulk discounting bar soap product
				lineItem.changeLinePrice(lineItem.linePrice - 1.66 * lineItem.quantity);
			} else if (parseGid(product.id) === 4484210425925) {
				// Ball cream discounting. If added from speicifc PDPs (build a fathers day pack || build a holiday pack) discount accordingly
				if (lineItem.attributes[BUILD_A_PACK]) {
					lineItem.changeLinePrice(lineItem.linePrice - 1.0 * lineItem.quantity);
				} else if (lineItem.attributes[FROM_PACK_SKU] === 'MPL-SHNQ121-KT2028-OSFA') {
					lineItem.changeLinePrice(lineItem.linePrice - 1.33 * lineItem.quantity);
				}
			}
		}
	});

	//loop backwards through lineitems and remove any indexes that need removing
	for (let i = lineItems.length - 1; i >= 0; i--) {
		if (indexesToRemove.includes(i)) {
			lineItems.splice(i, 1);
		}
	}

	const cartTotals = lineItems.reduce(
		(memo, lineItem) => {
			const isShippingProtection = lineItem?.merchandise?.product?.title?.includes('Shipping');
			if (
				lineItem.attributes[IS_SUBSCRIPTION_PARENT] !== 'true' &&
				!isShippingProtection &&
				!lineItem.attributes[IS_HIDDEN]
			) {
				memo.subTotal += lineItem.attributes[DISCOUNTED_LINE_PRICE]
					? parseFloat(lineItem.attributes[DISCOUNTED_LINE_PRICE]) * lineItem.quantity
					: lineItem.linePrice;

				memo.compareAtPrice +=
					(parseFloat(lineItem?.merchandise?.compareAtPriceV2?.amount) || lineItem.linePrice || 0) *
					lineItem.quantity;
			}
			try {
				memo.undiscountedTotal +=
					parseFloat(lineItem.attributes[PROMOTIONAL_PRICE]) || parseFloat(lineItem.linePrice) || 0;
			} catch (error) {
				memo.undiscountedTotal += 0;
			}

			if (
				lineItem.attributes[PROMOTIONAL_ITEM] === 'true' &&
				lineItem.attributes[IS_OPTED_OUT] !== 'true' &&
				lineItem?.merchandise?.compareAtPriceV2?.amount
			) {
				memo.promotionalTotal += parseFloat(lineItem.merchandise.compareAtPriceV2.amount) || 0;
			}

			const hiddenAttribute = lineItem?.attributes[IS_HIDDEN] || isShippingProtection;

			if (!hiddenAttribute) {
				try {
					memo.productCount += parseInt(lineItem.quantity);
				} catch (e) {
					console.log(e);
				}
			}

			return memo;
		},
		{ compareAtPrice: 0, productCount: 0, promotionalTotal: 0, subTotal: 0, undiscountedTotal: 0 },
	);

	return {
		...cart,
		compareAtPrice: cartTotals.compareAtPrice,
		hasConjuredSubscription,
		hasSubscriptionItem,
		hasMonthlySubscriptionItem,
		undiscountedTotal: cartTotals.undiscountedTotal,
		productCount: cartTotals.productCount,
		processedLineItems: lineItems,
		promotionalTotal: cartTotals.promotionalTotal,
		hasItems: lineItems.length > 0,
		subTotal: cartTotals.subTotal,
	};
};
