import PropTypes from 'prop-types';

function SelectGroup({
	className = '',
	name,
	options = [],
	onChange = () => {},
	error = false,
	errorText = '',
	description = '',
	placeholder = '',
	label = '',
	noBorder = false,
	variant = 'default',
	...newProps
}) {
	const hasError = error || errorText;
	let finalClass = `${className} block appearance-none w-full bg-white rounded-sm pr-8 cursor-pointer focus:outline-none`;
	if (hasError) finalClass += ' border-red-600';
	if (!noBorder) finalClass += ' border border-gray-300 hover:border-gray-400';

	if (variant === 'short') {
		finalClass += ' pl-2 py-1';
	} else if (variant === 'medium') {
		finalClass += ' pl-3 py-1.5';
	} else {
		finalClass += ' pl-4 py-3';
	}

	const optionsData = options.reduce(
		(memo, o) => {
			let key = `${o.value}-${name}`;

			if (o.disabled) {
				key += '-disabled';
			}

			memo.html.push(
				<option key={key} value={o.value} disabled={o.disabled}>
					{o.label}
				</option>,
			);
			memo.lookup[o.value] = o.label;
			return memo;
		},
		{ html: [], lookup: {} },
	);

	return (
		<div>
			{label && (
				<label
					className={`text-sm text-gray-600 inline-block ${hasError && 'text-primary'}`}
					htmlFor={name}>
					{label}
				</label>
			)}
			<div className="flex relative w-full">
				<select
					className={finalClass}
					// defaultValue={defaultValue || 'x-placeholder-x'}
					onChange={(e) => {
						onChange(e.currentTarget.value, optionsData.lookup[e.currentTarget.value]);
					}}
					{...newProps}>
					{placeholder ? (
						<option disabled="disabled" value="x-placeholder-x">
							{placeholder}
						</option>
					) : null}
					{optionsData.html}
				</select>
				<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-primary">
					<svg
						className="fill-current h-6 w-6"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20">
						<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
					</svg>
				</div>
			</div>
			{description && <span className="mt-2 text-gray-600 text-xs">{description}</span>}
			{errorText && (
				<div className="bg-red-200 mt-2 py-2 px-4 text-xs text-primary rounded-sm">{errorText}</div>
			)}
		</div>
	);
}

SelectGroup.propTypes = {
	className: PropTypes.string,
	defaultValue: PropTypes.string,
	description: PropTypes.string,
	error: PropTypes.bool,
	errorText: PropTypes.string,
	label: PropTypes.string,
	name: PropTypes.string,
	noBorder: PropTypes.bool,
	onChange: PropTypes.func,
	options: PropTypes.array.isRequired,
	placeholder: PropTypes.string,
	variant: PropTypes.oneOf(['default', 'medium', 'short']),
};

export default SelectGroup;
