import { gql } from '@apollo/client';

const GET_PRODUCTS_FOR_COLLECTION = gql`
	query getProducts($collectionHandle: String!, $limit: Int!) {
		collectionByHandle(handle: $collectionHandle) {
			products(first: $limit) {
				edges {
					node {
						id
						availableForSale
						buttonImg: metafield(namespace: "product", key: "pack_img") {
							value
							type
						}
						description
						handle
						images(first: 10) {
							edges {
								node {
									src
									altText
									width
									height
								}
							}
						}
						options {
							id
							name
							values
						}
						priceRange {
							minVariantPrice {
								amount
							}
							maxVariantPrice {
								amount
							}
						}
						productType
						tags
						title
						variants(first: 100) {
							edges {
								node {
									availableForSale
									id
									image {
										src
										altText
										width
										height
									}
									selectedOptions {
										name
										value
									}
									dcInventory: metafield(namespace: "denver_inventory", key: "quantity") {
										value
									}
									sku
									title
									quantityAvailable
									currentlyNotInStock
								}
							}
						}
						vendor
					}
				}
			}
		}
	}
`;

export default GET_PRODUCTS_FOR_COLLECTION;
