const PRODUCT_TYPE_BIKINI = 'Bikini';
const PRODUCT_TYPE_BIKINI_LOWER = 'bikini';
const PRODUCT_TYPE_BIKINI_LACE = 'Bikini Lace';
const PRODUCT_TYPE_BIKINI_LACE_LOWER = 'bikini lace';
const PRODUCT_TYPE_BOXER = 'Boxer';
const PRODUCT_TYPE_BOXER_LOWER = 'boxer';
const PRODUCT_TYPE_BOXER_BRIEF = 'Boxer - Brief';
const PRODUCT_TYPE_BOXER_BRIEF_LOWER = 'boxer - brief';
const PRODUCT_TYPE_BOXER_FLY = 'Boxer - Fly';
const PRODUCT_TYPE_BOXER_FLY_LOWER = 'boxer - fly';
const PRODUCT_TYPE_BOXER_LONG_FLY = 'Boxer - Long - Fly';
const PRODUCT_TYPE_BOXER_LONG_FLY_LOWER = 'boxer - long - fly';
const PRODUCT_TYPE_BOXER_TRUNK = 'Boxer - Trunk';
const PRODUCT_TYPE_BOXER_TRUNK_LOWER = 'boxer - trunk - lower';
const PRODUCT_TYPE_BOXERS = 'Boxers';
const PRODUCT_TYPE_BOXERS_LOWER = 'boxers';
const PRODUCT_TYPE_BOXERS_BRIEF = 'Boxers - Brief';
const PRODUCT_TYPE_BOXERS_BRIEF_LOWER = 'boxers - brief';
const PRODUCT_TYPE_BOXERS_COOLING = 'Boxers - Cooling - Fly';
const PRODUCT_TYPE_BOXERS_COOLING_LOWER = 'boxers - cooling - fly';
const PRODUCT_TYPE_BOXERS_COOLING_LONG = 'Boxers - Cooling - Long';
const PRODUCT_TYPE_BOXERS_COOLING_LONG_LOWER = 'boxers - cooling - long';
const PRODUCT_TYPE_BOXERS_FLY = 'Boxers - Fly';
const PRODUCT_TYPE_BOXERS_FLY_LOWER = 'boxers - fly';
const PRODUCT_TYPE_BOXERS_LOOSE = 'Boxers - Loose';
const PRODUCT_TYPE_BOXERS_LOOSE_LOWER = 'boxers - loose';
const PRODUCT_TYPE_BOXERS_LONG_FLY = 'Boxers - Long - Fly';
const PRODUCT_TYPE_BOXERS_LONG_FLY_LOWER = 'boxers - long - fly';
const PRODUCT_TYPE_BOXERS_TRUNK = 'Boxers - Trunk';
const PRODUCT_TYPE_BOXERS_TRUNK_LOWER = 'boxers - trunk';
const PRODUCT_TYPE_BOYS_UNDERWEAR = 'Boys Underwear';
const PRODUCT_TYPE_BOYS_UNDERWEAR_LOWER = 'boys underwear';
const PRODUCT_TYPE_BOYSHORT = 'Boyshort';
const PRODUCT_TYPE_BOYSHORT_LOWER = 'boyshort';
const PRODUCT_TYPE_BRALETTES = 'Bralettes';
const PRODUCT_TYPE_BRALETTES_LOWER = 'bralettes';
const PRODUCT_TYPE_BRALETTES_COOLING = 'Bralettes - Cooling';
const PRODUCT_TYPE_BRALETTES_COOLING_LOWER = 'bralettes - cooling';
const PRODUCT_TYPE_CHEEKY = 'Cheeky';
const PRODUCT_TYPE_CHEEKY_LOWER = 'cheeky';
const PRODUCT_TYPE_LAUNDRY_SHEETS = 'Laundry Detergent Sheets';
const PRODUCT_TYPE_LAUNDRY_SHEETS_LOWER = 'laundry detergent sheets';
const PRODUCT_TYPE_LONG_JOHNS = 'Long Johns';
const PRODUCT_TYPE_LONG_JOHNS_LOWER = 'long johns';
const PRODUCT_TYPE_LOUNGE_MENS_HOODIE = "Men's Lounge Hoodie";
const PRODUCT_TYPE_LOUNGE_MENS_HOODIE_LOWER = "men's lounge hoodie";
const PRODUCT_TYPE_LOUNGE_MENS_JOGGER = "Men's Lounge Jogger";
const PRODUCT_TYPE_LOUNGE_MENS_JOGGER_LOWER = "men's lounge jogger";
const PRODUCT_TYPE_LOUNGE_WOMENS_HOODIE = "Women's Lounge Hoodie";
const PRODUCT_TYPE_LOUNGE_WOMENS_HOODIE_LOWER = "women's lounge hoodie";
const PRODUCT_TYPE_LOUNGE_WOMENS_JOGGER = "Women's Lounge Jogger";
const PRODUCT_TYPE_LOUNGE_WOMENS_JOGGER_LOWER = "women's lounge jogger";
const PRODUCT_TYPE_SLEEP_MENS_TOPS = 'Pajama Tops';
const PRODUCT_TYPE_SLEEP_MENS_TOPS_LOWER = 'pajama tops';
const PRODUCT_TYPE_SLEEP_MENS_BOTTOMS = 'Pajama Bottoms';
const PRODUCT_TYPE_SLEEP_MENS_BOTTOMS_LOWER = 'pajama bottoms';
const PRODUCT_TYPE_SLEEP_WOMENS_SETS = "Women's Pajama Set";
const PRODUCT_TYPE_SLEEP_WOMENS_SETS_LOWER = "women's pajama set";
const PRODUCT_TYPE_SLEEP_WOMENS_DRESS = 'Sleep Dress';
const PRODUCT_TYPE_SLEEP_WOMENS_DRESS_LOWER = 'sleep dress';
const PRODUCT_TYPE_SOCKS_ANKLE = 'Socks - Ankle';
const PRODUCT_TYPE_SOCKS_ANKLE_LOWER = 'socks - ankle';
const PRODUCT_TYPE_SOCKS_CREW = 'Socks - Crew';
const PRODUCT_TYPE_SOCKS_CREW_LOWER = 'socks - crew';
const PRODUCT_TYPE_THONG_LACE = 'Thong Lace';
const PRODUCT_TYPE_THONG_LACE_LOWER = 'thong lace';
const PRODUCT_TYPE_THONGS = 'Thongs';
const PRODUCT_TYPE_THONGS_LOWER = 'thongs';
const PRODUCT_TYPE_THONGS_COOLING = 'Thongs - Cooling';
const PRODUCT_TYPE_THONGS_COOLING_LOWER = 'thongs - cooling';
const PRODUCT_TYPE_THONGS_MODAL = 'Thongs - Modal';
const PRODUCT_TYPE_THONGS_MODAL_LOWER = 'thongs modal';
const PRODUCT_TYPE_T_SHIRTS = "Men's T-Shirts";
const PRODUCT_TYPE_T_SHIRTS_LOWER = "men's t-shirts";
const PRODUCT_TYPE_WOMENS_BOXER = "Women's Boxer";
const PRODUCT_TYPE_WOMENS_BOXER_LOWER = "women's boxer";

const PRODUCT_TYPES = [
	{ id: PRODUCT_TYPE_BOXERS, title: 'Boxers', titleShort: 'No Fly' },
	{ id: PRODUCT_TYPE_BOXERS_FLY, title: 'Boxers - Fly', titleShort: 'With Fly' },
	{ id: PRODUCT_TYPE_BOXERS_LONG_FLY, title: 'Boxers - Fly - Long', titleShort: 'Long' },
	{ id: PRODUCT_TYPE_BOXERS_BRIEF, title: 'Boxers - Brief', titleShort: 'Briefs' },
	{ id: PRODUCT_TYPE_BOXERS_TRUNK, title: 'Boxers - Trunk', titleShort: 'Trunks' },
	{ id: PRODUCT_TYPE_BOXER, title: 'Boxers', titleShort: 'No Fly' },
	{ id: PRODUCT_TYPE_BOXER_FLY, title: 'Boxers - Fly', titleShort: 'With Fly' },
	{ id: PRODUCT_TYPE_BOXER_LONG_FLY, title: 'Boxers - Fly - Long', titleShort: 'Long' },
	{ id: PRODUCT_TYPE_BOXER_BRIEF, title: 'Boxers - Brief', titleShort: 'Briefs' },
	{ id: PRODUCT_TYPE_BOXER_TRUNK, title: 'Boxers - Trunk', titleShort: 'Trunks' },
	{ id: PRODUCT_TYPE_BOYSHORT, title: 'Boyshort', titleShort: 'Boyshort' },
	{ id: PRODUCT_TYPE_BIKINI, title: 'Bikini', titleShort: 'Bikini' },
	{ id: PRODUCT_TYPE_CHEEKY, title: 'Cheeky', titleShort: 'Cheeky' },
	{ id: PRODUCT_TYPE_LAUNDRY_SHEETS, title: 'Laundry Sheets', titleShort: 'Laundry Sheets' },
	{ id: PRODUCT_TYPE_THONGS, title: 'Thongs', titleShort: 'Thong' },
	{ id: PRODUCT_TYPE_THONGS_MODAL, title: 'Modal Thongs', titleShort: 'Modal Thongs' },
	{ id: PRODUCT_TYPE_SOCKS_ANKLE, title: 'Socks', titleShort: 'Socks' },
	{ id: PRODUCT_TYPE_SOCKS_CREW, title: 'Socks', titleShort: 'Socks' },
	{ id: PRODUCT_TYPE_T_SHIRTS, title: 'Everyman Tee', titleShort: 'Everyman Tee' },
	{ id: PRODUCT_TYPE_WOMENS_BOXER, title: "Women's Boxer", titleShort: "Women's Boxer" },
];

const getProductTypes = () => {
	return PRODUCT_TYPES;
};

const getProductType = (id) => {
	return PRODUCT_TYPES.find((productType) => {
		return productType.id === id;
	});
};

module.exports = {
	PRODUCT_TYPE_BIKINI,
	PRODUCT_TYPE_BIKINI_LOWER,
	PRODUCT_TYPE_BIKINI_LACE,
	PRODUCT_TYPE_BIKINI_LACE_LOWER,
	PRODUCT_TYPE_BOXER,
	PRODUCT_TYPE_BOXER_LOWER,
	PRODUCT_TYPE_BOXER_BRIEF,
	PRODUCT_TYPE_BOXER_BRIEF_LOWER,
	PRODUCT_TYPE_BOXER_FLY,
	PRODUCT_TYPE_BOXER_FLY_LOWER,
	PRODUCT_TYPE_BOXER_LONG_FLY,
	PRODUCT_TYPE_BOXER_LONG_FLY_LOWER,
	PRODUCT_TYPE_BOXER_TRUNK,
	PRODUCT_TYPE_BOXER_TRUNK_LOWER,
	PRODUCT_TYPE_BOXERS,
	PRODUCT_TYPE_BOXERS_LOWER,
	PRODUCT_TYPE_BOXERS_BRIEF,
	PRODUCT_TYPE_BOXERS_BRIEF_LOWER,
	PRODUCT_TYPE_BOXERS_COOLING,
	PRODUCT_TYPE_BOXERS_COOLING_LOWER,
	PRODUCT_TYPE_BOXERS_COOLING_LONG,
	PRODUCT_TYPE_BOXERS_COOLING_LONG_LOWER,
	PRODUCT_TYPE_BOXERS_FLY,
	PRODUCT_TYPE_BOXERS_FLY_LOWER,
	PRODUCT_TYPE_BOXERS_LONG_FLY,
	PRODUCT_TYPE_BOXERS_LONG_FLY_LOWER,
	PRODUCT_TYPE_BOXERS_LOOSE,
	PRODUCT_TYPE_BOXERS_LOOSE_LOWER,
	PRODUCT_TYPE_BOXERS_TRUNK,
	PRODUCT_TYPE_BOXERS_TRUNK_LOWER,
	PRODUCT_TYPE_BOYS_UNDERWEAR,
	PRODUCT_TYPE_BOYS_UNDERWEAR_LOWER,
	PRODUCT_TYPE_BOYSHORT,
	PRODUCT_TYPE_BOYSHORT_LOWER,
	PRODUCT_TYPE_BRALETTES,
	PRODUCT_TYPE_BRALETTES_LOWER,
	PRODUCT_TYPE_BRALETTES_COOLING,
	PRODUCT_TYPE_BRALETTES_COOLING_LOWER,
	PRODUCT_TYPE_CHEEKY,
	PRODUCT_TYPE_CHEEKY_LOWER,
	PRODUCT_TYPE_LAUNDRY_SHEETS,
	PRODUCT_TYPE_LAUNDRY_SHEETS_LOWER,
	PRODUCT_TYPE_LONG_JOHNS,
	PRODUCT_TYPE_LONG_JOHNS_LOWER,
	PRODUCT_TYPE_LOUNGE_MENS_HOODIE,
	PRODUCT_TYPE_LOUNGE_MENS_HOODIE_LOWER,
	PRODUCT_TYPE_LOUNGE_MENS_JOGGER,
	PRODUCT_TYPE_LOUNGE_MENS_JOGGER_LOWER,
	PRODUCT_TYPE_LOUNGE_WOMENS_HOODIE,
	PRODUCT_TYPE_LOUNGE_WOMENS_HOODIE_LOWER,
	PRODUCT_TYPE_LOUNGE_WOMENS_JOGGER,
	PRODUCT_TYPE_LOUNGE_WOMENS_JOGGER_LOWER,
	PRODUCT_TYPE_SLEEP_MENS_TOPS,
	PRODUCT_TYPE_SLEEP_MENS_TOPS_LOWER,
	PRODUCT_TYPE_SLEEP_MENS_BOTTOMS,
	PRODUCT_TYPE_SLEEP_MENS_BOTTOMS_LOWER,
	PRODUCT_TYPE_SLEEP_WOMENS_SETS,
	PRODUCT_TYPE_SLEEP_WOMENS_SETS_LOWER,
	PRODUCT_TYPE_SLEEP_WOMENS_DRESS,
	PRODUCT_TYPE_SLEEP_WOMENS_DRESS_LOWER,
	PRODUCT_TYPE_SOCKS_ANKLE,
	PRODUCT_TYPE_SOCKS_ANKLE_LOWER,
	PRODUCT_TYPE_SOCKS_CREW,
	PRODUCT_TYPE_SOCKS_CREW_LOWER,
	PRODUCT_TYPE_T_SHIRTS,
	PRODUCT_TYPE_T_SHIRTS_LOWER,
	PRODUCT_TYPE_THONG_LACE,
	PRODUCT_TYPE_THONG_LACE_LOWER,
	PRODUCT_TYPE_THONGS,
	PRODUCT_TYPE_THONGS_LOWER,
	PRODUCT_TYPE_THONGS_COOLING,
	PRODUCT_TYPE_THONGS_COOLING_LOWER,
	PRODUCT_TYPE_THONGS_MODAL,
	PRODUCT_TYPE_THONGS_MODAL_LOWER,
	PRODUCT_TYPE_WOMENS_BOXER,
	PRODUCT_TYPE_WOMENS_BOXER_LOWER,
	getProductType,
	getProductTypes,
};
