import Price from '../Price';
import PropTypes from 'prop-types';

const DiscountCodeBannerText = ({
	bannerTextKey = 'bannerTextDefault',
	discountCodeConfig,
	swapCode,
}) => {
	if (!discountCodeConfig) {
		return null;
	}

	let bannerText = discountCodeConfig?.[bannerTextKey] || discountCodeConfig?.bannerTextDefault;

	if (!bannerText) {
		return null;
	}

	if (discountCodeConfig?.bannerText?.includes('[discountCode]') && swapCode) {
		bannerText = bannerText.replaceAll('[discountCode]', swapCode);
	}

	if (
		bannerText?.indexOf('[subscriptionPrice]') > -1 &&
		typeof discountCodeConfig.subscriptionPrice !== 'undefined'
	) {
		const splitBannerText = bannerText.split('[subscriptionPrice]');

		if (splitBannerText.length === 2) {
			return (
				<>
					{splitBannerText[0]}
					<Price price={discountCodeConfig.subscriptionPrice} />
					{splitBannerText[1]}
				</>
			);
		}
	}

	return bannerText;
};

DiscountCodeBannerText.propTypes = {
	bannerTextKey: PropTypes.string,
	discountCodeConfig: PropTypes.object,
	swapCode: PropTypes.string,
};

export default DiscountCodeBannerText;
