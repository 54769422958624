import { CURRENCY } from './constants';
import { formatProducts } from './formatters';
import sendEvent from './send-event';

const trackViewCart = (payload, checkoutUrl) => {
	const products = payload?.map((lineItem) => {
		return {
			quantity: lineItem?.quantity,
			price: lineItem?.linePrice,
			shopifyProductId: lineItem?.merchandise?.product?.id,
			...lineItem?.merchandise?.product,
		};
	});

	const formattedData = formatProducts(products);

	sendEvent('ViewCart', {
		checkoutUrl,
		contents: formattedData.contents,
		currency: CURRENCY,
		value: formattedData.totalValue,
	});
};

export default trackViewCart;
