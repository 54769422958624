import Clickable from '/components/Clickable';
import PropTypes from 'prop-types';
import { reactChildren } from '/utils/prop-types';

const ClickToDetails = ({ children, className, heapLocation, onClick, product, rank }) => (
	<Clickable
		className={className}
		googleEventData={{
			product: product,
			rank: rank,
		}}
		googleEventName="trackProductClick"
		heapEventName="Product Card Click"
		heapEventData={{
			Location: heapLocation,
			Price: product.price, // TODO: sale pricing?
			Product: product.handle,
			Position: rank,
			'Product Type': product.product_type,
			objectId: product.objectID,
		}}
		linkToInternal={product.href}
		onClick={onClick}>
		{children}
	</Clickable>
);

ClickToDetails.propTypes = {
	children: reactChildren,
	className: PropTypes.string,
	heapLocation: PropTypes.string,
	onClick: PropTypes.func,
	product: PropTypes.object,
	rank: PropTypes.number,
};

export default ClickToDetails;
