import { trackLead } from '../services/analytics/shinesty-analytics';
import { useEffect } from 'react';

const TrackLeadInit = () => {
	useEffect(() => {
		window.trackLead = trackLead;
	}, []);
	return null;
};
export default TrackLeadInit;
