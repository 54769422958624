import { parseGid } from '/utils/graph-ql';

export const formatProductsAxon = (products) => {
	return products.map((product) => {
		const axonProduct = {
			item_id: parseGid(product.shopifyProductId),
			item_name: product.title,
			price: parseFloat(product.price),
			quantity: product?.quantity || 1,
			item_category: product.productType,
			image_url: product.image,
			item_brand: 'Shinesty',
		};

		if (product.shopifyVariantId) {
			axonProduct.item_variant = parseGid(product.shopifyVariantId);
		}

		return axonProduct;
	});
};
