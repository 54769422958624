import NavLink from '/components/top-nav/mobile-menu/NavLink';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';

import { AccordionItem, AccordionPanel } from '/components/Accordion';

const NavItem = ({ navItem, position, resetMenu }) => {
	if (!navItem.title) {
		return null;
	}

	if (!navItem.children || navItem.children.length === 0) {
		return <NavLink item={navItem} resetMenu={resetMenu} />;
	}

	const showNavLink = !navItem.title.includes('Build') && !navItem.title.includes('Sub');

	return (
		<>
			<AccordionItem
				toggle={`nav-panel-${position}`}
				className="bg-gray-600 text-white"
				variant="mobile-nav">
				<Typography
					className="capitalize font-semibold pl-3 text-primary-dark !tracking-wider"
					variant="body">
					{navItem.title}
				</Typography>
			</AccordionItem>
			<AccordionPanel id={`nav-panel-${position}`}>
				{showNavLink && (
					<NavLink item={navItem} resetMenu={resetMenu} showShopAll={true} variant="plain" />
				)}

				{navItem.children.map((child, index) => {
					return (
						<div className="flex" key={index}>
							<NavLink item={child} resetMenu={resetMenu} variant="plain" />
						</div>
					);
				})}
			</AccordionPanel>
		</>
	);
};

NavItem.propTypes = {
	navItem: PropTypes.object,
	position: PropTypes.number,
	resetMenu: PropTypes.func,
};

export default NavItem;
