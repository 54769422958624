import { makeAutoObservable, toJS } from 'mobx';

const VALID_PRODUCT_TYPES = [
	'Bikini',
	'Boxers',
	'Boxers - Brief',
	'Boxers - Fly',
	'Boxers - Long - Fly',
	'Boxers - Trunk',
	'Boyshort',
	'Bralette',
	'Brief Lace - High Waist',
	'Cheeky',
	'No Show Bikini - High Waist',
	'No Show Thong - High Waist',
	'Thongs',
	'Thong Lace - High Waist',
	"Women's Boxer",
	'Thongs - Modal',
];

export class SizeStore {
	constructor() {
		makeAutoObservable(this);
	}

	selectedSize;

	getSize(productType) {
		if (!VALID_PRODUCT_TYPES.includes(productType)) {
			return;
		}

		return this.selectedSize;
	}

	setSize(productType, variantTitle) {
		if (VALID_PRODUCT_TYPES.includes(productType)) {
			this.selectedSize = variantTitle;
		}
	}

	plain() {
		return toJS(this);
	}
}
