export const USE_ALGOLIA_BACKUPS = false;

export const FACETS = {
	tags: 'tags',
	'_availableSizes.ecommerce': 'Size',
	shopifyProductType: 'Product Type',
};

export const TAG_BASED_FACETS = {
	theme: 'Theme',
	color: 'Color',
	print: 'Print',
	gender: 'Gender',
};

const SORT_OPTIONS_DEFAULT = {
	'most relevant': 'production_shinesty-pipeline-main',
	popularity: 'production_shinesty-pipeline-order-count-descending',
	'highest price': 'production_shinesty-pipeline-price-descending',
	'lowest price': 'production_shinesty-pipeline-price-ascending',
	new: 'production_shinesty-pipeline-publish-date-descending',
	title: 'production_shinesty-pipeline-title-ascending',
};

const SORT_OPTIONS_BACKUP = {
	'most relevant': 'backup_shinesty_products',
	popularity: 'backup_shinesty_products_recently_ordered_count_desc',
	'lowest price': 'backup_shinesty_products_price_asc',
	'highest price': 'backup_shinesty_products_price_desc',
};

export const SORT_OPTIONS = USE_ALGOLIA_BACKUPS ? SORT_OPTIONS_BACKUP : SORT_OPTIONS_DEFAULT;

export const DEFAULT_INDEX = 'production_shinesty-pipeline-main';

export const QUERY_ID_KEY = 'shinesty:algolia:queryid';
