import PropTypes from 'prop-types';
import React from 'react';

import { reactChildren } from '/utils/prop-types';

const DiscountCodeContext = React.createContext(null);

export const DiscountCodeProvider = ({ value, children }) => {
	return <DiscountCodeContext.Provider value={value}>{children}</DiscountCodeContext.Provider>;
};

export const useDiscountCodeStore = () => React.useContext(DiscountCodeContext);

DiscountCodeProvider.propTypes = {
	children: reactChildren,
	value: PropTypes.object,
};
