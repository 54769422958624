const getAspectRatio = (defaultHeight, defaultWidth, newWidth) => {
	if (
		!Number.isInteger(defaultHeight) ||
		!Number.isInteger(defaultWidth) ||
		!Number.isInteger(newWidth)
	) {
		return;
	}

	return {
		height: (defaultHeight / defaultWidth) * newWidth,
		width: newWidth,
	};
};

/**
 * returns a string that defines next image sizes string for dynamically resized images
 * https://nextjs.org/docs/api-reference/next/image#sizes
 * @param {array} srcSets [{maxWidth: '123px', imageWidth: '123px'}]
 * @param {string} defaultSize '123px'
 * @returns a formatted string to be consumed by next/image
 */
const getNextImageSizes = (settings) => {
	let sizeConfig = '';

	if (settings.srcSets) {
		settings.srcSets.forEach((setting) => {
			sizeConfig += `(max-width: ${setting.maxWidth}) ${setting.imageWidth}, `;
		});
	}

	sizeConfig += settings.defaultSize;

	return sizeConfig;
};

module.exports = {
	getAspectRatio,
	getNextImageSizes,
};
