import { formatProductsAxon } from './formatters';
import sendEvent from './send-event';

const trackViewItem = (products) => {
	try {
		const formattedProducts = formatProductsAxon(products);
		sendEvent('view_item', {
			currency: 'usd',
			items: formattedProducts,
		});
	} catch (e) {
		console.log(e);
	}
};

export default trackViewItem;
