import Button from '/components/Button';
import Icon from '/components/IconUpdated';
import Image from '/components/Image';
import MeasurementTables from '/components/size-guides/MeasurementTables';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import dynamic from 'next/dynamic';
import { getSizeGuide } from '/services/size-guides';

import { Tab, Tabs } from '/components/Tabs';
import { useEffect, useState } from 'react';

const Modal = dynamic(() => import('/components/Modal'));
const ModalBody = dynamic(() => import('/components/Modal').then((mod) => mod.ModalBody));

const SizeGuideContent = ({ sizeGuide }) => {
	return (
		<div className="py-4 bg-white">
			<Tabs>
				<Tab title={<Typography variant="heading-xs">Measurements</Typography>}>
					<MeasurementTables note={sizeGuide.note} tables={sizeGuide.tables} />
				</Tab>
				{sizeGuide.measuringGuide && (
					<Tab title={<Typography variant="heading-xs">Measuring guide</Typography>}>
						<div className="w-full">
							<div className="grid grid-cols-2 gap-2">
								<ol className="flex flex-col justify-center list-decimal inside marker:text-primary marker:font-bold pl-6 w-full md:px-20">
									{sizeGuide.measuringGuide.details &&
										sizeGuide.measuringGuide.details.map((detail, i) => {
											return (
												<li key={i}>
													<Typography className="py-2" component="p" variant="body">
														{detail}
													</Typography>
												</li>
											);
										})}
								</ol>
								{sizeGuide.measuringGuide.image && (
									<div>
										<Image
											alt="measuring guide"
											height={sizeGuide.measuringGuide.image.height}
											src={sizeGuide.measuringGuide.image.src}
											width={sizeGuide.measuringGuide.image.width}
										/>
									</div>
								)}
							</div>
						</div>
					</Tab>
				)}
			</Tabs>
		</div>
	);
};

export default function SizeGuide({
	styleNumber,
	white = false,
	dropShadow = false,
	modal = true,
}) {
	const [modalOpen, setModalOpen] = useState(false);
	const [sizeGuide, setSizeGuide] = useState();

	useEffect(() => {
		const fetchSizeGuide = async () => {
			const sizeGuideResponse = await getSizeGuide(styleNumber);

			if (sizeGuideResponse && !sizeGuideResponse.hasError) {
				setSizeGuide(sizeGuideResponse);
			}
		};

		fetchSizeGuide();
	}, [styleNumber]);

	if (!sizeGuide?.tables || sizeGuide.tables.length == 0) {
		return null;
	}

	const fontColor = white ? '!text-white' : 'text-secondary hover:text-secondary-darker';
	if (!modal) {
		return <SizeGuideContent sizeGuide={sizeGuide} />;
	}
	return (
		<>
			<Button
				variant="link"
				title="View Size Guide"
				className={`${fontColor} capitalize underline underline-offset-2`}
				onClick={() => setModalOpen(!modalOpen)}>
				Size Guide
			</Button>
			<Modal
				isOpen={modalOpen}
				id="sizeGuide"
				toggle={(val) => setModalOpen(val)}
				dropShadow={dropShadow}>
				<ModalBody className="md:max-h-[70vh] overflow-x-auto rounded-sm">
					<button
						type="button"
						className="absolute top-0 right-0 p-4"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => setModalOpen(!modalOpen)}>
						<Icon name="close" height="18px" width="18px" />
					</button>
					<SizeGuideContent sizeGuide={sizeGuide} />
				</ModalBody>
			</Modal>
		</>
	);
}

SizeGuide.propTypes = {
	className: PropTypes.string,
	dropShadow: PropTypes.bool,
	modal: PropTypes.bool,
	styleNumber: PropTypes.string,
	white: PropTypes.bool,
};

SizeGuideContent.propTypes = {
	sizeGuide: PropTypes.object,
};
