const MAX_RETRIES = 5;
const RETRY_DELAY = 1000;
const INITIAL_DELAY = 3000;

let isAxonReady = false;
let eventQueue = [];
let retryCount = 0;

const checkAxonReady = () => {
	if (typeof window !== 'undefined' && window.axon) {
		try {
			if (window.performance) {
				isAxonReady = true;
				processQueue();
			} else {
				throw new Error('axon not loaded');
			}
		} catch (error) {
			handleAxonError(error);
		}
	} else if (retryCount < MAX_RETRIES) {
		retryCount++;
		setTimeout(checkAxonReady, RETRY_DELAY);
	} else {
		isAxonReady = true;
		processQueue();
	}
};

const handleAxonError = (error) => {
	if (error.message === 'axon not loaded') {
		if (retryCount < MAX_RETRIES) {
			retryCount++;
			setTimeout(checkAxonReady, RETRY_DELAY);
		} else {
			isAxonReady = true;
			processQueue();
		}
	}
};

const processQueue = () => {
	while (eventQueue.length > 0) {
		const event = eventQueue.shift();
		sendEventImmediately(event.eventName, event.eventBody);
	}
};

const sendEventImmediately = (eventName, eventBody) => {
	if (isAxonReady) {
		try {
			return window.axon('track', eventName, eventBody);
		} catch (error) {
			handleAxonError(error);
		}
	}
	return null;
};

const sendEvent = (eventName, eventBody) => {
	if (isAxonReady) {
		return sendEventImmediately(eventName, eventBody);
	} else {
		eventQueue.push({ eventName, eventBody });
		if (retryCount === 0) {
			setTimeout(checkAxonReady, INITIAL_DELAY);
		}
		return null;
	}
};

setTimeout(checkAxonReady, INITIAL_DELAY);

export default sendEvent;
