import PropTypes from 'prop-types';
import Typography from '/components/Typography';

// TODO: fill in the rest of the possible values

export default function ProductType({ productType }) {
	const PRODUCT_TYPE_MAP = {
		Boxers: 'No Fly',
		'Boxers - Fly': 'With Fly',
		'Boxers - Long - Fly': 'Long',
		'Boxers - Cooling - Fly': 'Cooling',
		'Thongs - Cooling': 'Cooling',
		'Boxers - Trunk': 'Trunks',
		'Boxers - Brief': 'Briefs',
		'Bralettes - Cooling': 'Cooling',
		Bralettes: 'Bralettes',
		Bikini: 'Bikini',
		Thongs: 'Thong',
		Boyshort: 'Boyshort',
		Cheeky: 'Cheeky',
	};

	if (!productType || !PRODUCT_TYPE_MAP[productType]) {
		return null;
	}

	if (productType.includes('Cooling')) {
		return (
			<div className="rounded-full bg-gradient-to-r from-cyan-500 to-blue-500 my-2 h-7 px-2 w-max">
				<Typography className="text-white text-center" variant="small">
					Cooling
				</Typography>
			</div>
		);
	}
	return (
		<div className="rounded-full bg-primary my-2 h-7 px-2 w-max">
			<Typography className="text-white text-center" variant="small">
				{PRODUCT_TYPE_MAP[productType] || productType}
			</Typography>
		</div>
	);
}
ProductType.propTypes = {
	productType: PropTypes.string,
};
