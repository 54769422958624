import { CURRENCY } from './constants';
import { formatProducts } from './formatters';
import sendEvent from './send-event';

const trackAddToCart = (products, checkoutUrl) => {
	const formattedData = formatProducts(products);

	sendEvent('AddToCart', {
		checkoutUrl,
		contents: formattedData.contents,
		currency: CURRENCY,
		value: formattedData.totalValue,
	});
};

export default trackAddToCart;
