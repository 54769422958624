import Carousel from '../../../../Carousel';
import Product from './product-carousel/Product';
import { getProductsShopify } from '/services/products';
import { getUpsellDiscount } from '../../../../../utils/cart-processor';
import { logTryCatch } from '../../../../../utils/logging';

import { useEffect, useState } from 'react';

const ProductCarousel = () => {
	const [products, setProducts] = useState([]);

	useEffect(() => {
		async function fetchProducts() {
			let products = await getProductsShopify('minicart-upsell-temp');

			products = products.hits.map((product) => {
				const upsellDiscount = getUpsellDiscount(product.vendor);
				if (!product.tags.includes('mystery')) {
					product.upsellPrice = product.prices[0] * upsellDiscount;
				}
				return product;
			});

			setProducts(products);
		}

		fetchProducts().catch((e) => logTryCatch(e));
	}, []);

	return (
		<div className="w-[364px]" onClick={(e) => e.stopPropagation() && false}>
			<Carousel dots={false} slidesToShow={1} infinite={true}>
				{products.map((p, i) => (
					<Product key={i} product={p} />
				))}
			</Carousel>
		</div>
	);
};

export default ProductCarousel;
