const storageAvailable = (type) => {
	if (typeof window !== 'undefined') {
		let storage;
		try {
			storage = window[type];
			const x = '__storage_test__';
			storage.setItem(x, x);
			storage.removeItem(x);
			return true;
		} catch (e) {
			return (
				e instanceof DOMException &&
				e.name === 'QuotaExceededError' &&
				// acknowledge QuotaExceededError only if there's something already stored
				storage &&
				storage.length !== 0
			);
		}
	}

	return false;
};

// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#feature-detecting_localstorage
const readLocalStorage = (key) => {
	if (storageAvailable('localStorage')) {
		return window?.localStorage.getItem(key);
	}

	if (storageAvailable('sessionStorage')) {
		return window?.sessionStorage.getItem(key);
	}
};

const removeLocalStorageValue = (key) => {
	if (storageAvailable('localStorage')) {
		return window?.localStorage.removeItem(key);
	}

	if (storageAvailable('sessionStorage')) {
		return window?.sessionStorage.removeItem(key);
	}
};

const writeLocalStorage = (key, value) => {
	if (storageAvailable('localStorage')) {
		return window?.localStorage.setItem(key, value);
	}

	if (storageAvailable('sessionStorage')) {
		return window?.sessionStorage.setItem(key, value);
	}
};

module.exports = {
	readLocalStorage,
	removeLocalStorageValue,
	writeLocalStorage,
};
