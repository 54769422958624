import Clickable from '/components/Clickable';
import Image from '/components/Image';
import PromotionModal from './PromotionModal';
import { observer } from 'mobx-react-lite';
import { useGlobalStore } from '/state/global-context';

import { readLocalStorage, writeLocalStorage } from '/utils/local-storage';
import { useEffect, useState } from 'react';

const MultiProductPopup = () => {
	const globalStore = useGlobalStore();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const currentPromotion = globalStore.getPromotionalConfig();

	useEffect(() => {
		if (window) {
			const localStorageValue = readLocalStorage(currentPromotion.dateEnd);

			if (!localStorageValue) {
				setIsModalOpen(true);
			}

			writeLocalStorage(currentPromotion.dateEnd, true);
		}
	}, []);

	if (!currentPromotion) {
		return null;
	}

	if (
		!currentPromotion.sitewidePopup ||
		!currentPromotion.sitewidePopup.imageSrcDesktop ||
		!currentPromotion.sitewidePopup.imageSrcMobile
	) {
		return null;
	}

	return (
		<>
			<PromotionModal
				imageSrcDesktop={currentPromotion.sitewidePopup.imageSrcDesktop}
				imageSrcMobile={currentPromotion.sitewidePopup.imageSrcMobile}
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
				termsLink={currentPromotion.termsHref}
			/>

			{currentPromotion?.popupTrigger?.imageSrc && (
				<Clickable
					className="bottom-[20px] h-24 fixed right-2 w-24 md:w-30 md:h-30 z-20"
					heapEventName="Promotion Modal Open"
					heapEventData={{
						page: 'home',
					}}
					onClick={() => setIsModalOpen(true)}>
					<Image
						alt="underwear hero"
						layout="fill"
						objectFit="cover"
						objectPosition="left"
						src={currentPromotion?.popupTrigger?.imageSrc}
					/>
				</Clickable>
			)}
		</>
	);
};

export default observer(MultiProductPopup);
