import { formatProducts } from './formatters';
import sendEvent from './send-event';
import { v4 as uuidv4 } from 'uuid';
import { CONTENT_TYPE, CURRENCY } from './constants';

const trackViewItem = (products) => {
	const formatted = formatProducts(products);

	if (!products || products.length === 0) {
		return;
	}

	const formattedData = {
		contents: formatted.contents,
		content_type: CONTENT_TYPE,
		currency: CURRENCY,
		eventId: uuidv4(),
		value: formatted.total,
	};

	sendEvent('ViewContent', formattedData);
};

export default trackViewItem;
