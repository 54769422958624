import { CURRENCY } from './constants';
import { formatProducts } from './formatters';
import sendEvent from './send-event';

const trackViewItem = (payload, estimatedValue) => {
	const formattedData = formatProducts(payload);

	sendEvent('ViewContent', {
		contents: formattedData.contents,
		currency: CURRENCY,
		value: estimatedValue,
	});
};

export default trackViewItem;
