import { formatVariantSelect } from './formatters';
import sendEvent from './send-event';
import { updateWunderkindVariantTracking } from '/services/analytics/wunderkind';

const trackVariantSelect = (productId, variantId) => {
	const formatted = formatVariantSelect(productId, variantId);
	updateWunderkindVariantTracking(variantId);
	sendEvent('variantSelect', formatted);
};

export default trackVariantSelect;
