import identifyUser from './identify-user';
import trackAddToCart from './track-add-to-cart';
import trackInitiateCheckout from './track-initiate-checkout';
import trackPageView from './track-page-view';
import trackViewItem from './track-view-item';

module.exports = {
	identifyUser,
	trackAddToCart,
	trackInitiateCheckout,
	trackPageView,
	trackViewItem,
};
