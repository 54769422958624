import DeviceDetector from 'device-detector-js';
export const getDeviceType = () => {
	if (typeof window === 'undefined') {
		return;
	}

	const deviceDetector = new DeviceDetector();
	const parsedDevice = deviceDetector.parse(window.navigator.userAgent);

	let deviceType = 'desktop';
	if (window?.navigator?.userAgentData?.mobile) {
		deviceType = window?.navigator?.userAgentData?.mobile ? 'mobile' : 'desktop';
	} else if (parsedDevice?.os?.name == 'iOS') {
		deviceType = 'mobile';
	} else if (parsedDevice?.device?.type == 'smartphone') {
		deviceType = 'mobile';
	}
	return deviceType;
};
