import PropTypes from 'prop-types';

const reactChildren = PropTypes.oneOfType([
	PropTypes.element,
	PropTypes.string,
	PropTypes.array,
	PropTypes.number,
]);

module.exports = {
	reactChildren,
};
