// @ts-ignore
import { BUILD_A_PACK_DATA } from '@/services/static/packs/build-a-pack';

import {
	BuilderConfig,
	DiscountConfig,
	ExtraItemsPricing,
	PackPriceResponse,
	ProductInPack,
} from '@/services/static/packs/types';

import { formatMonetaryValue, formatPercentageValue } from '@/utils/format';

const INCLUDE_AUDIT_LOG = false;

const DISCOUNTS: { [key: string]: DiscountConfig } = {
	default: {
		discountType: 'sliding',
		discounts: [1, 0.95, 0.9, 0.85, 0.8, 0.75, 0.73, 0.7, 0.67],
	},
	socks: {
		discountType: 'fixed-price',
		priceFixed: 9.99,
		priceRetail: 14.99,
	},
};

const checkNumber = (n?: number) => {
	return typeof n === 'number' && (n === 0 || Number.isFinite(n));
};

const getDiscounts = (builderType?: string): DiscountConfig => {
	if (!builderType) {
		return DISCOUNTS.default;
	}

	// TODO - USE PRODUCT TYPES CONSTANT
	return builderType === 'socks' ? DISCOUNTS.socks : DISCOUNTS.default;
};

export const getEmptySlots = (builderType: string, packLength: number, maxSlots?: number) => {
	if (maxSlots && packLength >= maxSlots) {
		return [];
	}

	const discountConfig = getDiscounts(builderType);

	// always show enough slots to make a three pack
	const numberOfSlots: number = Math.max(3 - packLength, 1);

	const slots = [];

	for (let i = 0; i < numberOfSlots; i++) {
		let label;

		if (discountConfig?.discountType === 'sliding' && discountConfig.discounts) {
			const discount = getDiscountByIndex(discountConfig.discounts, packLength + i);

			label =
				discount === 1 ? 'Get Started' : `Save ${formatPercentageValue(1 - discount, false, true)}`;
		}

		if (discountConfig?.discountType === 'fixed-price' && discountConfig.priceFixed) {
			label = `${formatMonetaryValue(discountConfig.priceFixed)}`;
		}

		slots.push({
			label,
		});
	}

	return slots;
};

export const getDiscountByIndex = (discounts: number[], index: number): number => {
	const discountsCount = discounts.length - 1;
	return index > discountsCount ? discounts[discountsCount] : discounts[index];
};

export const getPackDiscount = (
	builderType?: string,
	quantity?: number,
	priceRetail?: number,
): PackPriceResponse => {
	// console.log({ builderType, quantity, priceRetail });

	if (!builderType || builderType === '') {
		return {
			error: 'no builder type provided',
			success: false,
		};
	}

	if (!quantity) {
		return {
			error: 'no quantity provided',
			success: false,
		};
	}

	let discountConfig = getDiscounts(builderType);

	if (typeof priceRetail === 'undefined') {
		if (discountConfig?.priceRetail) {
			priceRetail = discountConfig.priceRetail;
		} else {
			// since all we care about is the % discount in this function, the retail price per pair doesn't matter
			priceRetail = 100;
		}
	}

	let totalRetail: number = priceRetail * quantity;
	let totalPack: number = 0;

	if (discountConfig.discountType === 'sliding' && discountConfig.discounts) {
		for (let i = 0; i < quantity; i++) {
			const discountCurrent = getDiscountByIndex(discountConfig.discounts, i);
			totalPack += priceRetail * discountCurrent;
		}
	}

	// an example here is socks -- priceFixed is 9.99, but we need to give the user the 8.99 (sale) price.
	if (discountConfig.discountType === 'fixed-price' && discountConfig.priceFixed) {
		const price = discountConfig.priceFixed < priceRetail ? discountConfig.priceFixed : priceRetail;
		totalPack = quantity * price;
	}

	if (!checkNumber(totalRetail) || !checkNumber(totalPack) || totalPack === 0) {
		return {
			error: 'could not calculate total retail',
			success: false,
		};
	}

	const discountPercentInverse = totalPack / totalRetail;

	return {
		discountPercent: 1 - discountPercentInverse,
		discountPercentInverse,
		success: true,
	};
};

export const getPackPrice = (settings: {
	builderConfig: BuilderConfig;
	builderId?: string;
	builderType?: 'socks' | 'underwear';
	selectedBox?: ProductInPack;
	selectedExtras?: ProductInPack[];
	selectedProducts?: ProductInPack[];
}): PackPriceResponse => {
	let auditLog: unknown[] = [];

	// TODO: Once the configs are API driven, we'll just pass the ID here and have an endpoint to get all the configs on site load.
	let { builderConfig, builderId, builderType, selectedBox, selectedExtras, selectedProducts } =
		settings;

	if (!builderConfig && builderId) {
		builderConfig = BUILD_A_PACK_DATA[settings.builderId];
	}

	if (!builderConfig) {
		builderConfig = {
			builderType,
			id: 'unknown',
		};
	}

	if (!builderType) {
		return {
			auditLog,
			error: 'no builder type provided',
			builderType: settings.builderType,
			success: false,
		};
	}

	if (!selectedProducts || !Array.isArray(selectedProducts) || selectedProducts.length === 0) {
		return {
			auditLog,
			error: 'no products provided',
			success: false,
		};
	}

	const selectedProductsQuantity = selectedProducts.length;
	let priceDiscountBox = 0;
	let priceDiscountExtras = 0;
	let priceRetailBox = 0;
	let priceRetailExtras = 0;
	let extraItemsPricing = {} as ExtraItemsPricing;

	let priceRetailProducts = selectedProducts.reduce((memo, product) => {
		if (!product?.prices?.[0]) {
			return memo;
		}

		if (INCLUDE_AUDIT_LOG) {
			auditLog.push({
				message: 'RETAIL PRODUCTS - Found Retail Product',
				productTitle: product.title,
				price: product.prices[0],
			});
		}

		const price = Number(product.prices[0]);
		memo += price;

		return memo;
	}, 0);

	if (selectedBox?.prices?.[0]) {
		priceDiscountBox = Number(selectedBox.prices[0]);
		priceRetailBox = Number(selectedBox.prices[0]);

		if (INCLUDE_AUDIT_LOG) {
			auditLog.push({
				message: 'BOX - Found Box',
				productTitle: selectedBox.title,
				price: selectedBox.prices[0],
			});
		}
	}

	if (selectedExtras && selectedExtras.length > 0) {
		extraItemsPricing = selectedExtras.reduce(
			(memo, extraItem) => {
				if (!extraItem?.product?.prices?.[0]) {
					return memo;
				}

				const extraItemPriceRetail = Number(extraItem.product?.prices[0]) || 0;
				memo.retail += extraItemPriceRetail;

				if (INCLUDE_AUDIT_LOG) {
					auditLog.push({
						message: 'EXTRA ITEMS PRICING - Found Extra Item',
						productTitle: extraItem.product?.title,
						price: extraItem.product?.prices[0],
					});
				}

				if (extraItem?.product?.handle) {
					const foundForcedPrice = builderConfig?.extraItems?.find((item) => {
						if (!item?.handle) {
							return false;
						}

						return item.handle === extraItem.product?.handle;
					});

					if (foundForcedPrice?.priceFixed) {
						if (INCLUDE_AUDIT_LOG) {
							auditLog.push({
								message: 'EXTRA ITEMS PRICING - Found Extra Item Forced Price',
								productTitle: extraItem.product?.title,
								price: foundForcedPrice.priceFixed,
							});
						}

						memo.lookup[extraItem.product.handle] = foundForcedPrice.priceFixed;

						if (extraItemPriceRetail) {
							if (extraItemPriceRetail < foundForcedPrice) {
								memo.discount += extraItemPriceRetail;
							} else {
								memo.discount += foundForcedPrice.priceFixed;
							}
						} else {
							memo.discount += foundForcedPrice.priceFixed;
						}
					}
				}

				return memo;
			},
			{ discount: 0, lookup: {}, retail: 0 } as ExtraItemsPricing,
		);

		priceDiscountExtras = extraItemsPricing.discount;
		priceRetailExtras = extraItemsPricing.retail;
	}

	const priceRetailTotal = priceRetailProducts + priceRetailBox + priceRetailExtras;

	if (INCLUDE_AUDIT_LOG) {
		auditLog.push({
			message: 'Calculated Total',
			price: priceRetailTotal,
		});
	}

	if (priceRetailTotal === 0) {
		return {
			auditLog,
			error: 'could not calculate total retail',
			success: false,
		};
	}

	const discounts = getPackDiscount(builderType, selectedProductsQuantity);

	if (INCLUDE_AUDIT_LOG) {
		auditLog.push({
			message: 'Found Discounts',
			price: discounts,
		});
	}

	if (!discounts.success || !discounts.discountPercentInverse) {
		return discounts;
	}

	const priceDiscountProducts = priceRetailProducts * discounts.discountPercentInverse;
	const priceDiscountTotal = priceDiscountProducts + priceDiscountExtras + priceRetailBox;

	return {
		auditLog,
		discountDollars: priceRetailTotal - priceDiscountTotal,
		discountPercent: discounts.discountPercent,
		discountPercentInverse: discounts.discountPercentInverse,
		extraItemsPricing,
		priceDiscountPerPair: priceDiscountProducts / selectedProductsQuantity,
		priceDiscountBox,
		priceDiscountExtras,
		priceDiscountProducts,
		priceDiscountTotal,
		priceRetailBox,
		priceRetailExtras,
		priceRetailProducts,
		priceRetailTotal,
		success: true,
	};
};
