import { logEvent } from '../helpers/logging';
import { CONSOLE_BACKGROUND, ENABLE_LOGGING } from './constants';

const sendEvent = (eventName, payload) => {
	if (!window) {
		return;
	}

	if (!window.wunderkindEvents) {
		window.wunderkindEvents = [];
	}

	window.wunderkindEvents.push({
		data: payload,
		eventName,
	});

	logEvent('Wunderkind', CONSOLE_BACKGROUND, ENABLE_LOGGING, eventName, payload);
};

export default sendEvent;
