import axios from 'axios';
const getRemoteNavigationConfig = async () => {
	return axios
		.get(`${process.env.NEXT_PUBLIC_SHINE_PUBLIC}/navigation-current`)
		.then((res) => {
			if (res?.status === 200 && res.data) {
				return res.data;
			}
		})
		.catch(() => {
			return {
				menus: [],
			};
		});
};

module.exports = {
	getRemoteNavigationConfig,
};
