import { logTryCatch } from '/utils/logging';

const trackEvent = (eventName, eventData) => {
	// adding a catch here to alleviate heap.push issues in the dom.
	try {
		if (window.heap && window.heap.track) {
			window.heap.track(eventName, eventData);
		}
	} catch (e) {
		logTryCatch('cannot push analytics data.');
	}
};

const addUserProperty = (key, value) => {
	try {
		if (key && value) {
			window.heap.addUserProperties({ [key]: value });
		}
	} catch (e) {
		logTryCatch(e);
	}
};

const identifyUser = (clientId) => {
	try {
		if (window.heap && window.heap.identify) {
			window.heap.identify(clientId);
		}
	} catch (e) {
		logTryCatch('cannot identify user');
	}
};

module.exports = {
	addUserProperty,
	identifyUser,
	trackEvent,
};
