import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import iconMap from '/services/static/icons';

const Icon = forwardRef(({ altText, className = '', height, name, width }, ref) => {
	const iconProps = iconMap[name];

	if (!iconProps) {
		return null;
	}

	className += ' shrink-0';

	return (
		<img
			alt={altText || iconProps.altText}
			className={className.trim()}
			ref={ref}
			style={{ height: height, width: width }}
			src={iconProps.src}
		/>
	);
});

Icon.displayName = 'Icon';

Icon.propTypes = {
	altText: PropTypes.string, // if defined, replaces iconMap[name].altText
	className: PropTypes.string,
	height: PropTypes.string,
	name: PropTypes.string, // name of icon in icon map
	width: PropTypes.string,
};

export default Icon;
