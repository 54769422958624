const GID_REGEXP = /\/(\w[\w-]*)(?:\?(.*))*$/;

const createGid = (resourceType, id, dontEncode) => {
	if (!resourceType || !id) {
		return;
	}

	const unencoded = `gid://shopify/${resourceType}/${id}`;
	return dontEncode ? unencoded : encodeGid(unencoded);
};

const encodeGid = (unencoded) => {
	const encoded = Buffer.from(unencoded).toString('base64');
	return encoded;
};

const decodeGid = (encoded) => {
	if (!encoded?.includes('=')) {
		return encoded;
	}

	const decoded = Buffer.from(encoded, 'base64').toString();
	return decoded;
};

const parseGid = (gid) => {
	if (!gid || typeof gid !== 'string') {
		return gid;
	}

	// the gid is already decoded, don't decode it again.
	if (gid.indexOf('gid') !== 0) {
		gid = Buffer.from(gid, 'base64');
	}

	// prepends forward slash to help identify invalid id
	const id = `/${gid}`;
	const matches = GID_REGEXP.exec(id);

	if (matches && matches[1] !== undefined) {
		return matches[1];
	}

	return null;
};

module.exports = {
	createGid,
	decodeGid,
	encodeGid,
	parseGid,
};
