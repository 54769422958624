const ENABLE_LOGGING = false;

const logTryCatch = (message) => {
	if (ENABLE_LOGGING) {
		console.log(message);
	}
};

module.exports = {
	logTryCatch,
};
