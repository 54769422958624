import Clickable from '../../../../Clickable';
import PropTypes from 'prop-types';
import Typography from '../../../../Typography';

import classNames from '../../../../../utils/class-names';
import { getItemHref } from '../../../utils';

const LinkPlain = ({
	className,
	item,
	menuStyle = 'default',
	navGroup = 'unknown',
	tier = 'unknown',
	typographyVariant = 'link-red',
}) => {
	let href = getItemHref(item);

	if (!href || !item.title) {
		return null;
	}

	return (
		<Clickable
			linkToInternal={href}
			heapEventName="Navigation Link Click"
			heapEventData={{
				Href: href,
				Position: `Tier ${tier}`,
				'Nav Group': navGroup,
			}}>
			<Typography
				className={classNames(
					'text-sm',
					menuStyle === 'default' && 'mb-2',
					item?.style === 'cooling' && 'text-secondary-darker font-semibold',
					item?.style === 'primary' && 'text-primary font-semibold',
					className,
				)}
				component="div"
				variant={typographyVariant}>
				{item.title}
			</Typography>
		</Clickable>
	);
};

LinkPlain.propTypes = {
	className: PropTypes.string,
	item: PropTypes.object,
	menuStyle: PropTypes.string,
	navGroup: PropTypes.string,
	tier: PropTypes.string,
	typographyVariant: PropTypes.string,
};

export default LinkPlain;
