import { CURRENCY } from './constants';
// import { formatProducts } from './formatters';
import sendEvent from './send-event';

const trackSearch = (products, searchTerm) => {
	// const formattedData = formatProducts(payload.ecommerce?.detail?.products);

	sendEvent('Search', {
		// TODO: BLOTOUT -- is this the results of the search?
		// contents: formattedData.contents,
		currency: CURRENCY,
		search: searchTerm,
		// TODO: BLOTOUT -- what is a value of a search?
		// value: 10.50,
	});
};

export default trackSearch;
