import { gql } from '@apollo/client';

const GET_PRODUCTS_METADATA = gql`
	query getProducts($productHandle: String!) {
		productByHandle(handle: $productHandle) {
			id
			buttonImg: metafield(namespace: "product", key: "pack_img") {
				value
				type
			}
			handle
			images(first: 10) {
				edges {
					node {
						src
						altText
						width
						height
					}
				}
			}
			title
			priceRange {
				minVariantPrice {
					amount
				}
				maxVariantPrice {
					amount
				}
			}
			variants(first: 100) {
				edges {
					node {
						availableForSale
						id
						image {
							src
						}
						selectedOptions {
							name
							value
						}
						dcInventory: metafield(namespace: "denver_inventory", key: "quantity") {
							value
						}
						sku
						quantityAvailable
						currentlyNotInStock
					}
				}
			}
		}
	}
`;

export default GET_PRODUCTS_METADATA;
