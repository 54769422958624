const LOGGING_OVERRIDE = true;

const checkConsole = (enableLogging) => {
	return LOGGING_OVERRIDE && enableLogging && window?.console?.log && window.console.groupCollapsed;
};

const checkObject = (obj) => {
	return typeof obj === 'object' && !Array.isArray(obj) && obj !== null;
};

const logEvent = (channelName, color, enableLogging, eventName, data, extras) => {
	const canLog = checkConsole(enableLogging);
	if (!canLog) {
		return;
	}

	const consoleLabel = `%c---------- ${channelName} Event ${eventName} ----------`;
	console.groupCollapsed(consoleLabel, `background: ${color}; padding: 8px;`);

	if (!data && !extras) {
		console.log('Event was successfully fired, but there is no data for this event.');
	}

	if (data && window?.console?.table) {
		const dataIsObject = checkObject(data);
		if (dataIsObject === true) {
			const tableData = Object.keys(data).reduce((memo, key) => {
				let value = data[key];

				const valueIsObject = checkObject();
				if (valueIsObject === true) {
					value = JSON.stringify(data[key], null, 4);
				}

				const valueIsArray = Array.isArray(data[key]);
				if (valueIsArray === true) {
					value = data[key].join(', ');
				}

				memo.push({ key, value });
				return memo;
			}, []);

			console.table(tableData, ['key', 'value']);
			console.log(data);
		} else {
			console.log(data);
		}
	}
	if (extras) {
		console.log(extras);
	}

	console.groupEnd(consoleLabel);
};

const logMessage = (channelName, color, enableLogging, eventName, message) => {
	const canLog = checkConsole(enableLogging);
	if (!canLog) {
		return;
	}

	const consoleLabel = `%c---------- ${channelName} Event ${eventName} ----------`;
	console.groupCollapsed(consoleLabel, `background: ${color}; padding: 8px;`);
	console.log(message);
	console.groupEnd(consoleLabel);
};

module.exports = {
	logEvent,
	logMessage,
};
