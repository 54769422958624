import PropTypes from 'prop-types';
import React from 'react';

import { reactChildren } from '/utils/prop-types';

const UtmContext = React.createContext(null);

export const UtmProvider = ({ value, children }) => {
	return <UtmContext.Provider value={value}>{children}</UtmContext.Provider>;
};

export const useUtmStore = () => React.useContext(UtmContext);

UtmProvider.propTypes = {
	children: reactChildren,
	value: PropTypes.object,
};
