import { formatViewItem } from './formatters';
import sendEvent from './send-event';
import { updateWunderkindProductTracking } from '/services/analytics/wunderkind';

const trackViewItem = (products, isSubscription, estimatedValue) => {
	const formatted = formatViewItem(products, isSubscription, estimatedValue);

	if (formatted && formatted?.length > 0) {
		updateWunderkindProductTracking(formatted[0]);
	}

	sendEvent('productView', formatted);
};

export default trackViewItem;
