const trackAddToCart = () => {
	try {
		const aid = 4;
		const adv_event_id = 12;
		const eftid = window.EF.getAdvertiserTransactionId(aid);
		const data = {
			aid: aid,
			adv_event_id: adv_event_id,
			transaction_id: eftid,
			adv1: 'Added to Cart Pixel',
		};
		window.EF.conversion(data).then();
	} catch (e) {
		console.log('everflow event failure');
	}
};

export default trackAddToCart;
