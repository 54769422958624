import { DEFAULT_INDEX } from '/services/algolia/constants';
import aa from 'search-insights';
import { getShinestyClientId } from '/services/analytics/shinesty';

let storedQueryId;

const init = () => {
	aa('init', {
		appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
		apiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
		userHasOptedOut: false,
		useCookie: false,
	});

	const userId = getShinestyClientId();

	aa('setUserToken', userId);
};

const setQueryId = (queryId) => {
	storedQueryId = queryId;
};

const trackAddToCart = (products) => {
	const objectIds = products.map((product) => product.shopifyProductId);
	const foundQueryId = getQueryId();
	if (foundQueryId) {
		aa('convertedObjectIDsAfterSearch', {
			userToken: getShinestyClientId(),
			index: DEFAULT_INDEX,
			eventName: 'Add To Cart',
			objectIDs: objectIds,
			queryID: getQueryId(),
		});
	} else {
		aa('convertedObjectIDs', {
			userToken: getShinestyClientId(),
			index: DEFAULT_INDEX,
			eventName: 'Add To Cart',
			objectIDs: objectIds,
		});
	}
};

const trackProductClick = (product, rank) => {
	const analyticsObject = {
		userToken: getShinestyClientId(),
		index: DEFAULT_INDEX,
		eventName: 'Product Clicked',
		objectIDs: [product.shopifyProductId],
	};

	const queryId = getQueryId();
	if (queryId && queryId != 'undefined') {
		const position = rank ? rank + 1 : 1;
		analyticsObject['queryID'] = queryId;
		analyticsObject['positions'] = [position];
	}

	aa('clickedObjectIDsAfterSearch', analyticsObject);
};

const getQueryId = () => {
	return storedQueryId;
};

module.exports = {
	getQueryId,
	init,
	setQueryId,
	trackAddToCart,
	trackProductClick,
};
