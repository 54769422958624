import PropTypes from 'prop-types';
import React from 'react';

import { reactChildren } from '/utils/prop-types';

const GlobalContext = React.createContext(null);

export const GlobalProvider = ({ value, children }) => {
	return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

export const useGlobalStore = () => React.useContext(GlobalContext);

GlobalProvider.propTypes = {
	children: reactChildren,
	value: PropTypes.object,
};
