import { formatViewItemList } from './formatters';
import sendEvent from './send-event';
import { updateWunderkindCollectionTracking } from '/services/analytics/wunderkind';

const trackViewItemList = (payload = {}, collectionHandle) => {
	const formatted = formatViewItemList(payload, collectionHandle);
	updateWunderkindCollectionTracking(collectionHandle, payload);

	sendEvent('viewItemList', formatted);
};

export default trackViewItemList;
