import algoliaClient from '/utils/algolia-client.js';
import { formatProduct as formatProductAlgolia } from '/utils/product-normalizers/algolia';
import { getShinestyClientId } from '/services/analytics/shinesty';
import { DEFAULT_INDEX, FACETS, SORT_OPTIONS } from './constants';

export const getCollectionProducts = async (searchSettings) => {
	const index = SORT_OPTIONS[searchSettings.sortOrder] || DEFAULT_INDEX;
	let pageNum = searchSettings.page || 0;
	const itemsPerPage = searchSettings.pageSize || 20;
	const term = searchSettings.searchTerm;
	const underwearRegex = /\b(hammock|bralettes|boxer|underwear|sale)\b/;
	const contexts = ['storefront'];
	const facetFilters = [];

	if (searchSettings.collectionHandle) {
		facetFilters.push(`collections:${searchSettings.collectionHandle}`);
	}

	facetFilters.push('salesChannels.storefront: true');
	facetFilters.push('inventory.ecommerce > 0');

	if (searchSettings.selectedFacets) {
		facetFilters.push(searchSettings.selectedFacets);
	}

	if (searchSettings.collectionHandle) {
		const isUnderwear = searchSettings.collectionHandle.match(underwearRegex);
		if (isUnderwear) {
			contexts.push('underwearCollection');
		}
	}

	let clientId = searchSettings.clientId;

	if (typeof window !== undefined) {
		clientId = getShinestyClientId();
	}

	const {
		hits,
		nbPages,
		facets = [],
		queryID,
	} = await algoliaClient.initIndex(index).search(term, {
		clickAnalytics: true,
		facetFilters: facetFilters,
		filters: searchSettings.filters,
		hitsPerPage: itemsPerPage,
		page: pageNum,
		query: term || '',
		ruleContexts: contexts,
		userToken: clientId,
		facets: Object.keys(FACETS),
	});

	const formatted = [];

	hits.map((hit) => {
		if (hit) {
			formatted.push(formatProductAlgolia(hit));
		}
	});

	return { facets, hits: formatted, nbPages, queryID };
};
