import { CONSOLE_BACKGROUND, ENABLE_LOGGING } from './constants';
import { logEvent, logMessage } from '../helpers/logging';

const sendIdentify = (clientId) => {
	if (!window || !window.tatari || !window.tatari.identify) {
		logMessage(
			'Tatari',
			CONSOLE_BACKGROUND,
			ENABLE_LOGGING,
			'identify',
			'no window.tatari.identify function found',
		);

		return;
	}

	window.tatari.identify(clientId);
	logEvent('Tatari', CONSOLE_BACKGROUND, ENABLE_LOGGING, 'identify', { clientId });
};

export default sendIdentify;
