import LinkPlain from './items/LinkPlain';
import PropTypes from 'prop-types';

const SubNavigationFooter = ({ items }) => {
	if (!items || items.length === 0) {
		return null;
	}

	return (
		<div className="border-t flex my-2 py-10 gap-12">
			{items.map((item, itemIndex) => {
				return <LinkPlain item={item} key={itemIndex} typographyVariant="link-header" />;
			})}
		</div>
	);
};

SubNavigationFooter.propTypes = {
	items: PropTypes.array,
};

export default SubNavigationFooter;
