const footerLinks = [
	{
		link: '/collections/guys-boxers',
		title: 'Boxer',
	},
	{
		link: '/collections/mens-long-leg-boxer-briefs',
		title: 'Long Leg',
	},
	{
		link: '/collections/patterned-boxer-briefs',
		title: 'W/Fly',
	},
	{
		link: '/collections/ball-hammock-briefs',
		title: 'Brief',
	},
	{
		link: '/collections/valentines-day-underwear',
		title: 'Valentines Day',
	},
	{
		link: '/collections/mens-naughty-underwear',
		title: 'X-Rated',
	},
	{
		link: '/collections/ball-hammock-trunk-underwear',
		title: 'Trunk',
	},
	{
		title: 'Derby',
		link: '/collections/mens-derby-clothes',
	},
	{
		title: 'Swim',
		link: '/collections/mens-swim-collection',
	},
	{
		link: '/collections/derby-ties',
		title: 'Bow Ties & Ties',
	},
	{
		link: '/collections/mardi-gras-party-outfits',
		title: 'Mardis Gras',
	},
	{
		link: '/collections/mens-mardi-gras-outfits',
		title: 'Mardis Gras Outfits',
	},
	{
		link: '/collections/80s-and-90s-clothing',
		title: 'Retro',
	},
	{
		link: '/collections/ski-base-layers',
		title: 'Base Layers',
	},
	{
		link: '/collections/vintage-ski-clothing',
		title: 'Ski',
	},
	{
		link: '/collections/st-patricks-day-clothing',
		title: 'St Patricks',
	},
	{
		link: '/collections/ugly-christmas-sweater-suits',
		title: 'Xmas Suits',
	},
	{
		link: '/collections/usa-formal',
		title: 'USA Suits',
	},
	{
		link: '/collections/womens-swimwear',
		title: "Women's Swim",
	},
];

module.exports = {
	footerLinks,
};
