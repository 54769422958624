import Image from 'next/image';
import PropTypes from 'prop-types';
import { createUriWithParam } from '/utils/common-utils';
import { getFileExtension } from '/utils/common-utils';
import { getNextImageSizes } from '/utils/image-sizes';

const CLOUDFRONT_BASE_URL = 'https://cdn.shinesty.com';
const SHOPIFY_BASE_URL = 'https://cdn.shopify.com';

const checkBaseUrl = (baseUrl, src) => {
	return src.includes(baseUrl);
};

const imageLoader = ({ src, width, quality }) => {
	const isCloudfrontHosted = checkBaseUrl(CLOUDFRONT_BASE_URL, src);
	const isShopifyHosted = checkBaseUrl(SHOPIFY_BASE_URL, src);

	if (isCloudfrontHosted) {
		return createUriWithParam(src, { size: `${width}w` });
	}

	if (isShopifyHosted) {
		return createUriWithParam(src, { width: width, quality: quality || 75 });
	}

	return src;
};

const ShinestyImage = ({ blurPreload = false, className, ...rest }) => {
	let { alt, src, ...nextImageProps } = rest;

	if (!src) {
		return null;
	}

	if (!alt) {
		alt = 'Shinesty Image';
	}

	if (!src) {
		return null;
	}

	if (nextImageProps.sizes) {
		nextImageProps.sizes = getNextImageSizes(nextImageProps.sizes);
	}

	// short circuit svgs or non cdn/shopify hosted images to just use the next image component without a loader
	const fileExtension = getFileExtension(src);
	const isCloudfrontHosted = checkBaseUrl(CLOUDFRONT_BASE_URL, src);
	const isShopifyHosted = checkBaseUrl(SHOPIFY_BASE_URL, src);

	if (fileExtension.toLowerCase() === 'svg' || (!isCloudfrontHosted && !isShopifyHosted)) {
		const { height, width } = nextImageProps;
		return <img alt={alt} height={height} src={src} width={width} className={className} />;
	}

	// hopefully we always use blur unless we explicitally don't want to
	if (blurPreload === true) {
		const blurSrc = imageLoader({ src, width: 10 });
		return (
			<Image
				alt={alt}
				blurDataURL={blurSrc}
				className={className}
				loader={imageLoader}
				preload="blur"
				src={src}
				{...nextImageProps}
			/>
		);
	}

	return (
		<Image alt={alt} className={className} loader={imageLoader} src={src} {...nextImageProps} />
	);
};

imageLoader.propTypes = {
	quality: PropTypes.string,
	src: PropTypes.string,
	width: PropTypes.string,
};

ShinestyImage.propTypes = {
	blurPreload: PropTypes.bool,
	className: PropTypes.string,
	extension: PropTypes.string,
	version: PropTypes.string,
};

export default ShinestyImage;
