const mensPricingLookup = {
	79.99: 69.99,
	85.99: 69.99,
	109.99: 99.99,
	139.99: 119.99,
};

const womensPricingLookup = {
	59.99: 49.99,
	89.99: 79.99,
	109.99: 99.99,
};

const packTypes = {
	'bikini - pack': womensPricingLookup,
	'boxer - brief - pack': mensPricingLookup,
	'boxer - cooling - pack': mensPricingLookup,
	'boxer - fly - pack': mensPricingLookup,
	'boxer - long - pack': mensPricingLookup,
	'boxer - pack': mensPricingLookup,
	'boxer - trunk - pack': mensPricingLookup,
	'boyshort - pack': womensPricingLookup,
	'cheeky - pack': womensPricingLookup,
	'thongs - cooling - pack': womensPricingLookup,
	'thongs - modal - pack': womensPricingLookup,
	'thongs - pack': womensPricingLookup,
	"women's boxer - pack": mensPricingLookup,
};

export const getTestPrice = (product, variantKey) => {
	if (!product || !variantKey || variantKey == 'control') {
		return;
	}

	const productType = product?.shopifyProductType || product?.productType?.toLowerCase();
	const typeLookup = packTypes[productType];

	if (!typeLookup) {
		return;
	}
	const currentPrice = product?.pricing?.min || product.prices[0];
	const testPrice = typeLookup[currentPrice];
	return testPrice;
};
