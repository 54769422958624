import MainLayout from '/layouts/MainLayout';
import NickGetsLashed from '/components/NickGetsLashed';
import React from 'react';

import { getShinestyClientId } from '/services/analytics/shinesty';
import { readLocalStorage } from '/utils/local-storage';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);

		// Define a state variable to track whether is an error or not
		this.state = {
			emailBody: '',
			hasError: false,
			rollbarId: undefined,
		};
	}

	async trackRollbarError(err) {
		if (typeof window === 'undefined' || !window?.Rollbar) {
			return;
		}

		const clientId = getShinestyClientId();
		const cartId = readLocalStorage('shinesty:shopify:cartId');

		if (err) {
			const rollbarError = await window.Rollbar?.error(err, {
				applicationError: true,
				cartId: cartId || 'unknown',
				clientId: clientId || 'unknown',
			});

			if (rollbarError?.uuid) {
				this.setState({
					hasError: true,
					rollbarId: rollbarError.uuid,
				});
			}
		} else {
			const rollbarError = await window.Rollbar?.critical("Exception object didn't exist", {
				applicationError: true,
				cartId: cartId || 'unknown',
				clientId: clientId || 'unknown',
			});

			if (rollbarError?.uuid) {
				this.setState({
					hasError: true,
					rollbarId: rollbarError.uuid,
				});
			}
		}
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI
		return { hasError: true };
	}

	componentDidCatch(error) {
		void this.trackRollbarError(error);
	}

	render() {
		// Check if the error is thrown
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<MainLayout>
					<NickGetsLashed title="Error" />
				</MainLayout>
			);
		}

		// Return children components in case of no error

		// eslint-disable-next-line react/prop-types
		return this.props.children;
	}
}

export default ErrorBoundary;
