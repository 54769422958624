import trackAddToCart from './track-add-to-cart';
import trackFilterClick from './track-filter-click';
import trackInitiateCheckout from './track-initiate-checkout';
import trackLead from './track-lead';
import trackPageView from './track-page-view';
import trackProductClick from './track-product-click';
import trackRemoveFromCart from './track-remove-from-cart';
import trackSearch from './track-search';
import trackViewCart from './track-view-cart';
import trackViewItem from './track-view-item';
import trackViewItemList from './track-view-item-list';

module.exports = {
	trackAddToCart,
	trackFilterClick,
	trackInitiateCheckout,
	trackLead,
	trackPageView,
	trackProductClick,
	trackRemoveFromCart,
	trackSearch,
	trackViewCart,
	trackViewItem,
	trackViewItemList,
};
