import PropTypes from 'prop-types';
import { getTestVariant } from '/services/ab-test';

import { useEffect, useState } from 'react';

const AbHandler = ({ control, testId, variations, emptyHeight }) => {
	const [variantKey, setVariantKey] = useState();
	const style = {
		height: emptyHeight,
	};

	useEffect(async () => {
		const variation = await getTestVariant(testId);
		setVariantKey(variation.key);
	}, []);

	if (!variantKey) {
		return <div style={style}></div>;
	}

	return variations[variantKey] || control;
};

AbHandler.propTypes = {
	control: PropTypes.object,
	emptyHeight: PropTypes.string,
	testId: PropTypes.string,
	variations: PropTypes.object,
};
export default AbHandler;
