/**
 * @param {array} tags the product tags
 * @param {string} toCheck the text to look for
 * @returns true if the product is tagged
 */
export const checkProductTags = (tags, toCheck) => {
	const found = tags?.find((tag) => {
		return tag && tag.toLowerCase() === toCheck.toLowerCase();
	});

	return typeof found !== 'undefined';
};

export const findProductTag = (tags, toFind, splitIndex, defaultValue) => {
	if (!tags) {
		return;
	}

	const tag = tags.find((tag) => {
		return tag.includes(toFind);
	});

	if (!tag || typeof splitIndex !== 'number') {
		return defaultValue;
	}

	return splitTag(tag, splitIndex, defaultValue);
};

export const findProductTags = (tags, toFind, splitIndex, defaultValue) => {
	const foundTags = tags.filter((tag) => {
		return tag.includes(toFind);
	});

	if (!foundTags || !splitIndex) {
		return foundTags;
	}

	return splitTags(foundTags, splitIndex, defaultValue);
};

export const findAllProductTags = (tags, toFind, splitIndex, defaultValue) => {
	const filtered = tags.filter((tag) => {
		return tag.includes(toFind);
	});

	if (filtered.length === 0 || !splitIndex) {
		return tags;
	}

	return tags.reduce((memo, tag) => {
		const split = splitTag(tag, splitIndex, defaultValue);
		if (split) {
			memo.push(split);
		}
		return memo;
	});
};

const checkTag = (tag, toCheck) => {
	if (typeof tag === 'string' && typeof toCheck === 'string') {
		return tag.trim().toLowerCase() === toCheck.trim().toLowerCase();
	}

	return false;
};

export const getNamedTagMatch = (product, tag, toCheck) => {
	if (!product?.named_tags || typeof product?.named_tags[tag] === 'undefined') {
		return false;
	}

	// return product.named_tags[tag].includes(content);
	if (typeof product.named_tags[tag] === 'string') {
		return checkTag(product.named_tags[tag], toCheck);
	}

	if (Array.isArray(product.named_tags[tag])) {
		const foundTag = product.named_tags[tag].find((tag) => {
			return checkTag(tag, toCheck);
		});

		return typeof foundTag !== 'undefined';
	}

	return false;
};

const splitTag = (tag, splitIndex, defaultValue) => {
	try {
		const split = tag.split(':');
		return split[splitIndex].trim();
	} catch (e) {
		return defaultValue;
	}
};

const splitTags = (tags, splitIndex, defaultValue) => {
	return tags.map((tag) => {
		try {
			const split = tag.split(':');
			return split[splitIndex].trim();
		} catch (e) {
			return defaultValue;
		}
	});
};
