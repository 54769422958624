import { CURRENCY } from './constants';
import { formatProducts } from './formatters';
import sendEvent from './send-event';

const trackRemoveFromCart = (products) => {
	const formattedData = formatProducts(products);

	sendEvent('RemoveFromCart', {
		contents: formattedData.contents,
		currency: CURRENCY,
	});
};

export default trackRemoveFromCart;
