import { checkProductTags } from '/utils/product-tags';
import { parseGid } from '/utils/graph-ql';

const formatAddToCart = (products) => {
	if (!products) {
		return {};
	}

	return products.reduce((memo, product) => {
		const parsedId = parseGid(product?.shopifyProductId);

		if (parsedId) {
			memo.push(parsedId);
		}

		return memo;
	}, []);
};

const formatBackInStock = (product) => {
	return {
		shopifyProductId: parseGid(product.productId),
	};
};

const formatViewItem = (products, isSubscriptionPage) => {
	return products.map((product) => {
		return {
			shopifyProductId: parseGid(product.shopifyProductId),
			image: product.image,
			isSubscriptionPage: isSubscriptionPage,
			itemCategory: product.productType,
			price: product.price,
			productInStock: product.inStock,
			productIsExcluded: checkProductTags(product.tags, 'wunderkind_ignore'),
			name: product.titlePrimary,
			url: `${process.env.NEXT_PUBLIC_BASE_DOMAIN}/products/${product.handle}`,
		};
	});
};

const formatViewItemList = (collection, collectionHandle) => {
	const productIds = collection?.map((product) => {
		return parseGid(product?.shopifyProductId);
	});

	const formatted = {
		collectionProductIds: productIds,
	};
	if (collectionHandle) {
		formatted[
			'collectionUrl'
		] = `${process.env.NEXT_PUBLIC_BASE_DOMAIN}/collections/${collectionHandle}`;
	}
	return formatted;
};

const formatVariantSelect = (productId, variantId) => {
	return {
		shopifyProductId: parseGid(productId),
		shopifyVariantId: parseGid(variantId),
	};
};

module.exports = {
	formatAddToCart,
	formatBackInStock,
	formatVariantSelect,
	formatViewItem,
	formatViewItemList,
};
