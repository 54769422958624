import { CURRENCY } from './constants';
import { formatProducts } from './formatters';
import sendEvent from './send-event';

const trackViewItemList = (payload) => {
	const formattedData = formatProducts(payload);

	sendEvent('ViewContentList', {
		contents: formattedData.contents,
		currency: CURRENCY,
		pageType: 'category',
	});
};

export default trackViewItemList;
