import Clickable from '/components/Clickable';
import Loading from '/components/Loading';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import { VALID_SITEWIDE_PACK_TYPES } from '/services/static/packs/build-a-pack';
import classNames from '/utils/class-names';
import { createGid } from '../../utils/graph-ql';
import { getProduct } from '/services/products';
import { linesFromVariants } from '/utils/product';
import { updateWunderkindProductTracking } from '/services/analytics/wunderkind';
import { useCartStore } from '/state/cart-context';
import { useGlobalStore } from '/state/global-context';

import { BUILD_A_PACK, PRODUCT_TYPE, SITEWIDE_PACK_BUILDER } from '/services/static/attribute-keys';
import { useEffect, useState } from 'react';

const VariantButton = ({ addToCart, product, variant }) => {
	const [addToCartLoading, setAddToCartLoading] = useState(false);

	const disabled = !variant?.dcInventory?.value || variant?.dcInventory?.value < 1;
	const title = variant.title == 'OSFA' ? '+' : variant.title.replace('US Jacket', '');
	return (
		<>
			{addToCartLoading ? (
				<Loading className="ml-2" height="30px" width="30px" />
			) : (
				<Clickable
					className={classNames(
						'bg-gray-200 border border-gray-200 font-bold min-w-[54px] p-2 rounded shadow-sm',
						disabled ? 'border-gray-200' : 'hover:bg-gray-600 hover:text-white',
					)}
					disabled={disabled || addToCartLoading}
					onClick={() => addToCart(product, variant.id, variant.title, setAddToCartLoading)}>
					{title}
				</Clickable>
			)}
		</>
	);
};

const ProductCardQuickAdd = ({ className = 'bg-white', product }) => {
	const [fullProduct, setProduct] = useState();
	const globalStore = useGlobalStore();
	const sitewidePack = globalStore.getSitewidePack();
	const cart = useCartStore();

	useEffect(() => {
		async function fetchProduct() {
			const fullProduct = await getProduct(product.handle);
			updateWunderkindProductTracking(fullProduct, true);
			setProduct(fullProduct);
		}
		fetchProduct();
	}, [product]);

	const addToCart = async (product, variantId, selectedTitle, setAddToCartLoading) => {
		const attributes = [];

		if (sitewidePack && VALID_SITEWIDE_PACK_TYPES.includes(product.productType)) {
			attributes.push({ key: SITEWIDE_PACK_BUILDER, value: 'true' });
			attributes.push({ key: PRODUCT_TYPE, value: product.productType });
			attributes.push({ key: BUILD_A_PACK, value: 'true' });
		}
		const line = {
			attributes,
			variantId,
			quantity: 1,
		};

		const lines = linesFromVariants([line]);

		const analyticsData = {
			price: product.prices[0],
			product: {
				id: createGid('Product', product.id),
				images: product.images,
				productType: product.productType,
				title: product.title,
				prices: product.prices,
				vendor: product.brand || 'Shinesty',
			},
			quantity: 1,
		};

		const variant = fullProduct.variants.find((variant) => {
			return variant.id && variant.id.toString() === variantId;
		});

		if (variant) {
			analyticsData.variantId = variant.id;
			analyticsData.variantSku = variant.sku;
			analyticsData.variantTitle = selectedTitle;
		}

		await cart
			.addItems({
				analytics: [analyticsData],
				error: () => {
					setAddToCartLoading(false);
				},
				lines: lines,
				success: () => {
					setAddToCartLoading(false);
				},
			})
			.catch((e) => {
				setAddToCartLoading(false);
				console.log(e);
			});
	};
	return (
		<div className="absolute z-20 w-full">
			<div className={classNames('pb-4 shadow-lg px-3 rounded-b-xl', className)}>
				<Typography variant="heading-xs" className="hidden md:block py-2">
					Add To Cart
				</Typography>
				<div className="flex flex-wrap gap-2">
					{fullProduct &&
						fullProduct.variants.map((variant, i) => {
							return (
								<VariantButton
									variant={variant}
									key={i}
									addToCart={addToCart}
									product={fullProduct}
								/>
							);
						})}
				</div>
			</div>
		</div>
	);
};

export default ProductCardQuickAdd;

ProductCardQuickAdd.propTypes = {
	className: PropTypes.string,
	isSupercollection: PropTypes.bool,
	product: PropTypes.object,
};

VariantButton.propTypes = {
	addToCart: PropTypes.func,
	product: PropTypes.object,
	variant: PropTypes.object,
};
