import axios from 'axios';

const getSizeGuide = (styleNumber) => {
	return axios({
		method: 'get',
		url: `${process.env.NEXT_PUBLIC_SHINE_PUBLIC}/size-guide-by-style-number`,
		params: { styleNumber },
		responseType: 'json',
	})
		.then(handleSuccess)
		.catch(handleError);
};

const handleError = (e) => {
	return { error: e, hasError: true };
};

const handleSuccess = (response) => {
	return response?.data;
};

module.exports = {
	getSizeGuide,
};
