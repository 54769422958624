import Button from '/components/Button';
import Clickable from '/components/Clickable';
import CountrySelector from '/components/CountrySelector';
import Icon from '/components/IconUpdated';
import NavGroup from '/components/top-nav/mobile-menu/NavGroup';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';

import dynamic from 'next/dynamic';
import { useState } from 'react';

const Drawer = dynamic(() => import('/components/Drawer'));
const DrawerBody = dynamic(() => import('/components/Drawer').then((mod) => mod.DrawerBody));
const DrawerHeader = dynamic(() => import('/components/Drawer').then((mod) => mod.DrawerHeader));
const DrawerFooter = dynamic(() => import('/components/Drawer').then((mod) => mod.DrawerFooter));

const NavMenu = ({ closeNavigation, footer, isOpen, menus, setOpen }) => {
	const DEFAULT_NAVIGATION_STATE = {
		current: { children: menus, location: 'start' },
	};

	// navigation state is an array that we pop items in and out of to maintain forward/backward
	const [navigationState, setNavigationState] = useState(DEFAULT_NAVIGATION_STATE);

	const resetNavigationState = () => {
		setNavigationState(DEFAULT_NAVIGATION_STATE);
	};

	const resetMenu = () => {
		setNavigationState(DEFAULT_NAVIGATION_STATE);
		setOpen(false);
	};

	const updateNavigationState = (navItem) => {
		const newNavigationState = { ...navigationState };
		newNavigationState.current = navItem;
		setNavigationState(newNavigationState);
	};

	return (
		<Drawer
			isOpen={isOpen}
			position="left"
			toggle={(val) => {
				setOpen(val);
			}}>
			<DrawerHeader>
				<div className="flex relative">
					<Button
						title="Close menu"
						className="block bg-white fill-gray-600 flex-none grow-1 hover:bg-white mr-4 px-6 py-5 self-start shrink-0"
						onClick={() => resetMenu()}>
						<Icon name="close" height="16px" width="16px" />
					</Button>
					<div className="basis-[calc(100%-128px)] flex justify-center">
						<Clickable linkToInternal="/" className="self-center" onClick={() => resetMenu()}>
							<Icon altText="shinesty logo" name="shinesty" height="30px" />
						</Clickable>
					</div>
				</div>
			</DrawerHeader>

			<DrawerBody>
				<div className="h-full">
					<NavGroup
						closeNavigation={closeNavigation}
						navGroup={navigationState.current}
						resetMenu={resetMenu}
						resetNavigationState={resetNavigationState}
						updateNavigationState={updateNavigationState}
					/>
					<Clickable linkToInternal="/refer" onClick={() => resetMenu()}>
						<div className="ml-6 pt-4">
							<Typography className="capitalize font-semibold !tracking-wider" variant="body">
								Get 20% Off
							</Typography>
						</div>
					</Clickable>
					<div className="ml-6 pt-4">
						<CountrySelector className="flex" />
					</div>
				</div>
			</DrawerBody>

			{footer && <DrawerFooter>{footer} </DrawerFooter>}
		</Drawer>
	);
};

NavMenu.propTypes = {
	closeNavigation: PropTypes.func,
	footer: PropTypes.element,
	isOpen: PropTypes.bool,
	menus: PropTypes.array,
	parent: PropTypes.object,
	setOpen: PropTypes.func,
};

export default NavMenu;
