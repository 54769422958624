import MenuItem from './MenuItem';
import PropTypes from 'prop-types';
// import classNames from '../../../../utils/class-names';

export default function GridNavigation({ menu }) {
	return (
		<div className="flex justify-center w-3/4 pb-6">
			<div className="flex justify-between mt-6 w-full">
				{menu.children.map((child, childIndex) => {
					return <MenuItem key={childIndex} item={child} menuStyle={menu.menuStyle} />;
				})}
			</div>
		</div>
	);
}

GridNavigation.propTypes = {
	menu: PropTypes.object,
};
