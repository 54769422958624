import Clickable from '../../../../Clickable';
import Image from '../../../../Image';
import PropTypes from 'prop-types';
import Typography from '../../../../Typography';
import { getItemHref } from '../../../utils';

const SidebarImage = ({ item }) => {
	let href = getItemHref(item);

	if (!href) {
		return null;
	}

	return (
		<Clickable
			className="!text-left first:mb-4"
			linkToInternal={href}
			heapEventName="Navigation Link Click"
			heapEventData={{
				Href: href,
				Position: 'Underwear images',
				'Nav Group': 'Underwear',
			}}>
			{item.imageSrc && (
				<Image
					className="rounded-md"
					src={item.imageSrc}
					alt={item.title}
					loading="lazy"
					priority={false}
					height={200}
					width={200}
					objectFit="contain"
				/>
			)}
			<Typography variant="link-header" component="div">
				{item.title}
			</Typography>
		</Clickable>
	);
};

SidebarImage.propTypes = {
	className: PropTypes.string,
	item: PropTypes.object,
	menuStyle: PropTypes.string,
	navGroup: PropTypes.string,
	tier: PropTypes.string,
	typographyVariant: PropTypes.string,
};

export default SidebarImage;
