import Slider from 'react-slick';
import { forwardRef } from 'react';
import { reactChildren } from '/utils/prop-types';

const Carousel = forwardRef(({ children, ...settings }, ref) => (
	<Slider {...settings} ref={ref}>
		{children}
	</Slider>
));

Carousel.displayName = 'Carousel';

Carousel.propTypes = {
	children: reactChildren.isRequired,
};

export default Carousel;
