import PropTypes from 'prop-types';
function RadioGroup({
	checkedValue,
	className = '',
	label = '',
	name,
	radios = [],
	onChange = () => {},
	error = false,
	errorText = '',
	description = '',
	horizontal = false,
	...newProps
}) {
	const hasError = error || errorText;

	let finalClass = `${className}`;
	if (hasError) {
		finalClass += ' border-red-600';
	}

	let radiosWrapperClass = '';
	let radioWrapperClass = 'flex items-center';

	if (horizontal) {
		radiosWrapperClass += 'flex flex-wrap';
		radioWrapperClass += ' mr-4';
	} else {
		radioWrapperClass += ' first:mt-2 last:mb-4 mb-2';
	}

	const Radios = radios.map((r) => {
		return (
			<div key={`${r.value}-${name}`} className={radioWrapperClass}>
				<label className="cursor-pointer text-base flex flex-col" htmlFor={`${r.value}-${name}`}>
					<fieldset className="px-2 py-1 mx-px">
						<div className="flex">
							<div className="border-gray-300 border-2 rounded-full w-6 h-6 flex justify-center items-center mr-2 shrink-0">
								<input
									name={name}
									type="radio"
									checked={checkedValue === r.value}
									className="rounded-full appearance-none w-4 h-4 outline-0 cursor-pointer checked:bg-primary"
									id={`${r.value}-${name}`}
									onChange={() => onChange(r.value)}
								/>
							</div>
							{r.label}
						</div>
					</fieldset>
				</label>
			</div>
		);
	});

	return (
		<div className={finalClass} {...newProps}>
			{label && (
				<label
					className={`mb-4 text-sm text-gray-600 inline-block ${hasError && 'text-primary'}`}
					htmlFor={name}>
					{label}
				</label>
			)}
			<div className={radiosWrapperClass}>{Radios}</div>
			{description && <span className="mt-2 text-gray-600 text-xs">{description}</span>}
			{errorText && (
				<div className="bg-red-200 mt-2 py-2 px-4 text-xs text-primary rounded-sm">{errorText}</div>
			)}
		</div>
	);
}

RadioGroup.propTypes = {
	checkedValue: PropTypes.string,
	className: PropTypes.string,
	description: PropTypes.string,
	error: PropTypes.bool,
	errorText: PropTypes.string,
	horizontal: PropTypes.bool,
	label: PropTypes.string,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	radios: PropTypes.array.isRequired,
};

export default RadioGroup;
