import MenuItem from './MenuItem';
import PropTypes from 'prop-types';

import classNames from '../../../../utils/class-names';

const MenuChild = ({ child, menuStyle = 'default' }) => {
	return (
		<div
			className={classNames(
				'max-w-[200px]',
				menuStyle === 'default' && 'my-4',
				menuStyle === 'condensed' && 'my-1',
				menuStyle === 'fancy' &&
					'hover:underline hover:decoration-4 hover:decoration-primary hover:underline-offset-4 transition-all mb-2 whitespace-nowrap',
			)}>
			<MenuItem item={child} menuStyle={menuStyle} typographyVariant="link-header" />
			{child.children && (
				<>
					{child.children.map((stepChild, stepChildKey) => {
						return <MenuItem item={stepChild} key={stepChildKey} menuStyle={menuStyle} />;
					})}
				</>
			)}
		</div>
	);
};

MenuChild.propTypes = {
	child: PropTypes.object,
	menuStyle: PropTypes.string,
};

export default MenuChild;
