import Clickable from '/components/Clickable';
import Image from '/components/Image';
import MenuChild from './MenuChild';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';

const imageButtons = [
	{
		href: '/products/build-a-boxer-pack',
		image:
			'https://cdn.shopify.com/s/files/1/0234/5963/files/ThreatLevelMidnightTrunks3Pack.png?v=1686232307&width=500&quality=75',
		title: 'Packs',
	},
	{
		href: '/collections/mens-ball-hammock-pouch-underwear-with-fly',
		image:
			'https://cdn.shopify.com/s/files/1/0234/5963/files/TroutOfTheBlueBXFly.png?v=1704745643&width=500&quality=75',
		title: 'Boxers W/ Fly',
	},
	{
		href: '/collections/singles-ball-hammock-pouch-underwear',
		image:
			'https://cdn.shopify.com/s/files/1/0234/5963/files/TripAdvisorBXStndrd.png?v=1704491390&width=500&quality=75',
		title: 'Standard Boxers',
	},
	{
		href: '/collections/mens-ball-hammock-pouch-underwear-with-fly',
		image: 'https://cdn.shinesty.com/2024-04-19/risque2.png',
		title: "Women's Boxer",
	},
	{
		href: '/products/white-stay-weird-ankle-socks',
		image:
			'https://cdn.shopify.com/s/files/1/0234/5963/files/Cloud9AnkleSock2.png?v=1701883945&width=500&quality=75',
		title: 'Socks',
	},
	{
		href: '/collections/all-mens-underwear',
		image:
			'https://cdn.shopify.com/s/files/1/0234/5963/files/ThreatLevelMidnightBXFly.png?v=1706219773&width=500&quality=75',
		title: 'All Mens',
	},
	{
		href: '/collections/womens-underwear',
		image: 'https://cdn.shinesty.com/2024-04-19/threat-render.png',
		title: 'All Womens',
	},
];

const ImageListNavigation = ({ menu }) => {
	return (
		<div className="flex h-[26rem] justify-start w-full">
			<div className="pl-24 py-8 w-1/3">
				{menu.children.map((topLevelChild, topLevelChildIndex) => {
					return (
						<MenuChild
							child={topLevelChild}
							key={topLevelChildIndex}
							menuStyle={topLevelChild.menuStyle}
						/>
					);
				})}
			</div>
			<div className="bg-gray-200 flex flex-wrap justify-center py-8 w-2/3">
				<div className="grid grid-cols-4 gap-x-8 gap-y-12">
					{imageButtons.map((item, itemIndex) => {
						return (
							<Clickable
								className="flex flex-col gap-y-2 h-40 items-center w-40"
								key={itemIndex}
								linkToInternal={item.href}>
								<div className="bg-gray-300 h-32 relative rounded-full w-32 p-2 border-2 border-gray-300 hover:border-white hover:ring-gray-600 hover:ring-4 transition-all">
									<Image src={item.image} alt="" height={200} width={200} objectFit="contain" />
								</div>
								<Typography component="div" variant="subtitle-sm">
									{item.title}
								</Typography>
							</Clickable>
						);
					})}
				</div>
			</div>
		</div>
	);
};

ImageListNavigation.propTypes = {
	menu: PropTypes.object,
};

export default ImageListNavigation;
