import { logTryCatch } from '/utils/logging';

const tryParseFloat = (value) => {
	try {
		value = parseFloat(value);
	} catch (e) {
		logTryCatch(e);
	}

	return value;
};

const tryParseInt = (value) => {
	try {
		value = parseInt(value);
	} catch (e) {
		logTryCatch(e);
	}

	return value;
};

module.exports = {
	tryParseFloat,
	tryParseInt,
};
