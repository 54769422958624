import { CONSOLE_BACKGROUND, ENABLE_LOGGING } from './constants';
import { logEvent, logMessage } from '../helpers/logging';

const sendEvent = (eventName, payload) => {
	if (!window || !window.tatari || !window.tatari.track) {
		logMessage(
			'Tatari',
			CONSOLE_BACKGROUND,
			ENABLE_LOGGING,
			eventName,
			'no window.tatari.track function found',
		);

		return;
	}

	try {
		window.tatari.track(eventName, payload);
		logEvent('Tatari', CONSOLE_BACKGROUND, ENABLE_LOGGING, eventName, payload);
	} catch (e) {
		logMessage('Tatari', CONSOLE_BACKGROUND, ENABLE_LOGGING, eventName, 'Error');
	}
};

export default sendEvent;
