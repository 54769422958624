import ProductCardQuickAdd from '/components/collections/ProductCardQuickAdd';
import PropTypes from 'prop-types';

const ProductCardAction = ({ className, product, showQuickAdd }) => {
	return <>{showQuickAdd && <ProductCardQuickAdd className={className} product={product} />}</>;
};

ProductCardAction.propTypes = {
	className: PropTypes.string,
	product: PropTypes.object,
	showQuickAdd: PropTypes.bool,
};

export default ProductCardAction;
