const COUNTRIES = [
	{
		countryCode: 'US',
		countryFlagIcon: '🇺🇸',
		currency: 'United States Dollar',
		currencyCode: 'USD',
		currencySymbol: '$',
		hiddenFunctionality: [],
		localeCode: 'en-US',
		name: 'United States',
		reverseCurrencyCode: false,
	},
	{
		countryCode: 'AU',
		currency: 'Australian Dollar',
		countryFlagIcon: 'countryFlagAU',
		currencyCode: 'AUD',
		currencySymbol: '$',
		hiddenFunctionality: ['subscription'],
		localeCode: 'en-AU',
		name: 'Australia',
		reverseCurrencyCode: false,
	},
	{
		countryCode: 'AT',
		countryFlagIcon: 'countryFlagAT',
		currency: 'Euro',
		currencyCode: 'EUR',
		currencySymbol: '€',
		hiddenFunctionality: ['subscription'],
		localeCode: 'en-AT',
		name: 'Austria',
		reverseCurrencyCode: false,
	},
	{
		countryCode: 'BE',
		countryFlagIcon: 'countryFlagBE',
		currency: 'Euro',
		currencyCode: 'EUR',
		currencySymbol: '€',
		hiddenFunctionality: ['subscription'],
		localeCode: 'en-BE',
		name: 'Belgium',
		reverseCurrencyCode: false,
	},
	{
		countryCode: 'BG',
		countryFlagIcon: 'countryFlagBG',
		currency: 'Bulgarian Lev',
		currencyCode: 'BGN',
		currencySymbol: 'лв',
		hiddenFunctionality: ['subscription'],
		localeCode: 'bg-BG',
		name: 'Bulgaria',
		reverseCurrencyCode: false,
	},
	{
		countryCode: 'CA',
		countryFlagIcon: 'countryFlagCA',
		currency: 'Canadian Dollar',
		currencyCode: 'CAD',
		currencySymbol: '$',
		hiddenFunctionality: ['subscription'],
		localeCode: 'en-CA',
		name: 'Canada',
		reverseCurrencyCode: false,
	},
	{
		countryCode: 'HR',
		countryFlagIcon: 'countryFlagHR',
		currency: 'Euro',
		currencyCode: 'EUR',
		currencySymbol: '€',
		hiddenFunctionality: ['subscription'],
		localeCode: 'hr-HR',
		name: 'Croatia',
		reverseCurrencyCode: false,
	},
	{
		countryCode: 'CY',
		countryFlagIcon: 'countryFlagCY',
		currency: 'Euro',
		currencyCode: 'EUR',
		currencySymbol: '€',
		hiddenFunctionality: ['subscription'],
		localeCode: 'en-CY',
		name: 'Cyprus',
		reverseCurrencyCode: false,
	},
	{
		countryCode: 'CZ',
		countryFlagIcon: 'countryFlagCZ',
		currency: 'Czech Koruna',
		currencyCode: 'CZK',
		currencySymbol: 'Kč',
		hiddenFunctionality: ['subscription'],
		localeCode: 'cs-CZ',
		name: 'Czech Republic',
		reverseCurrencyCode: false,
	},
	{
		countryCode: 'DK',
		countryFlagIcon: 'countryFlagDK',
		currency: 'Danish Krone',
		currencyCode: 'DKK',
		currencySymbol: 'kr.',
		hiddenFunctionality: ['subscription'],
		localeCode: 'da-DK',
		name: 'Denmark',
		reverseCurrencyCode: true,
	},
	{
		countryCode: 'EE',
		countryFlagIcon: 'countryFlagEE',
		currency: 'Euro',
		currencyCode: 'EUR',
		currencySymbol: '€',
		hiddenFunctionality: ['subscription'],
		localeCode: 'et-EE',
		name: 'Estonia',
		reverseCurrencyCode: false,
	},
	{
		countryCode: 'FI',
		countryFlagIcon: 'countryFlagFI',
		currency: 'Euro',
		currencyCode: 'EUR',
		currencySymbol: '€',
		hiddenFunctionality: ['subscription'],
		localeCode: 'fi-FI',
		name: 'Finland',
		reverseCurrencyCode: false,
	},
	{
		countryCode: 'FR',
		countryFlagIcon: 'countryFlagFR',
		currency: 'Euro',
		currencyCode: 'EUR',
		currencySymbol: '€',
		hiddenFunctionality: ['subscription'],
		localeCode: 'fr-FR',
		name: 'France',
		reverseCurrencyCode: true,
	},
	{
		countryCode: 'DE',
		countryFlagIcon: 'countryFlagDE',
		currency: 'Euro',
		currencyCode: 'EUR',
		currencySymbol: '€',
		hiddenFunctionality: ['subscription'],
		localeCode: 'de-DE',
		name: 'Germany',
		reverseCurrencyCode: true,
	},
	{
		countryCode: 'GR',
		countryFlagIcon: 'countryFlagGR',
		currency: 'Euro',
		currencyCode: 'EUR',
		currencySymbol: '€',
		hiddenFunctionality: ['subscription'],
		localeCode: 'el-GR',
		name: 'Greece',
		reverseCurrencyCode: false,
	},
	{
		countryCode: 'HU',
		countryFlagIcon: 'countryFlagHU',
		currency: 'Hungarian Forint',
		currencyCode: 'HUF',
		currencySymbol: 'Ft',
		hiddenFunctionality: ['subscription'],
		localeCode: 'hu-HU',
		name: 'Hungary',
		reverseCurrencyCode: false,
	},
	{
		countryCode: 'IE',
		countryFlagIcon: 'countryFlagIE',
		currency: 'Euro',
		currencyCode: 'EUR',
		currencySymbol: '€',
		hiddenFunctionality: ['subscription'],
		localeCode: 'en-IE',
		name: 'Ireland',
		reverseCurrencyCode: false,
	},
	{
		countryCode: 'IT',
		countryFlagIcon: 'countryFlagIT',
		currency: 'Euro',
		currencyCode: 'EUR',
		currencySymbol: '€',
		hiddenFunctionality: ['subscription'],
		localeCode: 'it-IT',
		name: 'Italy',
		reverseCurrencyCode: false,
	},
	{
		countryCode: 'LV',
		countryFlagIcon: 'countryFlagLV',
		currency: 'Euro',
		currencyCode: 'EUR',
		currencySymbol: '€',
		hiddenFunctionality: ['subscription'],
		localeCode: 'lv-LV',
		name: 'Latvia',
		reverseCurrencyCode: false,
	},
	{
		countryCode: 'LT',
		countryFlagIcon: 'countryFlagLT',
		currency: 'Euro',
		currencyCode: 'EUR',
		currencySymbol: '€',
		hiddenFunctionality: ['subscription'],
		localeCode: 'lt-LT',
		name: 'Lithuania',
		reverseCurrencyCode: false,
	},
	{
		countryCode: 'LU',
		countryFlagIcon: 'countryFlagLU',
		currency: 'Euro',
		currencyCode: 'EUR',
		currencySymbol: '€',
		hiddenFunctionality: ['subscription'],
		localeCode: 'lb-LU',
		name: 'Luxembourg',
		reverseCurrencyCode: false,
	},
	{
		countryCode: 'MT',
		countryFlagIcon: 'countryFlagMT',
		currency: 'Euro',
		currencyCode: 'EUR',
		currencySymbol: '€',
		hiddenFunctionality: ['subscription'],
		localeCode: 'en-MT',
		name: 'Malta',
		reverseCurrencyCode: false,
	},
	{
		countryCode: 'NL',
		countryFlagIcon: 'countryFlagNL',
		currency: 'Euro',
		currencyCode: 'EUR',
		currencySymbol: '€',
		hiddenFunctionality: ['subscription'],
		localeCode: 'en-NL',
		name: 'Netherlands',
		reverseCurrencyCode: false,
	},
	{
		countryCode: 'PL',
		countryFlagIcon: 'countryFlagPL',
		currency: 'Polish Zloty',
		currencyCode: 'PLN',
		currencySymbol: 'zł',
		hiddenFunctionality: ['subscription'],
		localeCode: 'pl-PL',
		name: 'Poland',
		reverseCurrencyCode: false,
	},
	{
		countryCode: 'PT',
		countryFlagIcon: 'countryFlagPT',
		currency: 'Euro',
		currencyCode: 'EUR',
		currencySymbol: '€',
		hiddenFunctionality: ['subscription'],
		localeCode: 'pt-PT',
		name: 'Portugal',
		reverseCurrencyCode: false,
	},
	{
		countryCode: 'RO',
		countryFlagIcon: 'countryFlagRO',
		currency: 'Romanian Leu',
		currencyCode: 'RON',
		currencySymbol: 'lei',
		hiddenFunctionality: ['subscription'],
		localeCode: 'ro-RO',
		name: 'Romania',
		reverseCurrencyCode: false,
	},
	{
		countryCode: 'SG',
		currency: 'Singaporean Dollar',
		countryFlagIcon: 'countryFlagSG',
		currencyCode: 'SGD',
		currencySymbol: '$',
		hiddenFunctionality: ['subscription'],
		localeCode: 'en-SG',
		name: 'Singapore',
		reverseCurrencyCode: false,
	},
	{
		countryCode: 'SK',
		countryFlagIcon: 'countryFlagSK',
		currency: 'Euro',
		currencyCode: 'EUR',
		currencySymbol: '€',
		hiddenFunctionality: ['subscription'],
		localeCode: 'sk-SK',
		name: 'Slovakia',
		reverseCurrencyCode: false,
	},
	{
		countryCode: 'SI',
		countryFlagIcon: 'countryFlagSI',
		currency: 'Euro',
		currencyCode: 'EUR',
		currencySymbol: '€',
		hiddenFunctionality: ['subscription'],
		localeCode: 'sl-SL',
		name: 'Slovenia',
		reverseCurrencyCode: false,
	},
	{
		countryCode: 'ES',
		countryFlagIcon: 'countryFlagES',
		currency: 'Euro',
		currencyCode: 'EUR',
		currencySymbol: '€',
		hiddenFunctionality: ['subscription'],
		localeCode: 'es-ES',
		name: 'Spain',
		reverseCurrencyCode: false,
	},
	{
		countryCode: 'SE',
		countryFlagIcon: 'countryFlagSE',
		currency: 'Swedish Krona',
		currencyCode: 'SEK',
		currencySymbol: 'kr.',
		hiddenFunctionality: ['subscription'],
		localeCode: 'en-SE',
		name: 'Sweden',
		reverseCurrencyCode: true,
	},
	{
		countryCode: 'GB',
		countryFlagIcon: 'countryFlagGB',
		currency: 'Pound Sterling',
		currencyCode: 'GBP',
		currencySymbol: '£',
		hiddenFunctionality: ['subscription'],
		localeCode: 'en-GB',
		name: 'United Kingdom',
		reverseCurrencyCode: false,
	},
];

const checkFunctionality = (countryCode, functionalityKey) => {
	const currentCountry = getCountryByCode(countryCode);
	return (
		!currentCountry?.hiddenFunctionality ||
		!currentCountry.hiddenFunctionality.includes(functionalityKey)
	);
};

const getCountries = () => {
	return COUNTRIES;
};

const getCountryByCode = (code) => {
	return COUNTRIES.find((country) => {
		return country.countryCode === code;
	});
};

module.exports = {
	checkFunctionality,
	getCountries,
	getCountryByCode,
};
