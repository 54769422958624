import axios from 'axios';

import { createGid, parseGid } from '/utils/graph-ql';
import { readLocalStorage, removeLocalStorageValue, writeLocalStorage } from '/utils/local-storage';

const LOOP_RETURNS_PARAMS_KEY = 'loop_returns_params';
const LOOP_RETURNS_CART_TOKEN_KEY = 'loop_returns_cart_token';
const LOOP_RETURNS_RETURN_ID_KEY = 'loop_returns_return_id';

const createCart = async (processedCart) => {
	// we don't have a cart - early exit since there's nothing to do
	if (
		!processedCart ||
		!processedCart.processedLineItems ||
		processedCart.processedLineItems.length == 0
	) {
		return;
	}

	// early exit for no saved loop return id
	const loopParams = getLoopReturnsParams();
	if (!loopParams || !loopParams.loop_return_id) {
		return;
	}

	// map the processed cart variant to an array of variant ids, where there are mulitple ids for multiple quantities
	const variantIds = processedCart.processedLineItems.reduce((memo, lineItem) => {
		if (!lineItem.merchandise?.id || !lineItem.quantity || lineItem.quantity < 0) {
			return memo;
		}

		const parsed = parseGid(lineItem.merchandise.id);

		if (!parsed) {
			return memo;
		}

		for (var i = 0; i < lineItem.quantity; i++) {
			memo.push(parsed);
		}

		return memo;
	}, []);

	let cartToken = readLocalStorage(LOOP_RETURNS_CART_TOKEN_KEY) || '';

	// create the cart on the loop api
	const loopCart = await axios({
		data: { cart: variantIds },
		method: 'POST',
		url: `${process.env.NEXT_PUBLIC_LOOP_RETURNS_API}/${cartToken}`,
		headers: {
			accept: 'application/json',
			'content-type': 'application/json',
			'X-Authorization': process.env.NEXT_PUBLIC_LOOP_RETURNS_API_KEY,
		},
	});

	const toReturn = {
		success: false,
	};

	// if we're successful in creating the cart and get a token back, create the checkout url and remove all the loop data
	// from local storage.
	if (!loopCart || !loopCart.data) {
		return toReturn;
	}

	toReturn.loopCart = loopCart.data;
	toReturn.success = true;

	// loop doesnt return the token on the update call
	if (loopCart.data.token) {
		cartToken = loopCart.data.token;
		writeLocalStorage(LOOP_RETURNS_CART_TOKEN_KEY, loopCart.data.token);
	}

	toReturn.checkoutUrl = `${process.env.NEXT_PUBLIC_LOOP_RETURNS_CHECKOUT_URL}/${cartToken}`;
	removeLocalStorageValue(LOOP_RETURNS_PARAMS_KEY);

	return toReturn;
};

const createParams = (query) => {
	if (!query) {
		return;
	}

	const queryKeys = Object.keys(query);
	let loopParams;

	queryKeys.forEach((key) => {
		if (key.indexOf('loop') > -1) {
			if (!loopParams) {
				loopParams = {};
			}

			loopParams[key] = query[key];
		}
	});

	return loopParams;
};

const getLoopReturnsParams = () => {
	const onServer = typeof window === 'undefined';

	if (onServer) {
		return;
	}

	try {
		const loopData = readLocalStorage(LOOP_RETURNS_PARAMS_KEY);
		return JSON.parse(loopData);
	} catch (e) {
		console.log('could not parse local storage loop returns');
		return;
	}
};

const init = async (query) => {
	const params = createParams(query);

	// there are no loop parameters in the query string
	if (!params) {
		return;
	}

	setLoopReturnsParams(params);

	const cartToken = readLocalStorage(LOOP_RETURNS_CART_TOKEN_KEY);
	const returnId = readLocalStorage(LOOP_RETURNS_RETURN_ID_KEY);

	// the user hasn't been on our site via loop, do nothing.
	if (!cartToken || !returnId) {
		return;
	}

	// the user has been on our site via loop, but this is a different return
	// remove the old return and then do nothing.
	if (!params.loop_return_id || params.loop_return_id !== returnId) {
		removeLocalStorageValue(LOOP_RETURNS_RETURN_ID_KEY);
		removeLocalStorageValue(LOOP_RETURNS_CART_TOKEN_KEY);
		return;
	}

	// the user has been on our site via loop and the return ids match, populate the cart from loop data
	const loopCart = await axios({
		method: 'GET',
		url: `${process.env.NEXT_PUBLIC_LOOP_RETURNS_API}/${cartToken}`,
		headers: {
			accept: 'application/json',
			'content-type': 'application/json',
			'X-Authorization': process.env.NEXT_PUBLIC_LOOP_RETURNS_API_KEY,
		},
	});

	// either we didn't get a cart back or the cart has no variants, do nothing
	if (!loopCart || !loopCart.data || !loopCart.data.cart || loopCart.data.cart.length === 0) {
		return;
	}

	const newLineItems = loopCart.data.cart.reduce((memo, variantId) => {
		if (!memo[variantId]) {
			memo[variantId] = {
				merchandiseId: createGid('ProductVariant', variantId),
				quantity: 0,
			};
		}

		memo[variantId].quantity++;
		return memo;
	}, {});

	return Object.values(newLineItems);
};

const setLoopReturnsParams = (params) => {
	if (params.loop_return_id) {
		writeLocalStorage(LOOP_RETURNS_RETURN_ID_KEY, params.loop_return_id);
	}

	writeLocalStorage(LOOP_RETURNS_PARAMS_KEY, JSON.stringify(params));
};

module.exports = {
	createCart,
	createParams,
	getLoopReturnsParams,
	init,
	setLoopReturnsParams,
};
