// Shopify only gives us selling plan names, this gives us
// additional data to use throughout the site.
import { formatMonetaryValue } from '/utils/format';
import { getProductType } from '/services/static/product-types';

const AUXILLARY_SELLING_PLAN_DATA = [
	{
		billingText: 'Billed Annually',
		cardText: '1 Pair Delivered Every Month',
		cartText: 'Annual |productType| Subscription',
		discountText: 'Save',
		frequency: 'annually',
		key: 'annually',
		label: '1 Pair Per Month for 12 Months',
		priceModifier: 'YR',
		sendText: "We'll send you 1 pair per month for 12 months",
		shopifyTitle: 'Delivered every month, paid annually',
	},
	{
		billingText: 'Billed Monthly',
		cardText: '1 Pair Delivered Every Month',
		cartText: 'Monthly |productType| Subscription',
		discountText: 'Save',
		frequency: 'monthly',
		key: 'monthly',
		label: '1 Every 1 Month',
		priceModifier: 'MO',
		sendText: "We'll send you 1 pair per month",
		shopifyTitle: 'Delivered every month',
	},
	{
		billingText: 'Billed Quarterly',
		cardText: '3 Pairs Delivered Every 3 Months',
		cartText: 'Quarterly |productType| Subscription',
		discountText: 'Save',
		frequency: 'quarterly',
		key: 'quarterly',
		label: '3 Every 3 Months',
		priceModifier: 'QTR',
		sendText: "We'll send you 3 pairs every 3 months",
		shopifyTitle: 'Delivered every quarter',
	},

	// laundry sheets
	{
		billingText: 'Billed Every Other Month',
		cardText: '1 Box Delivered Every Other Month',
		cartText: 'Bi-Monthly |productType| Subscription',
		discountText: 'Save',
		frequency: 'bimonthly',
		key: 'bimonthly',
		label: '1 Box Every Other Month',
		priceModifier: 'e.o.mo.',
		sendText: "We'll send you 1 pair per month for 12 months",
		shopifyTitle: 'Delivered every other month',
	},
];

const PARENT_VARIANTS = [
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NTAxMDg4NQ==',
		key: 'couples2198',
		productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTMxMDMxNzM=',
		sku: '20000-0009-OSFA',
	},
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NDc4MTUwOQ==',
		key: 'couples2398',
		productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTI5MDY1NjU=',
		sku: '20000-0006-OSFA',
	},
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NDY4MzIwNQ==',
		key: 'couples2598',
		productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTI4MDgyNjE=',
		sku: '20000-0004-OSFA',
	},
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NDkxMjU4MQ==',
		key: 'couples2798',
		productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTMwMDQ4Njk=',
		sku: '20000-0008-OSFA',
	},
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NDgxNDI3Nw==',
		key: 'couples2998',
		productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTI5MzkzMzM=',
		sku: '20000-0005-OSFA',
	},
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NDg3OTgxMw==',
		key: 'couples3398',
		productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTI5NzIxMDE=',
		sku: '20000-0007-OSFA',
	},
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NDcxNTk3Mw==',
		key: 'single1599',
		productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTI4NDEwMjk=',
		sku: '20000-0003-OSFA',
	},
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NDY1MDQzNw==',
		key: 'single1299',
		productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTI3NzU0OTM=',
		sku: '20000-0001-OSFA',
	},
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NDc0ODc0MQ==',
		key: 'single1699',
		productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTI4NzM3OTc=',
		sku: '20000-0002-OSFA',
	},
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NTA0MzY1Mw==',
		key: 'single2898',
		productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTMxMzU5NDE=',
		sku: '20004-0003-OSFA',
	},
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NDk0NTM0OQ==',
		key: 'single3498',
		productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTMwMzc2Mzc=',
		sku: '20004-0001-OSFA',
	},
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NDk3ODExNw==',
		key: 'single4497',
		productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTMwNzA0MDU=',
		sku: '20004-0002-OSFA',
	},
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NTE0MTk1Nw==',
		key: 'single9999',
		productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTMyMzQyNDU=',
		sku: '20012-0003-OSFA',
	},
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NTA3NjQyMQ==',
		key: 'single11999',
		productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTMxNjg3MDk=',
		sku: '20012-0001-OSFA',
	},
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE5MzM3NTEwOTE4OQ==',
		key: 'single15999',
		productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTMyMDE0Nzc=',
		sku: '20012-0002-OSFA',
	},

	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDU2OTUwMDIzNzg5Mw==',
		key: 'single1599',
		productId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY4OTIxNTMxNzYxMzM=',
		sku: '20002-0001-OSFA',
	},
];
const getAuxillarySellingPlanData = (shopifySellingPlanTitle, productType) => {
	const sellingPlanData = AUXILLARY_SELLING_PLAN_DATA.find((data) => {
		return data.shopifyTitle === shopifySellingPlanTitle;
	});
	const productTypeDetails = getProductType(productType);
	const finalObj = { ...sellingPlanData };

	if (productTypeDetails?.titleShort && sellingPlanData?.cartText) {
		const cartText = sellingPlanData.cartText.replace(
			'|productType|',
			productTypeDetails.titleShort,
		);
		finalObj.cartText = cartText;
	}

	return {
		...(finalObj || {}),
	};
};

const getParentVariantBySubscriptionTypeAndPrice = (subscriptionType, price) => {
	if (typeof price !== 'string') {
		price = formatMonetaryValue(price);
	}

	price = price.replace('.', '');

	const key = `${subscriptionType}${price}`;

	return PARENT_VARIANTS.find((variant) => {
		return variant.key === key;
	});
};

const getSubscriptionAnalyticsProductFromSellingPlan = (
	product,
	sellingPlan,
	productType,
	planType,
) => {
	const subscriptionParentVariant = getParentVariantBySubscriptionTypeAndPrice(
		planType,
		sellingPlan.recurringPrice,
	);

	if (!subscriptionParentVariant) {
		return;
	}

	return {
		id: subscriptionParentVariant.productId,
		images: product.images,
		prices: [sellingPlan.recurringPrice],
		productType: productType,
		quantity: 1,
		title: product.title,
		variants: [
			{
				id: subscriptionParentVariant.id,
				sku: subscriptionParentVariant.sku,
				title: sellingPlan.cardText,
				quantityAvailable: 0, // TODO: Do we want the product variant inventory or 0 (subscription variant inventory)?
			},
		],
		vendor: 'Private Label',
	};
};

module.exports = {
	getAuxillarySellingPlanData,
	getSubscriptionAnalyticsProductFromSellingPlan,
};
