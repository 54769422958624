import { parseGid } from '/utils/graph-ql';
import { tryParseFloat } from '/utils/parsers';

const formatProducts = (products) => {
	if (!Array.isArray(products)) {
		return;
	}

	return products.reduce(
		(memo, product) => {
			if (!product) {
				return memo;
			}

			const content = {};

			if (product.shopifyProductId) {
				content.productId = parseGid(product.shopifyProductId);
			}

			if (product.productType) {
				content.productType = product.productType;
			}

			if (product.quantity) {
				content.quantity = product.quantity;
			}

			if (product.title) {
				content.title = product.title;
			}

			if (product.price) {
				const parsed = tryParseFloat(product.price);

				if (Number.isFinite(parsed)) {
					content.price = parsed;
					memo.total += parsed;
				}
			}

			memo.contents.push(content);

			return memo;
		},
		{ contents: [], total: 0 },
	);
};

module.exports = {
	formatProducts,
};
