import { parseGid } from '/utils/graph-ql';

const transformCartLine = (cartLine, products) => {
	let formatted = {
		quantity: cartLine.quantity || 1,
	};

	const product = products.find((product) => {
		//this looks weird but the subscription ids we ad as lines are already encoded so need to bring them both back
		return parseGid(product.shopifyVariantId) === parseGid(cartLine.merchandiseId);
	});

	if (product) {
		formatted = { ...formatted, ...product };
	}

	return formatted;
};

export default transformCartLine;
