import { gql } from '@apollo/client';

const GET_VARIANT_INVENTORY = gql`
	query ($variantId: ID!) {
		node(id: $variantId) {
			... on ProductVariant {
				id
				availableForSale
				quantityAvailable
				currentlyNotInStock
			}
		}
	}
`;

export default GET_VARIANT_INVENTORY;
