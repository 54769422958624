import Clickable from '/components/Clickable';
import Icon from '/components/IconUpdated';
import Image from '/components/Image';
import Price from '../Price';
import Typography from '/components/Typography';
import dynamic from 'next/dynamic';
import { getNextTier } from '/services/static/promotions';
import { observer } from 'mobx-react-lite';
import { processor } from '/utils/cart-processor';
import { useCartStore } from '/state/cart-context';
import { useGlobalStore } from '/state/global-context';

import { readLocalStorage, writeLocalStorage } from '/utils/local-storage';
import { useEffect, useState } from 'react';

const Modal = dynamic(() => import('/components/Modal'));
const ModalBody = dynamic(() => import('/components/Modal').then((mod) => mod.ModalBody));

const StackingPopup = () => {
	const globalStore = useGlobalStore();
	const cart = useCartStore();

	const [stackingModalOpen, setStackingModalOpen] = useState(false);
	const [nextTierDetails, setNextTierDetails] = useState({});
	const [savingsAmount, setSavingsAmount] = useState(0);
	const [distanceFromFree, setDistanceFromFree] = useState(100);

	const promotion = globalStore.getPromotionalConfig();

	// TODO PROMOTION - seems like we need error handling here...
	let discountAmount = Number(promotion?.discountCodeValue) / 100;
	useEffect(async () => {
		if (window) {
			const localStorageValue = readLocalStorage(promotion.dateEnd);
			if (!localStorageValue) {
				setStackingModalOpen(true);
			}
			writeLocalStorage(promotion.dateEnd, true);
		}
		const processed = processor(cart);

		setSavingsAmount(processed.subTotal * discountAmount);
		setDistanceFromFree(100 - processed.subTotal);
		if (promotion.promotionType == 'stacking-gifts') {
			const nextTier = await getNextTier(processed.subTotal);
			setNextTierDetails(nextTier);
		}
	}, [cart.shopifyCart.lines]);

	if (!promotion.sitewidePopup) {
		return null;
	}

	return (
		<>
			<Modal
				id="quickshop"
				isOpen={stackingModalOpen}
				toggle={(val) => setStackingModalOpen(val)}
				customType="stacking">
				<ModalBody>
					<button
						type="button"
						className="fixed md:absolute mt-2 md:mt-6 mb-10 ml-4 p-2 bg-white rounded-full z-20"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => setStackingModalOpen(false)}>
						<Icon name="close" height="15px" width="15px" />
					</button>
					<Clickable
						onClick={() => setStackingModalOpen(false)}
						className="hidden relative md:block text-center mt-16 h-[39rem] w-full">
						<Image
							alt="modal"
							layout="fill"
							objectFit="contain"
							objectPosition="top"
							src={promotion.sitewidePopup.imageSrcDesktop}
						/>
						{promotion && promotion.termsHref && (
							<div className="w-16 rounded-full text-center text-primary absolute -top-8 right-2 bg-white font-bold">
								<Clickable linkToInternal={promotion.termsHref}>Terms</Clickable>
							</div>
						)}
					</Clickable>
					<Clickable
						onClick={() => setStackingModalOpen(false)}
						className="md:hidden relative top-0 text-center mt-10 h-[75vh] w-full">
						<Image
							alt="modal"
							layout="fill"
							objectFit="contain"
							objectPosition="top"
							src={promotion.sitewidePopup.imageSrcMobile}
						/>
						{promotion && promotion.termsHref && (
							<div className="w-16 rounded-full text-center text-primary ml-2 absolute -top-8 right-2 bg-white font-bold">
								<Clickable linkToInternal={promotion.termsHref}>Terms</Clickable>
							</div>
						)}
					</Clickable>
				</ModalBody>
			</Modal>

			{promotion.promotionType === 'prime-day' && (
				<Clickable
					className="bottom-[20px] h-14 fixed right-2 w-[9rem] md:w-32 z-10 bg-gray-800 rounded-lg capitalize"
					heapEventName="Stacking Modal Open"
					heapEventData={{
						page: 'home',
					}}
					onClick={() => setStackingModalOpen(true)}>
					<div className="absolute z-20 right-2 top-3 text-right">
						<Typography className="text-white text-sm leading-[.1rem]" component="div">
							You&apos;re saving <Price price={savingsAmount} showSymbol={true} />
						</Typography>

						{distanceFromFree > 0 ? (
							<>
								<Typography
									className="text-white text-sm leading-[.8rem] pt-2 grid"
									component="div">
									<span className="font-semibold">
										add <Price price={distanceFromFree} showSymbol={true} />{' '}
									</span>

									<span className="lowercase">for free shipping</span>
								</Typography>
							</>
						) : (
							<Typography
								className="text-white text-sm leading-[.8rem] font-semibold pt-2"
								component="div">
								+ free shipping
							</Typography>
						)}
					</div>
				</Clickable>
			)}

			{promotion.promotionType === 'stacking-gifts' && nextTierDetails && nextTierDetails.tier && (
				<Clickable
					className="bottom-[20px] h-20 fixed right-2 w-[10rem] md:w-48 z-10"
					heapEventName="Stacking Modal Open"
					heapEventData={{
						page: 'home',
					}}
					onClick={() => setStackingModalOpen(true)}>
					<Image
						alt={nextTierDetails.tier.title}
						layout="fill"
						objectFit="contain"
						src={nextTierDetails.tier.imageSrcModalTrigger}
					/>
					<div className="absolute z-20 right-16 md:right-24 top-3 text-right">
						<Typography className="text-white text-sm leading-[.5rem]" component="div">
							You are
						</Typography>
						<Typography className="text-white text-sm font-bold leading-0" component="div">
							<Price price={nextTierDetails.priceDifference} showSymbol={true} />
						</Typography>
						<Typography className="text-white text-sm leading-[.75rem]" component="div">
							away from free gift:
						</Typography>
					</div>
				</Clickable>
			)}
		</>
	);
};

export default observer(StackingPopup);
