import IconUpdated from '/components/IconUpdated';
import PropTypes from 'prop-types';

const Loading = ({ className = '', height = '25px', width = '25px' }) => {
	className += ' animate-spin';

	return (
		<IconUpdated
			altText="loading"
			className={className}
			height={height}
			name="spinner"
			width={width}
		/>
	);
};

Loading.propTypes = {
	className: PropTypes.string,
	height: PropTypes.string,
	width: PropTypes.string,
};

export default Loading;
