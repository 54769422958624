import axios from 'axios';

const getRemotePromotionConfig = async () => {
	return axios
		.get(`${process.env.NEXT_PUBLIC_SHINE_PUBLIC}/sale-current`)
		.then((res) => {
			if (res?.status === 200 && res.data) {
				return res.data;
			}
		})
		.catch(() => {
			return {};
		});
};

module.exports = {
	getRemotePromotionConfig,
};
