import { trackLead } from '../services/analytics/shinesty-analytics';
import { useEffect, useState } from 'react';

const BounceXWatcher = () => {
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		if (isMounted) {
			return;
		}

		document.addEventListener(
			'click',
			(e) => {
				if (!e.target.closest('form[action*="api.bounceexchange.com"] [type="submit"]')) {
					return;
				}

				const form = e.target.closest('form[action*="api.bounceexchange.com"]');
				if (!form) {
					return;
				}

				const email = form.querySelector('input[type="email"]')?.value;
				const phone = form.querySelector('input[type="tel"]')?.value;

				if (email || phone) {
					trackLead(email, phone);
				}
			},
			{ capture: true },
		);

		setIsMounted(true);
	}, []);

	return null;
};

export default BounceXWatcher;
