import MenuChild from './MenuChild';
import PropTypes from 'prop-types';
import SubNavigationFooter from './SubNavigationFooter';
import SubNavigationSidebar from './SubNavigationSidebar';

const LinkListNavigation = ({ menu }) => {
	return (
		<div className="flex justify-between mt-6 w-3/4">
			<div className="flex-1 mr-6">
				<div className="flex flex-auto flex-col flex-wrap gap-x-4 h-[26rem] nk-0">
					{menu.children.map((topLevelChild, topLevelChildIndex) => {
						return (
							<MenuChild
								child={topLevelChild}
								key={topLevelChildIndex}
								menuStyle={topLevelChild.menuStyle}
							/>
						);
					})}
				</div>
				{menu.footer && <SubNavigationFooter items={menu.footer} />}
			</div>
			{menu.sidebar && <SubNavigationSidebar sidebar={menu.sidebar} />}
		</div>
	);
};

LinkListNavigation.propTypes = {
	menu: PropTypes.object,
};

export default LinkListNavigation;
