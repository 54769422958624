import Clickable from '../../../../Clickable';
import Image from '../../../../Image';
import PropTypes from 'prop-types';
import Typography from '../../../../Typography';
import { getItemHref } from '../../../utils';

const LinkImage = ({ item }) => {
	let href = getItemHref(item);

	if (!href) {
		return null;
	}

	return (
		<Clickable
			className="flex justify-center relative"
			linkToInternal={item.href}
			heapEventName="Navigation Link Click"
			heapEventData={{
				Href: item.href,
				Position: 'Underwear images',
				'Nav Group': 'Underwear',
			}}>
			{item.imageSrc && (
				<Image
					className="rounded-xl w-60"
					src={item.imageSrc}
					alt={item.title}
					loading="lazy"
					priority={false}
					height={455}
					width={250}
					objectFit="cover"
				/>
			)}
			<Typography
				variant="link-red"
				component="div"
				className="absolute bottom-12 hover:text-gray-200 font-bold leading-tight text-2xl text-white tracking-widest uppercase text-center">
				{item.title}
			</Typography>
		</Clickable>
	);
};

LinkImage.propTypes = {
	className: PropTypes.string,
	item: PropTypes.object,
	menuStyle: PropTypes.string,
	navGroup: PropTypes.string,
	tier: PropTypes.string,
	typographyVariant: PropTypes.string,
};

export default LinkImage;
