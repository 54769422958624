import axios from 'axios';
import { formatProduct as formatProductAlgolia } from '/utils/product-normalizers/algolia';
import { parseGid } from '/utils/graph-ql';
import {
	PRODUCT_TYPE_BIKINI_LOWER,
	PRODUCT_TYPE_BOXERS_BRIEF_LOWER,
	PRODUCT_TYPE_BOXERS_COOLING_LOWER,
	PRODUCT_TYPE_BOXERS_FLY_LOWER,
	PRODUCT_TYPE_BOXERS_LONG_FLY_LOWER,
	PRODUCT_TYPE_BOXERS_LOOSE_LOWER,
	PRODUCT_TYPE_BOXERS_LOWER,
	PRODUCT_TYPE_BOXERS_TRUNK_LOWER,
	PRODUCT_TYPE_BOYSHORT_LOWER,
	PRODUCT_TYPE_BOYS_UNDERWEAR_LOWER,
	PRODUCT_TYPE_BRALETTES_COOLING_LOWER,
	PRODUCT_TYPE_BRALETTES_LOWER,
	PRODUCT_TYPE_CHEEKY_LOWER,
	PRODUCT_TYPE_LONG_JOHNS_LOWER,
	PRODUCT_TYPE_LOUNGE_MENS_HOODIE_LOWER,
	PRODUCT_TYPE_LOUNGE_MENS_JOGGER_LOWER,
	PRODUCT_TYPE_LOUNGE_WOMENS_HOODIE_LOWER,
	PRODUCT_TYPE_LOUNGE_WOMENS_JOGGER_LOWER,
	PRODUCT_TYPE_SLEEP_MENS_BOTTOMS_LOWER,
	PRODUCT_TYPE_SLEEP_MENS_TOPS_LOWER,
	PRODUCT_TYPE_SLEEP_WOMENS_DRESS_LOWER,
	PRODUCT_TYPE_SLEEP_WOMENS_SETS_LOWER,
	PRODUCT_TYPE_SOCKS_ANKLE_LOWER,
	PRODUCT_TYPE_SOCKS_CREW_LOWER,
	PRODUCT_TYPE_THONGS_COOLING_LOWER,
	PRODUCT_TYPE_THONGS_LOWER,
	PRODUCT_TYPE_THONGS_MODAL_LOWER,
	PRODUCT_TYPE_WOMENS_BOXER_LOWER,
} from './static/product-types';

const COLLECTION_BIKINI = 'womens-bikini-underwear';
const COLLECTION_BOYS_UNDERWEAR = 'boys-underwear';
const COLLECTION_BOXERS = 'singles-ball-hammock-pouch-underwear';
const COLLECTION_BOXERS_BRIEF = 'ball-hammock-briefs';
const COLLECTION_BOXERS_COOLING_FLY = 'ball-hammock-pouch-cooling-underwear';
const COLLECTION_BOXERS_FLY = 'mens-ball-hammock-pouch-underwear-with-fly';
const COLLECTION_BOXERS_LOOSE = 'pouchless-mens-boxers';
const COLLECTION_BOXERS_LONG = 'mens-long-leg-boxer-briefs';
const COLLECTION_BOXERS_TRUNK = 'ball-hammock-trunk-underwear';
const COLLECTION_BOYSHORT = 'womens-boyshort-underwear';
const COLLECTION_CHEEKY = 'cheeky-underwear';
const COLLECTION_LONG_JOHN = 'long-john-underwear';
const COLLECTION_MENS_LOUNGE_HOODIE = 'mens-goldilocks-hoodies-never-too-tight-loose-hot-or-cold';
const COLLECTION_MENS_LOUNGE_JOGGER = 'mens-hoodies-and-sweatpants';
const COLLECTION_MENS_TSHIRTS = 'mens-everyman-t-shirts';
const COLLECTION_SLEEP_MENS_BOTTOMS =
	'sleepdeep-mens-pajamas-bottoms-super-stupid-soft-micromodal-pajama-bottoms-for-men';
const COLLECTION_SLEEP_MENS_TOPS =
	'sleepdeep-mens-pajamas-tops-super-stupid-soft-micromodal-pajama-tops-for-men';
const COLLECTION_SLEEP_WOMENS_DRESS = 'sleepdeep-sleep-dresses';
const COLLECTION_TYPE_SLEEP_WOMENS_SETS = 'sleepdeep-pajamas-for-women';
const COLLECTION_THONG = 'womens-thong-underwear';
const COLLECTION_THONGS_MODAL = 'modal-thong-underwear-for-women';
const COLLECTION_WOMENS_BOXER = 'womens-boxer-briefs';
const COLLECTION_WOMENS_LOUNGE_HOODIE = 'womens-sweartshirt-and-sweatpants-sets';
const COLLECTION_WOMENS_LOUNGE_JOGGER =
	'womens-goldilocks-joggers-never-too-tight-loose-hot-or-cold';

// when looking up related prints by product type, use these collections.
const collections = {
	[PRODUCT_TYPE_BIKINI_LOWER]: COLLECTION_BIKINI,
	[PRODUCT_TYPE_BOXERS_LOWER]: COLLECTION_BOXERS,
	[PRODUCT_TYPE_BOXERS_BRIEF_LOWER]: COLLECTION_BOXERS_BRIEF,
	[PRODUCT_TYPE_BOXERS_COOLING_LOWER]: COLLECTION_BOXERS_COOLING_FLY,
	[PRODUCT_TYPE_BOXERS_FLY_LOWER]: COLLECTION_BOXERS_FLY,
	[PRODUCT_TYPE_BOXERS_LONG_FLY_LOWER]: COLLECTION_BOXERS_LONG,
	[PRODUCT_TYPE_BOXERS_LOOSE_LOWER]: COLLECTION_BOXERS_LOOSE,
	[PRODUCT_TYPE_BOXERS_TRUNK_LOWER]: COLLECTION_BOXERS_TRUNK,
	[PRODUCT_TYPE_BOYS_UNDERWEAR_LOWER]: COLLECTION_BOYS_UNDERWEAR,
	[PRODUCT_TYPE_BOYSHORT_LOWER]: COLLECTION_BOYSHORT,
	[PRODUCT_TYPE_BRALETTES_LOWER]: 'bralettes',
	[PRODUCT_TYPE_BRALETTES_COOLING_LOWER]: 'cooling-thong-underwear-bralette',
	[PRODUCT_TYPE_CHEEKY_LOWER]: COLLECTION_CHEEKY,
	[PRODUCT_TYPE_LONG_JOHNS_LOWER]: COLLECTION_LONG_JOHN,
	[PRODUCT_TYPE_LOUNGE_MENS_HOODIE_LOWER]: COLLECTION_MENS_LOUNGE_HOODIE,
	[PRODUCT_TYPE_LOUNGE_MENS_JOGGER_LOWER]: COLLECTION_MENS_LOUNGE_JOGGER,
	"men's shorts": 'ball-hammock-athletic-shorts',
	"men's t-shirts": COLLECTION_MENS_TSHIRTS,
	[PRODUCT_TYPE_SLEEP_MENS_TOPS_LOWER]: COLLECTION_SLEEP_MENS_TOPS,
	[PRODUCT_TYPE_SLEEP_MENS_BOTTOMS_LOWER]: COLLECTION_SLEEP_MENS_BOTTOMS,
	[PRODUCT_TYPE_SLEEP_WOMENS_DRESS_LOWER]: COLLECTION_SLEEP_WOMENS_DRESS,
	[PRODUCT_TYPE_SOCKS_ANKLE_LOWER]: 'heel-hammock-ankle-socks',
	[PRODUCT_TYPE_SOCKS_CREW_LOWER]: 'heel-hammock-crew-socks',
	[PRODUCT_TYPE_THONGS_LOWER]: COLLECTION_THONG,
	[PRODUCT_TYPE_THONGS_COOLING_LOWER]: 'cooling-thong-underwear-bralette',
	[PRODUCT_TYPE_THONGS_MODAL_LOWER]: COLLECTION_THONGS_MODAL,
	[PRODUCT_TYPE_WOMENS_BOXER_LOWER]: COLLECTION_WOMENS_BOXER,
	[PRODUCT_TYPE_LOUNGE_WOMENS_HOODIE_LOWER]: COLLECTION_WOMENS_LOUNGE_HOODIE,
	[PRODUCT_TYPE_LOUNGE_WOMENS_JOGGER_LOWER]: COLLECTION_WOMENS_LOUNGE_JOGGER,
	[PRODUCT_TYPE_SLEEP_WOMENS_SETS_LOWER]: COLLECTION_TYPE_SLEEP_WOMENS_SETS,
};

const validShopByPrintCollections = [
	COLLECTION_BIKINI,
	COLLECTION_BOXERS,
	COLLECTION_BOXERS_BRIEF,
	COLLECTION_BOXERS_COOLING_FLY,
	COLLECTION_BOXERS_FLY,
	COLLECTION_BOXERS_LONG,
	COLLECTION_BOXERS_TRUNK,
	COLLECTION_BOYSHORT,
	COLLECTION_BOYS_UNDERWEAR,
	COLLECTION_CHEEKY,
	COLLECTION_LONG_JOHN,
	COLLECTION_MENS_TSHIRTS,
	COLLECTION_THONG,
	COLLECTION_THONGS_MODAL,
	COLLECTION_WOMENS_BOXER,
];

const getSimilarPrints = async (product, print, size) => {
	const productType = product?.shopifyProductType || product?.productType;
	const productTypeLower = productType?.toLowerCase();

	const paramsArr = [];

	const invalidProductTypeForPrintFiltering = [
		PRODUCT_TYPE_LOUNGE_MENS_HOODIE_LOWER,
		PRODUCT_TYPE_LOUNGE_MENS_JOGGER_LOWER,
		PRODUCT_TYPE_LOUNGE_WOMENS_HOODIE_LOWER,
		PRODUCT_TYPE_LOUNGE_WOMENS_JOGGER_LOWER,
		PRODUCT_TYPE_SLEEP_MENS_BOTTOMS_LOWER,
		PRODUCT_TYPE_SLEEP_MENS_TOPS_LOWER,
		PRODUCT_TYPE_SLEEP_WOMENS_DRESS_LOWER,
		PRODUCT_TYPE_SLEEP_WOMENS_SETS_LOWER,
		"men's t-shirts",
	].includes(productTypeLower);

	if (print && !invalidProductTypeForPrintFiltering) {
		const formattedPrint = print?.includes('Solids') ? ' solid' : print?.replace('print:', '');
		formattedPrint?.replace('print:', '');
		paramsArr.push(`tags%3A%22print:${formattedPrint.toLowerCase()}%22`);
	}

	if (productTypeLower) {
		paramsArr.push(`shopifyProductType%3A%22${productTypeLower}%22`);
	}

	if (size) {
		paramsArr.push(`_availableSizes.ecommerce%3A%22${size}%22`);
	}

	const formattedParams = `?filters=${paramsArr.join(' AND ')}`;

	let res = await axios({
		method: 'get',
		url:
			`https://pipeline.shinesty.com/` +
			formattedParams +
			'&' +
			'facetFilters=%5B%22collections%3A' +
			(collections[productTypeLower] || 'guys-boxers') +
			'%22' +
			'%5D&hitsPerPage=61',
	});

	const validHits = res?.data?.hits?.reduce((memo, result) => {
		if (product.productType?.toLowerCase() === "men's shorts") {
			const length = product.handle.split('-shorts-');
			if (result.handle.includes(length && length.length > 0 ? length[1] : null)) {
				memo.push(result);
			}

			return memo;
		}

		if (result.shopifyProductType === productTypeLower) {
			memo.push(result);
		}

		return memo;
	}, []);

	return validHits || [];
};

const getCollection = async (collectionHandle) => {
	const res = await axios({
		method: 'get',
		url: `https://pipeline.shinesty.com/?facetFilters=%5B%22collections%3A${String(
			collectionHandle,
		)}%22%5D&hitsPerPage=40`,
	});
	const formatted = [];

	if (res && res.data.hits) {
		res.data.hits.map((hit) => {
			if (hit) {
				formatted.push(formatProductAlgolia(hit));
			}
		});

		return { facets: {}, hits: formatted, nbPages: 1 };
	}
};

const getRecommendedProducts = async (sourceProduct) => {
	const res = await axios({
		method: 'get',
		url: `https://pipeline.shinesty.com/?facetFilters=%5B%22shopifyProductType%3A${sourceProduct.productType}%22%5D`,
	});

	let filteredArray = [];
	res.data.hits.map((result) => {
		if (result.handle !== sourceProduct.handle) {
			filteredArray.push(result);
			result['id'] = parseGid(result.objectID);
		}
	});

	return filteredArray;
};

module.exports = {
	collections,
	getCollection,
	getRecommendedProducts,
	getSimilarPrints,
	validShopByPrintCollections,
};
