import { DiscountCodeConfig, SPECIAL_CONFIGS, getDiscountCodeConfigs } from './static-data';

export const findConfigForCode = (
	discountCode?: string,
	productType?: string,
): DiscountCodeConfig | undefined => {
	if (!discountCode || discountCode === '') {
		return;
	}

	if (Array.isArray(discountCode) && typeof discountCode[0] === 'string') {
		discountCode = discountCode[0];
	}

	const allConfigs = getDiscountCodeConfigs();

	const regularConfig = allConfigs.find((config) => {
		if (!discountCode || discountCode === '') {
			return false;
		}

		if (!productType) {
			return config.discountCode === discountCode.toLowerCase();
		}

		if (!config.productTypesAll) {
			return false;
		}

		return (
			config.productTypesAll.includes(productType.toLowerCase()) &&
			config.discountCode === discountCode.toLowerCase()
		);
	});

	if (regularConfig) {
		return regularConfig;
	}

	const specialConfig = SPECIAL_CONFIGS.find((config) => {
		if (discountCode && config.searchParams?.startsWith) {
			return discountCode.startsWith(config.searchParams.startsWith);
		}

		return false;
	});

	return specialConfig;
};
