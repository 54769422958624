import DesktopMenu from '/components/top-nav/DesktopMenu';
import LoadingBar from '/components/LoadingBar';
import MobileMenu from '/components/top-nav/MobileMenu';
import { observer } from 'mobx-react-lite';
import { useCartStore } from '/state/cart-context';

const TopNav = () => {
	const cart = useCartStore();

	return (
		<div className="fixed top-0 right-0 left-0 z-30">
			<DesktopMenu cart={cart} />
			<MobileMenu cart={cart} />
			<LoadingBar />
		</div>
	);
};

export default observer(TopNav);
