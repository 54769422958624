import KeyedComponent from './items/KeyedComponent';
import LinkIcon from './items/LinkIcon';
import LinkImage from './items/LinkImage';
import LinkPlain from './items/LinkPlain';
import PropTypes from 'prop-types';

const validItemTypes = {
	component: KeyedComponent,
	'image-link': LinkImage,
	'plain-link': LinkPlain,
	'icon-link': LinkIcon,
};

const MenuItem = ({ item, menuStyle, typographyVariant }) => {
	const Component = validItemTypes[item.itemType] || LinkPlain;
	return <Component item={item} menuStyle={menuStyle} typographyVariant={typographyVariant} />;
};

MenuItem.propTypes = {
	item: PropTypes.object,
	menuStyle: PropTypes.string,
	typographyVariant: PropTypes.string,
};

export default MenuItem;
