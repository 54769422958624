import sendEvent from './send-event';

import { user as userBlotout } from '@blotoutio/edgetag-sdk-js';

const trackLead = (email, phone) => {
	sendEvent('Lead');

	if (email) {
		userBlotout('email', email);
	}

	if (phone) {
		userBlotout('phone', phone);
	}
};

export default trackLead;
