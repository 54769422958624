import { tag } from '@blotoutio/edgetag-sdk-js';

import { CONSOLE_BACKGROUND, ENABLE_LOGGING } from './constants';
import { logEvent, logMessage } from '../helpers/logging';

const sendEvent = (eventName, payload) => {
	try {
		if (typeof payload === 'undefined') {
			tag(eventName);
		} else {
			tag(eventName, payload);
		}

		logEvent('Blotout', CONSOLE_BACKGROUND, ENABLE_LOGGING, eventName, { ...payload });
	} catch (e) {
		logMessage('Blotout', CONSOLE_BACKGROUND, ENABLE_LOGGING, eventName, e);
	}
};

export default sendEvent;
