import { ANNUAL, BUILD_A_PACK, PACK_PRODUCT, PREPAID_TYPE } from '/services/static/attribute-keys';

const getMultiProductDetails = (lineItems, currentPromotion) => {
	if (!currentPromotion || currentPromotion.promotionType !== 'multi-product') {
		return;
	}

	if (!lineItems || lineItems.length === 0) {
		return {
			emptyCart: true,
			quantityFreeQualified: 0,
			quantityNeeded: parseInt(currentPromotion.quantityQualifying),
		};
	}
	let prepaidSubCounted = false;

	let validProductsSummary = lineItems.reduce(
		(memo, lineItem) => {
			const isValidProductType = currentPromotion.productTypes.includes(
				lineItem?.merchandise?.product?.productType,
			);

			const isPackProduct = lineItem.attributes[PACK_PRODUCT] || lineItem.attributes[BUILD_A_PACK];
			const isSubscription = false;

			if (isValidProductType) {
				if (lineItem.attributes[PREPAID_TYPE] == 'Quarterly' || lineItem.attributes[ANNUAL]) {
					if (!prepaidSubCounted) {
						prepaidSubCounted = true;
						memo.countTotal += 1;
					}
				} else {
					memo.countTotal += lineItem.quantity || 1;
				}
				if (!isSubscription) {
					for (let q = 0; q < lineItem.quantity; q++) {
						memo.allPrices.push(parseFloat(lineItem.merchandise.priceV2.amount));
					}
				}

				if (!isSubscription && !isPackProduct && lineItem.linePrice === 0) {
					memo.countFree += lineItem.quantity || 1;
				} else {
					memo.countNotFree += lineItem.quantity || 1;
				}
			}

			return memo;
		},
		{ allPrices: [], countTotal: 0, countFree: 0, countNotFree: 0 },
	);

	/* the current quantity of free products */
	const quantityFreeQualified = Math.floor(
		validProductsSummary.countTotal /
			(parseInt(currentPromotion.quantityQualifying) + parseInt(currentPromotion.quantityFree)),
	);

	/* the quantity needed to get an additional free product */
	const quantityNeeded =
		parseInt(currentPromotion.quantityQualifying) +
		1 -
		(parseInt(validProductsSummary.countTotal) %
			(parseInt(currentPromotion.quantityQualifying) + 1));

	/* an array of prices that dictate which products are free */

	let pricesFree = validProductsSummary.allPrices.sort(function (a, b) {
		return a - b;
	});

	pricesFree = pricesFree.slice(0, quantityFreeQualified);

	return {
		emptyCart: false,
		pricesFree,
		quantityFreeAwarded: validProductsSummary.countFree,
		quantityFreeDelta: quantityFreeQualified - validProductsSummary.countFree,
		quantityFreeQualified,
		quantityNeeded,
	};
};

module.exports = {
	getMultiProductDetails,
};
