import { formatProducts } from './formatters';
import sendEvent from './send-event';

const trackAddToCart = (payload) => {
	const products = formatProducts(payload.ecommerce?.add?.products);

	if (!products) {
		return;
	}

	const formattedData = {
		contents: products.contents,
		content_type: 'product',
		currency: 'USD',
		eventId: payload.event_id,
		value: payload.ecommerce?.add?.actionField?.total,
	};

	sendEvent('AddToCart', formattedData);
};

export default trackAddToCart;
