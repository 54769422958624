import Clickable from '../../../../Clickable';
import PropTypes from 'prop-types';
import Typography from '../../../../Typography';

import classNames from '../../../../../utils/class-names';
import { getItemHref } from '../../../utils';

const config = {
	laundry: {
		standard: 'bg-laundry-sheets',
		hover: 'group-hover:bg-laundry-sheets-hover',
	},
	mens: {
		standard: 'bg-mens-underwear',
		hover: 'group-hover:bg-mens-underwear-hover',
	},
	womens: {
		standard: 'bg-womens-underwear',
		hover: 'group-hover:bg-womens-underwear-hover',
	},
	couples: {
		standard: 'bg-couples-underwear',
		hover: 'group-hover:bg-couples-underwear-hover',
	},
	socks: {
		standard: 'bg-socks',
		hover: 'group-hover:bg-socks-hover',
	},
};

const LinkIcon = ({ item }) => {
	const href = getItemHref(item);

	if (!item?.imageSetKey || !config[item.imageSetKey] || !href) {
		return null;
	}

	return (
		<div className="mt-20 mb-10 flex justify-center w-1/4">
			<Clickable
				linkToInternal={href}
				heapEventName="Navigation Link Click"
				className="group flex-col justify-center"
				heapEventData={{
					Href: href,
					Position: 'Subscription icons',
					'Nav Group': 'Subscription',
				}}>
				<span
					className={classNames(
						'mb-14 block w-[150px] h-[150px] bg-no-repeat bg-contain bg-center',
						config[item.imageSetKey].standard,
						config[item.imageSetKey].hover,
					)}
				/>
				<Typography
					variant="link-red"
					component="div"
					className="uppercase font-bold text-sm mb-2 text-center">
					{item.title}
				</Typography>
			</Clickable>
		</div>
	);
};

LinkIcon.propTypes = {
	className: PropTypes.string,
	item: PropTypes.object,
	menuStyle: PropTypes.string,
	navGroup: PropTypes.string,
	tier: PropTypes.string,
	typographyVariant: PropTypes.string,
};

export default LinkIcon;
