import { parseGid } from '/utils/graph-ql';

const formatProduct = (product) => {
	if (product.shopifyProductId) {
		product.id = parseGid(product.shopifyProductId);
	}

	if (product.objectID) {
		product.shinestyVariantId = product.objectID;
	}

	return product;
};

module.exports = {
	formatProduct,
};
