const getFirstProperty = (obj, listKey, propertyKey) => {
	if (!obj || !obj[listKey] || obj[listKey].length === 0) {
		return;
	}

	return propertyKey ? obj[listKey][0][propertyKey] : obj[listKey][0];
};

const getCurrentObjectFromKey = (obj, listKey, dateKey) => {
	if (!obj || !obj[listKey] || obj[listKey].length === 0) {
		return;
	}
	let latestObject = obj[listKey][0];
	const currentDate = new Date();
	obj[listKey].map((item) => {
		if (latestObject[dateKey] < item[dateKey] && latestObject[dateKey] < currentDate) {
			latestObject[dateKey] = item[dateKey];
			latestObject = item;
		}
	});
	return latestObject;
};

module.exports = {
	getCurrentObjectFromKey,
	getFirstProperty,
};
