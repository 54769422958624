import { checkProductTags } from '/utils/product-tags';
import { parseGid } from '/utils/graph-ql';

const init = () => {
	if (!window.wunderkindData) {
		window.wunderkindData = {};
	}
};

const setProperty = (propertyName, value) => {
	init();
	window.wunderkindData[propertyName] = value;
};

const updateWunderkindCartTracking = (checkoutUrl) => {
	setProperty('checkoutUrl', checkoutUrl);
};

const updateWunderkindCollectionTracking = (collectionHandle, products = []) => {
	const productIds = products.map((product) => {
		return parseGid(product?.shopifyProductId);
	});

	setProperty('collectionProductIds', productIds);
	setProperty(
		'collectionUrl',
		`${process.env.NEXT_PUBLIC_BASE_DOMAIN}/collections/${collectionHandle}`,
	);
};

const updateWunderkindProductSubscriptionTracking = (subscriptionProductId) => {
	setProperty('subscriptionProductId', subscriptionProductId);
};

const updateWunderkindProductTracking = (product) => {
	if (!product) {
		return;
	}

	setProperty('productTitle', product.name);
	setProperty('productId', product.shopifyProductId);
	setProperty('productImage', product.image);
	setProperty('productUrl', product.url);
	setProperty('productIsInstock', product.productInStock);
	setProperty('productIsExcluded', checkProductTags(product.tags, 'wunderkind_ignore'));
	setProperty('isSubscriptionPage', product.isSubscriptionPage);
};

const updateWunderkindVariantTracking = (variantId) => {
	setProperty('selectedVariantId', parseGid(variantId));
};

module.exports = {
	init,
	updateWunderkindCartTracking,
	updateWunderkindCollectionTracking,
	updateWunderkindProductSubscriptionTracking,
	updateWunderkindProductTracking,
	updateWunderkindVariantTracking,
};
