import Countdown from 'react-countdown';
import PropTypes from 'prop-types';

import { useEffect, useState } from 'react';

const ShinestyCountdown = ({ countdownDate }) => {
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		setIsLoaded(true);
	}, []);

	if (!isLoaded) {
		return null;
	}

	const renderer = ({ days, hours, minutes, seconds }) => {
		return (
			<span>
				{days} days {hours} hrs {minutes} mins {seconds} secs
			</span>
		);
	};

	return <Countdown date={new Date(countdownDate + 'T23:59:59')} renderer={renderer} />;
};

ShinestyCountdown.propTypes = {
	countdownDate: PropTypes.string,
};

export default ShinestyCountdown;
