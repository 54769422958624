export interface ProductSwap {
	code: string;
	productTypes: string[];
	subscriptionPrice?: number;
}

export interface DiscountCodeConfig {
	bannerHref?: string;
	bannerText?: string;
	bannerTextDefault?: string;
	bannerTextSecondary?: string;
	discountCode?: string;
	expiresDays: number;
	overwriteBlacklist?: string[];
	productTypesAll?: string[];
	productTypeSwaps?: ProductSwap[];
	searchParams?: {
		startsWith?: string;
	};
	subscriptionFrequencies?: string[];
	subscriptionPrice?: number;
}

const COUPLES_SWAPS: ProductSwap[] = [
	{ productTypes: ['mens', 'mens'], code: '10DolMatchingBoxerBoxerSub', subscriptionPrice: 5 },
	{ productTypes: ['mens', 'thongs'], code: '10DolMatchingBoxerThongSub', subscriptionPrice: 5 },
	{ productTypes: ['mens', 'womens'], code: '10DolMatchingBoxerWomenSub', subscriptionPrice: 5 },
	{
		productTypes: ['mens', "women's boxer"],
		code: '10DolMatchingBoxerWomensBoxerSub',
		subscriptionPrice: 5,
	},
	{ productTypes: ['thongs', 'thongs'], code: '10DolMatchingThongThongSub', subscriptionPrice: 5 },
	{ productTypes: ['thongs', 'womens'], code: '10DolMatchingThongWomensSub', subscriptionPrice: 5 },
	{
		productTypes: ['thongs', "women's boxer"],
		code: '10DolMatchingThongWomensBoxerSub',
		subscriptionPrice: 5,
	},
	{
		productTypes: ['womens', 'womens'],
		code: '10DolMatchingWomensWomensSub',
		subscriptionPrice: 5,
	},
	{
		productTypes: ['womens', "women's boxer"],
		code: '10DolMatchingWomensWomensBoxerSub',
		subscriptionPrice: 5,
	},
	{
		productTypes: ['womens - boxer', "women's - boxer"],
		code: '10DolMatchingWomensBoxerWomensBoxerSub',
		subscriptionPrice: 5,
	},
];

export const DEFAULT_DISCOUNT_CODE_CONFIG: DiscountCodeConfig = {
	expiresDays: 7,
};

const DEFAULT_BANNER_TEXT: string =
	'Use code [discountCode] and get your first pair for only [subscriptionPrice].';

const DEFAULT_BANNER_TEXT_DEFAULT: string =
	'Start a new subscription for [subscriptionPrice] today.';

const DEFAULT_BANNER_TEXT_SECONDARY: string =
	'Just for you: Try subscription undies for [subscriptionPrice].';

const DEFAULT_BANNER_HREF = '/pages/all-subscriptions';

const DISCOUNT_CODE_CONFIGS: DiscountCodeConfig[] = [
	{
		bannerHref: DEFAULT_BANNER_HREF,
		bannerText: 'Use code [discountCode] and get your first month for only [subscriptionPrice].',
		bannerTextDefault: DEFAULT_BANNER_TEXT_DEFAULT,
		discountCode: '5dollarlaundrysub',
		expiresDays: 21,
		productTypeSwaps: [{ productTypes: ['laundry detergent sheets'], code: '5dollarlaundrysub' }],
		subscriptionFrequencies: ['monthly', 'bimonthly', 'quarterly'],
		subscriptionPrice: 5,
	},
	{
		bannerHref: DEFAULT_BANNER_HREF,
		bannerText: DEFAULT_BANNER_TEXT,
		bannerTextDefault: DEFAULT_BANNER_TEXT_DEFAULT,
		bannerTextSecondary: DEFAULT_BANNER_TEXT_SECONDARY,
		discountCode: '5dollarsub',
		expiresDays: 21,
		productTypeSwaps: [
			{ productTypes: ['bikini'], code: '5DollarBikiniSubs' },
			{ productTypes: ['boxers'], code: '5DollarBoxerSubs' },
			{ productTypes: ['boxers - brief'], code: '5DollarBriefBoxerSubs' },
			{ productTypes: ['boxers - fly'], code: '5DollarBoxerSubs' },
			{ productTypes: ['boxers - long - fly'], code: '5DollarLongSubs' },
			{ productTypes: ['boxers - trunk'], code: '5DollarTrunkBoxerSubs' },
			{ productTypes: ['boys underwear'], code: '5Dollarboysboxerbriefsub' },
			{ productTypes: ['boyshort'], code: '5DollarBoyshortSubs' },
			{ productTypes: ['cheeky'], code: '5DollarCheekySubs' },
			{ productTypes: ['socks - ankle'], code: '5DollarSockSub' },
			{ productTypes: ['socks - crew'], code: '5DollarSockSub' },
			{ productTypes: ['thongs'], code: '5DollarThongSubs' },
			{ productTypes: ['thongs - modal'], code: '5DollarModalThongSubs' },
			{ productTypes: ["women's boxer"], code: '5DollarWomensBoxerSub' },
			...COUPLES_SWAPS,
		],
		subscriptionFrequencies: ['monthly'],
		subscriptionPrice: 5,
	},
	{
		bannerHref: DEFAULT_BANNER_HREF,
		bannerText: DEFAULT_BANNER_TEXT,
		bannerTextDefault: DEFAULT_BANNER_TEXT_DEFAULT,
		bannerTextSecondary: DEFAULT_BANNER_TEXT_SECONDARY,
		discountCode: '10dollarsub',
		expiresDays: 7,
		overwriteBlacklist: ['5dollarsub'], // cannot overwrite 5 dollar sub
		productTypeSwaps: [
			{ productTypes: ['bikini'], code: 'NiceHamWomensSubs' },
			{ productTypes: ['boxers'], code: 'NiceHamBoxerSubs' },
			{ productTypes: ['boxers - brief'], code: 'NiceHamBoxerSubs' },
			{ productTypes: ['boxers - fly'], code: 'NiceHamBoxerSubs' },
			{ productTypes: ['boxers - long - fly'], code: 'NiceHamBoxerSubs' },
			{ productTypes: ['boxers - trunk'], code: 'NiceHamBoxerSubs' },
			{ productTypes: ['boys underwear'], code: '10Dollarboysboxerbriefsub' },
			{ productTypes: ['boyshort'], code: 'NiceHamWomensSubs' },
			{ productTypes: ['cheeky'], code: 'NiceHamWomensSubs' },
			{ productTypes: ['socks - ankle'], code: '10DollarSockSub' },
			{ productTypes: ['socks - crew'], code: '10DollarSockSub' },
			{ productTypes: ['thongs'], code: 'NiceHamThongSubs' },
			{ productTypes: ['thongs - modal'], code: 'NiceHamModalThongSubs' },
			{ productTypes: ["women's boxer"], code: 'NiceHamWomensBoxerSub' },
			...COUPLES_SWAPS,
		],
		subscriptionFrequencies: ['monthly'],
		subscriptionPrice: 10,
	},
	{
		bannerHref: '/products/black-mens-tee',
		bannerText: 'Use code [discountCode] and get your first month for only [subscriptionPrice].',
		bannerTextDefault: DEFAULT_BANNER_TEXT_DEFAULT,
		bannerTextSecondary: 'Just for you: Try subscription tees for [subscriptionPrice].',
		discountCode: '10dollarteesub',
		expiresDays: 21,
		productTypeSwaps: [{ productTypes: ["men's t-shirts"], code: '10DollarTeeSub' }],
		subscriptionFrequencies: ['monthly', 'quarterly'],
		subscriptionPrice: 10,
	},
];

export const SPECIAL_CONFIGS: DiscountCodeConfig[] = [
	{
		bannerTextDefault: "Your exclusive Nift offer is ready. Shop now & we'll apply it at checkout.",
		expiresDays: 21,
		searchParams: {
			startsWith: 'nift',
		},
	},
];

export const getDiscountCodeConfigs = () => {
	return DISCOUNT_CODE_CONFIGS.map((discountCodeConfig) => {
		discountCodeConfig.productTypesAll =
			discountCodeConfig?.productTypeSwaps?.reduce((memo, productTypeSwap) => {
				productTypeSwap.productTypes?.forEach((productType) => {
					if (!memo.includes(productType)) {
						memo.push(productType);
					}
				});

				return memo;
			}, [] as string[]) || [];

		return discountCodeConfig;
	});
};
