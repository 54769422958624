import PropTypes from 'prop-types';
import React from 'react';

import { reactChildren } from '/utils/prop-types';

const SizeContext = React.createContext(null);

export const SizeProvider = ({ value, children }) => {
	return <SizeContext.Provider value={value}>{children}</SizeContext.Provider>;
};

export const useSizeStore = () => React.useContext(SizeContext);

SizeProvider.propTypes = {
	children: reactChildren,
	value: PropTypes.object,
};
