import Cookies from 'js-cookie';
import { DEFAULT_DISCOUNT_CODE_CONFIG } from '../services/discounts/static-data';
import { DateTime } from 'luxon';
import { findConfigForCode } from '../services/discounts/utils';
import { findSwapCode } from '../services/discounts/swap-codes';

// @ts-ignore
import { sortBy } from '@/utils/common-utils';

import { log, logJson } from '../services/discounts/logging';
import { makeAutoObservable, toJS } from 'mobx';

import {
	readLocalStorage,
	removeLocalStorageValue,
	writeLocalStorage,
} from '@/utils/local-storage';

interface ProcessedCart {
	processedLineItems?: {
		attributes?: {
			__isSubscriptionChild?: string;
			__subscriptionId?: string;
			'Matching UUID'?: string;
		};
		linePrice?: number;
		merchandise?: {
			product?: {
				productType: string;
			};
		};
	}[];
}

interface SubscriptionHashValue {
	productTypes: string[];
	value: number;
}

interface CurrentDiscountCode {
	code: string;
	expiresAt: string;
}

const RETIRED_LOCAL_STORAGE_DISCOUNT_CODE_KEYS: string[] = [
	'shinesty_discount_code',
	'utm_discount',
	'shinesty_subscription_discount',
];

const RETIRED_COOKIES_DISCOUNT_CODE_KEYS: string[] = ['discount_code', 'utm_discount'];

const LOCAL_STORAGE_KEY = 'shinesty-discount';

export class DiscountCodeStore {
	currentDiscountCode: CurrentDiscountCode | null = null;

	constructor() {
		makeAutoObservable(this);
	}

	checkExistingDiscountCode() {
		const newFormatCode = this.getCurrentDiscountCode();

		if (!newFormatCode) {
			log('No new format code was found either, no discount codes for user');
			return;
		}

		const isExpired = newFormatCode.expiresAt < DateTime.now().toJSON();

		// this is an issue from when marketing was using double utm_discount on the query string
		if (Array.isArray(newFormatCode.code)) {
			newFormatCode.code = newFormatCode.code[0];
		}

		logJson('Existing discount code found', newFormatCode);

		if (isExpired) {
			logJson('Existing discount is expired, removing', newFormatCode);

			// this.clearDiscountCode();

			return {
				expired: newFormatCode,
			};
		}

		return {
			valid: newFormatCode,
		};
	}

	clearDiscountCode() {
		removeLocalStorageValue(LOCAL_STORAGE_KEY);
		this.currentDiscountCode = null;
	}

	getCartDiscountCode(processedCart: ProcessedCart) {
		log('Getting cart discount code');
		const currentDiscountCode = this.getCurrentDiscountCode();

		// no discount code found, early exit
		if (!currentDiscountCode) {
			log('no discount code found, early exit');
			return;
		}

		const currentDiscountCodeConfig = this.getCurrentDiscountCodeConfig();

		// no special config found, early exit
		if (!currentDiscountCodeConfig?.productTypeSwaps) {
			log(
				`No product type swaps found for discount code, returning code - ${currentDiscountCode.code}`,
			);
			return currentDiscountCode.code;
		}

		log('Processing line items to find best swap match...');

		// we know there's a code, and we know it needs a swap... lets figure it out.
		const lineItems = processedCart?.processedLineItems || [];

		logJson('Line Items', lineItems);

		// we want to find the highest priced subscription in cart... taking into account matching as (1) subscription
		const subscriptionIdHash = lineItems.reduce(
			(memo: Record<string, SubscriptionHashValue>, lineItem) => {
				let subscriptionId;

				if (lineItem?.attributes) {
					subscriptionId = lineItem?.attributes['Matching UUID'];

					if (!subscriptionId) {
						subscriptionId = lineItem?.attributes?.__subscriptionId;
					}
				}

				const productType = lineItem?.merchandise?.product?.productType;

				if (
					!subscriptionId ||
					!productType ||
					lineItem?.attributes?.__isSubscriptionChild !== 'true'
				) {
					return memo;
				}

				// the id hasn't been in the loop yet, add it.
				if (!memo[subscriptionId]) {
					memo[subscriptionId] = {
						productTypes: [productType],
						value: lineItem.linePrice || 0,
					};
					return memo;
				}

				memo[subscriptionId].productTypes.push(productType);
				memo[subscriptionId].value += lineItem.linePrice || 0;

				return memo;
			},
			{},
		);

		const sortedSubscriptions = sortBy(Object.values(subscriptionIdHash), 'value', true);

		// there are no valid swaps for this code - return nothing as it won't apply to anyhting
		if (!lineItems || lineItems.length === 0) {
			return;
		}

		const relevantSubscription = sortedSubscriptions[0];

		if (!relevantSubscription?.productTypes) {
			return;
		}

		const swapCode = findSwapCode(currentDiscountCode.code, relevantSubscription.productTypes);

		return swapCode?.code;
	}

	getCurrentDiscountCodeConfig(productType?: string) {
		const discountCode = this.getCurrentDiscountCode();

		if (!discountCode || !discountCode.code) {
			return;
		}

		return findConfigForCode(discountCode.code, productType);
	}

	getCurrentDiscountCodeConfigAndSwap(productTypes?: string | string[]) {
		if (!productTypes) {
			return;
		}

		if (typeof productTypes === 'string') {
			productTypes = [productTypes];
		}

		const discountCodeConfig = this.getCurrentDiscountCodeConfig();

		if (!discountCodeConfig) {
			return;
		}

		const swap = findSwapCode(discountCodeConfig.discountCode, productTypes);

		return {
			discountCodeConfig,
			swap: swap?.code,
		};
	}

	getCurrentDiscountFirstMonthPrice(
		frequency: 'annually' | 'monthly' | 'quarterly',
		productTypes: string[],
		quantity?: number,
	) {
		const discountCodeConfig = this.getCurrentDiscountCodeConfig();

		if (!discountCodeConfig) {
			return;
		}

		if (!quantity) {
			quantity = 1;
		}

		if (!discountCodeConfig.subscriptionFrequencies?.includes(frequency)) {
			return;
		}

		const swap = findSwapCode(discountCodeConfig.discountCode, productTypes);

		// some swaps override the product price for the discount code
		// (i.e. 10dollarsub can have a single 10 dollarsub or two 5 dollar matching subs)
		if (swap && swap.subscriptionPrice) {
			return swap.subscriptionPrice * quantity;
		}

		if (
			discountCodeConfig.subscriptionPrice &&
			discountCodeConfig.subscriptionFrequencies?.includes(frequency)
		) {
			return discountCodeConfig.subscriptionPrice * quantity;
		}
	}

	handleOldFormatDiscountCodes(): string[] {
		log('Searching for Old Format Discount Codes');

		let existingDiscountCodes: string[] = [];

		RETIRED_LOCAL_STORAGE_DISCOUNT_CODE_KEYS.forEach((key) => {
			const discountCode = readLocalStorage(key);
			if (discountCode) {
				existingDiscountCodes.push(discountCode);
				log(`Found Old Format Discount Code in Local Storage ${key} - ${discountCode}`);
			}

			removeLocalStorageValue(key);
		});

		RETIRED_COOKIES_DISCOUNT_CODE_KEYS.forEach((key) => {
			const discountCode = Cookies.get(key);
			if (discountCode) {
				existingDiscountCodes.push(discountCode);
				log(`Found Old Format Discount Code in Cookies ${key} - ${discountCode}`);
			}

			Cookies.remove(key);
		});

		log(`Found ${existingDiscountCodes.length} old format codes.`);

		return existingDiscountCodes;
	}

	init(discountCode: string) {
		log('Initializing Discount Codes');

		// user has a new discount code on the querystring, use that to set
		// and clear out the old discount codes.
		if (discountCode) {
			log(`Discount Code Passed ${discountCode}`);
			this.setCurrentDiscountCode(discountCode);
			return;
		}

		const oldCodes = this.handleOldFormatDiscountCodes();

		// if there were, prefer the first one set (shrug) and set it with the new format
		if (oldCodes.length) {
			log(`Found an old code, we're going to update it - ${oldCodes[0]}`);
			this.setCurrentDiscountCode(oldCodes[0]);
			return;
		}

		this.checkExistingDiscountCode();
	}

	getCurrentDiscountCode() {
		if (this.currentDiscountCode) {
			return toJS(this.currentDiscountCode);
		}

		const newFormatCode = readLocalStorage(LOCAL_STORAGE_KEY);

		try {
			if (newFormatCode) {
				const parsed = JSON.parse(newFormatCode);
				this.currentDiscountCode = parsed;
				return parsed;
			}
		} catch {
			return;
		}
	}

	setCurrentDiscountCode(discountCode: string) {
		const oldCodes = this.handleOldFormatDiscountCodes();

		if (oldCodes.length > 0) {
			log(`Found and removed ${oldCodes.length} old format code(s)`);
		}

		// no discount code was passed, lets check to see the validity of the existing code
		if (!discountCode || discountCode === '') {
			log('No discount code passed on query string, checking existing codes');
			this.checkExistingDiscountCode();
			return;
		}

		// there was a discount code passed, lets see if there's a custom config
		let discountCodeConfig = findConfigForCode(discountCode);

		// there was no custom config, lets use the default
		if (!discountCodeConfig) {
			discountCodeConfig = DEFAULT_DISCOUNT_CODE_CONFIG;
		}

		const existingDiscountCode = this.getCurrentDiscountCode();

		if (existingDiscountCode) {
			logJson(`Existing Discount Code Found`, existingDiscountCode);
		}

		if (
			existingDiscountCode &&
			discountCodeConfig.overwriteBlacklist?.includes(existingDiscountCode.code)
		) {
			log(
				`New Discount Code (${discountCode}) was passed, but it can't override ${existingDiscountCode.code}.`,
			);
			return;
		}

		// this is what we'll save in local storage. since you know the code is fresh, you can just fire away
		const json: CurrentDiscountCode = {
			code: discountCode,
			expiresAt: DateTime.now().plus({ days: discountCodeConfig.expiresDays }).toJSON(),
		};

		logJson(`Setting Discount Code`, json);

		writeLocalStorage(LOCAL_STORAGE_KEY, JSON.stringify(json));

		this.currentDiscountCode = json;
	}

	plain() {
		return toJS(this);
	}
}
