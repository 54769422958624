import Badge from '/components/Badge';
import Button from '/components/Button';
import { IS_HIDDEN } from '/services/static/attribute-keys';
import Icon from '/components/IconUpdated';
import PropTypes from 'prop-types';
import classNames from '/utils/class-names.js';
import { logTryCatch } from '/utils/logging';
import { observer } from 'mobx-react-lite';
import { useCartStore } from '/state/cart-context';
import { useMemo } from 'react';

function CartNavButton({ variant }) {
	const cart = useCartStore();

	const computeItemCount = (cart) => {
		const count =
			cart.shopifyCart.id &&
			cart.shopifyCart.lines.edges.reduce((sum, l) => {
				if (l.node.attributes) {
					const hiddenAttribute = l.node.attributes.find((attribute) => {
						return attribute.key === IS_HIDDEN;
					});

					if (hiddenAttribute && hiddenAttribute.value === 'true') {
						return sum;
					}
				}
				const isShippingProtection = l?.node?.merchandise?.product?.title?.includes('Shipping');
				if (isShippingProtection) {
					return sum;
				}
				try {
					sum += parseInt(l.node.quantity);
				} catch (e) {
					logTryCatch('could not parse quantity on line item.');
				}

				return sum;
			}, 0);
		return count;
	};
	const itemCount = useMemo(() => computeItemCount(cart), [cart.shopifyCart.lines]);

	const top = variant === 'desktop' ? 'top-0' : 'top-2';

	return (
		<Button
			title="Open Cart"
			className="block flex-none"
			variant="naked"
			size="sm"
			onClick={() => cart.setDrawerOpen(true)}>
			<Icon name="cart" height="24px" width="24px" />
			{cart.shopifyCart.id && cart.shopifyCart.lines.edges.length > 0 && (
				<Badge className={classNames('absolute', top)} text={itemCount} variant="cart" />
			)}
		</Button>
	);
}

CartNavButton.propTypes = {
	variant: PropTypes.string,
};

export default observer(CartNavButton);
