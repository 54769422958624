import Cookies from 'js-cookie';

// if we update these, make sure to update analytics-platform to ingest the data
const VALID_ELEVAR_COOKIES = ['_ga', '_ga_Q3WCRJXF7E', '_ga_99C5J4EW70', '_fbc', '_fbp'];

const getElevarCheckoutData = (utmStore) => {
	const attributes = [];
	const referrer = utmStore.getReferrer();

	// Paremeters that were passed on the querystring need passed to the order
	const attributionInformation = { referrer: referrer || '', ...utmStore.getUtmParams('elevar') };

	attributes.push({
		key: '_elevar_visitor_info',
		value: JSON.stringify(attributionInformation),
	});

	const cookies = getElevarCookies();

	// get the elevar cookies and append them to the order
	Object.keys(cookies).forEach((key) => {
		attributes.push({
			key: `_elevar_${key}`,
			value: cookies[key],
		});
	});

	return attributes;
};

const getElevarCookies = () => {
	return VALID_ELEVAR_COOKIES.reduce((memo, key) => {
		const cookieValue = Cookies.get(key);

		if (cookieValue) {
			memo[key] = cookieValue;
		}

		return memo;
	}, {});
};

module.exports = {
	getElevarCheckoutData,
	getElevarCookies,
};
