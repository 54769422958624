import IconUpdated from '/components/IconUpdated';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const Stars = ({ rating, totalStars = 5, variant = 'normal' }) => {
	const [stars, setStars] = useState([]);
	useEffect(() => {
		calculateStars();
	}, [rating]);

	const styles = {
		large: {
			className: 'justify-center',
			dimension: '24px',
		},
		normal: {
			className: 'first-of-type:ml-0 m-0.5',
			dimension: '18px',
		},
		small: {
			className: '',
			dimension: '11px',
		},
	};

	const calculateStars = () => {
		const calculated = [];
		let fullStars = Math.floor(rating);
		const starDecimal = rating - fullStars;
		let showThirdStar = starDecimal > 0 && starDecimal < 0.333;
		let showTwoThird = starDecimal >= 0.333 && starDecimal < 0.8;
		if (starDecimal >= 0.8) {
			fullStars++;
		}

		for (let i = 0; i < fullStars; i++) {
			calculated.push('starFull');
		}

		if (fullStars < totalStars) {
			for (let j = 0; j < totalStars - fullStars; j++) {
				if (showThirdStar) {
					calculated.push('starThird');
					showThirdStar = false;
				} else if (showTwoThird) {
					calculated.push('starTwoThird');
					showTwoThird = false;
				} else {
					calculated.push('starEmpty');
				}
			}
		}
		setStars(calculated);
	};

	return (
		<div className="flex items-center">
			{stars.map((type, key) => {
				return (
					<IconUpdated
						className={styles[variant].className}
						key={key}
						name={type}
						height={styles[variant].dimension}
						width={styles[variant].dimension}
					/>
				);
			})}
		</div>
	);
};

Stars.propTypes = {
	rating: PropTypes.number,
	totalStars: PropTypes.number,
	variant: PropTypes.string,
};

export default Stars;
