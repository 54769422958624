import { CURRENCY } from './constants';
import sendEvent from './send-event';
import { v4 as uuidv4 } from 'uuid';

const trackInitiateCheckout = (cart) => {
	const formattedData = {
		eventId: uuidv4(),
		currency: CURRENCY,
		value: cart.subTotal,
	};

	sendEvent('InitiateCheckout', formattedData);
};

export default trackInitiateCheckout;
