// import Algolia from '/services/analytics/algolia.js';
import HeapService from '/services/analytics/heap';
import PropTypes from 'prop-types';
import React from 'react';
import { trackProductClick } from '/services/analytics/shinesty-analytics';

export const GoogleEventPropTypes = {
	googleEventData: PropTypes.object,
	googleEventName: PropTypes.string,
};

export const HeapEventPropTypes = {
	heapEventData: PropTypes.object,
	heapEventName: PropTypes.string,
};

const withAnalytics = (WrappedComponent) => {
	const WithAnalytics = (props) => {
		const handleTracking = () => {
			if (props.heapEventName) {
				HeapService.trackEvent(props.heapEventName, props.heapEventData);
			}

			if (props.googleEventName === 'trackProductClick') {
				trackProductClick(props.googleEventData);
			}
		};

		// remove the analytics events -- have to disable unused vars since we dont use these anywhere.
		/* eslint-disable no-unused-vars */
		const {
			forwardedRef,
			googleEventData,
			googleEventName,
			heapEventData,
			heapEventName,
			...rest
		} = props;
		/* eslint-enable no-unused-vars */

		return (
			<WrappedComponent handleTracking={handleTracking} ref={forwardedRef} {...rest}>
				{props.children}
			</WrappedComponent>
		);
	};

	WithAnalytics.propTypes = {
		...HeapEventPropTypes,
		...GoogleEventPropTypes,
		...WrappedComponent.propTypes,
	};

	WithAnalytics.displayName = `WithAnalytics(${getDisplayName(WrappedComponent)})`;

	function getDisplayName(WrappedComponent) {
		return WrappedComponent.displayName || WrappedComponent.name || 'Component';
	}

	return React.forwardRef((props, ref) => {
		return <WithAnalytics {...props} forwardedRef={ref} />;
	});
};

export default withAnalytics;
