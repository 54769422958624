import Icon from '/components/IconUpdated';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { useCartStore } from '../state/cart-context';
import { useGlobalStore } from '../state/global-context';

import { getCountries, getCountryByCode } from '../services/static/countries';
import { useEffect, useState } from 'react';

const CountrySelector = ({ className = '' }) => {
	const globalStore = useGlobalStore();
	const cartStore = useCartStore();

	const countries = getCountries();
	const storedCountry = globalStore.getCurrentCountry();
	const [currentCountry, setCurrentCountry] = useState(storedCountry);

	const updateCountry = (countryCode) => {
		const newCountry = getCountryByCode(countryCode);
		setCurrentCountry(newCountry);
		globalStore.setCurrentCountryByCode(countryCode, cartStore);
	};

	useEffect(() => {
		//doesnt work to set state on a proxy object
		setCurrentCountry(toJS(storedCountry));
	}, [storedCountry]);

	return (
		<form className={className}>
			<div className="flex">
				<label htmlFor="desktop-currency" className="sr-only">
					Currency
				</label>
				<div className="group relative -ml-2 rounded-full border-transparent bg-gray-900 focus-within:ring-2 focus-within:ring-white flex pr-3">
					<select
						value={currentCountry?.countryCode || 'US'}
						id="desktop-currency"
						name="currency"
						className="flex items-center rounded-full border-transparent bg-gray-900 bg-none py-0.5 pl-3 pr-2 text-sm font-medium text-white focus:border-transparent focus:outline-none focus:ring-0 group-hover:text-gray-100 appearance-none"
						onChange={(e) => {
							updateCountry(e.currentTarget.value);
						}}>
						{countries.map((country, countryIndex) => {
							return (
								<option key={countryIndex} value={country.countryCode}>
									{country.countryCode}
								</option>
							);
						})}
					</select>

					<div className="pointer-events-none flex items-center">
						<Icon name="caretDownWhite" height="12px" width="12px" />
					</div>
				</div>
			</div>
		</form>
	);
};

CountrySelector.propTypes = {
	className: PropTypes.string,
};

export default observer(CountrySelector);
