import { BuilderConfig, PackStyle, PrintType } from '@/services/static/packs/types';
import {
	PRODUCT_TYPE_BIKINI,
	PRODUCT_TYPE_BOXERS,
	PRODUCT_TYPE_BOXERS_BRIEF,
	PRODUCT_TYPE_BOXERS_COOLING,
	PRODUCT_TYPE_BOXERS_FLY,
	PRODUCT_TYPE_BOXERS_LONG_FLY,
	PRODUCT_TYPE_BOXERS_LOOSE,
	PRODUCT_TYPE_BOXERS_TRUNK,
	PRODUCT_TYPE_BOYSHORT,
	PRODUCT_TYPE_BOYS_UNDERWEAR,
	PRODUCT_TYPE_CHEEKY,
	PRODUCT_TYPE_SOCKS_ANKLE,
	PRODUCT_TYPE_SOCKS_CREW,
	PRODUCT_TYPE_THONGS,
	PRODUCT_TYPE_THONGS_MODAL,
	PRODUCT_TYPE_WOMENS_BOXER,
} from '../product-types';
import { SIZES_BOYS, SIZES_MENS, SIZES_SOCKS, SIZES_WOMENS } from '@/services/static/sizes';

const VALID_SITEWIDE_PACK_TYPES: string[] = [
	PRODUCT_TYPE_BIKINI,
	PRODUCT_TYPE_BOXERS,
	PRODUCT_TYPE_BOXERS_BRIEF,
	PRODUCT_TYPE_BOXERS_COOLING,
	PRODUCT_TYPE_BOXERS_FLY,
	PRODUCT_TYPE_BOXERS_LONG_FLY,
	PRODUCT_TYPE_BOXERS_LOOSE,
	PRODUCT_TYPE_BOXERS_TRUNK,
	PRODUCT_TYPE_BOYSHORT,
	PRODUCT_TYPE_BOYS_UNDERWEAR,
	PRODUCT_TYPE_CHEEKY,
	PRODUCT_TYPE_SOCKS_ANKLE,
	PRODUCT_TYPE_SOCKS_CREW,
	PRODUCT_TYPE_THONGS,
	PRODUCT_TYPE_THONGS_MODAL,
	PRODUCT_TYPE_WOMENS_BOXER,
];

const MENS_PRINT_TYPES: PrintType[] = [
	{ label: 'Solids', key: 'Solids' },
	{ label: 'Apex', key: 'Apex' },
	{ isDefault: true, label: 'Patterned', key: 'Patterned' },
];

const WOMENS_PRINT_TYPES: PrintType[] = [
	{ label: 'Solids', key: 'Solids' },
	{ isDefault: true, label: 'Patterned', key: 'Patterned' },
];

const MENS_PACK_STYLES: PackStyle = {
	id: 'mens',
	label: "Men's",
	productTypes: [
		{
			altText: 'Flyless Boxer Bries',
			collection: 'singles-ball-hammock-pouch-underwear',
			details: '6" inseam',
			id: 'standard',
			img: 'https://cdn.shinesty.com/2022-06-22/standard-line.svg',
			name: 'Flyless Boxer Briefs',
			printTypes: MENS_PRINT_TYPES,
			productType: PRODUCT_TYPE_BOXERS,
			selectedImg: 'https://cdn.shinesty.com/2022-06-22/standard-filled.svg',
			sizes: SIZES_MENS,
			styleNumber: '10071',
		},
		{
			altText: 'Boxer brief with fly',
			collection: 'mens-ball-hammock-pouch-underwear-with-fly',
			details: '6" inseam',
			id: 'fly',
			img: 'https://cdn.shinesty.com/2022-06-22/with-fly-line.svg',
			name: 'Boxer Briefs, Fly',
			printTypes: MENS_PRINT_TYPES,
			productType: PRODUCT_TYPE_BOXERS_FLY,
			selectedImg: 'https://cdn.shinesty.com/2022-06-22/with-fly-filled.svg',
			sizes: SIZES_MENS,
			styleNumber: '10071',
		},
		{
			altText: 'Cooling boxer with fly',
			collection: 'ball-hammock-pouch-cooling-underwear',
			details: '6" inseam w/ fly',
			id: 'cooling',
			img: 'https://cdn.shinesty.com/2022-06-22/cooling-line.svg',
			name: 'Cooling Boxer Briefs',
			printTypes: MENS_PRINT_TYPES,
			productType: PRODUCT_TYPE_BOXERS_COOLING,
			selectedImg: 'https://cdn.shinesty.com/2022-06-22/cooling-filled.svg',
			sizes: SIZES_MENS,
			styleNumber: '10071',
		},
		{
			altText: 'Long leg boxer briefs',
			collection: 'mens-long-leg-boxer-briefs',
			details: '8" inseam w/ fly',
			id: 'long',
			img: 'https://cdn.shinesty.com/2022-06-22/long-line.svg',
			name: 'Long Boxer Briefs',
			printTypes: MENS_PRINT_TYPES,
			productType: PRODUCT_TYPE_BOXERS_LONG_FLY,
			selectedImg: 'https://cdn.shinesty.com/2022-06-22/long-filled.svg',
			sizes: SIZES_MENS,
			styleNumber: '10071',
		},
		{
			altText: 'Briefs style underwear',
			collection: 'ball-hammock-briefs',
			details: 'w/ fly',
			id: 'brief',
			img: 'https://cdn.shinesty.com/2022-06-22/brief-line.svg',
			name: 'Briefs',
			printTypes: MENS_PRINT_TYPES,
			productType: PRODUCT_TYPE_BOXERS_BRIEF,
			selectedImg: 'https://cdn.shinesty.com/2022-06-22/brief-filled.svg',
			sizes: SIZES_MENS,
			styleNumber: '10071',
		},
		{
			altText: 'Trunk style underwear',
			collection: 'ball-hammock-trunk-underwear',
			details: '3.5" inseam w/ fly',
			id: 'trunk',
			img: 'https://cdn.shinesty.com/2022-06-22/trunks-line.svg',
			name: 'Trunks',
			printTypes: MENS_PRINT_TYPES,
			productType: PRODUCT_TYPE_BOXERS_TRUNK,
			selectedImg: 'https://cdn.shinesty.com/2022-06-22/trunks-filled.svg',
			sizes: SIZES_MENS,
			styleNumber: '10071',
		},
		{
			altText: 'Pouchless mens boxer',
			collection: 'pouchless-mens-boxers',
			details: '5" inseam',
			id: 'pouchless',
			img: 'https://cdn.shinesty.com/2024-11-08/boxer-loose-line.svg',
			name: 'Boxers',
			printTypes: MENS_PRINT_TYPES,
			productType: PRODUCT_TYPE_BOXERS_LOOSE,
			selectedImg: 'https://cdn.shinesty.com/2024-11-08/boxer-loose-filled.svg',
			sizes: SIZES_MENS,
			styleNumber: '10423',
		},
		{
			altText: 'Boys Underwear',
			collection: 'boys-underwear',
			details: 'Flyless',
			id: 'boys-underwear',
			img: 'https://cdn.shinesty.com/2024-11-08/boys-outline.svg',
			name: 'Boys Boxer Briefs',
			printTypes: MENS_PRINT_TYPES,
			productType: PRODUCT_TYPE_BOYS_UNDERWEAR,
			selectedImg: 'https://cdn.shinesty.com/2024-11-08/boys-solid.svg',
			sizes: SIZES_BOYS,
			styleNumber: '10428',
		},
	],
};

const WOMENS_PACK_STYLES: PackStyle = {
	id: 'womens',
	label: "Women's",
	productTypes: [
		{
			altText: 'Bikini',
			collection: 'womens-bikini-underwear',
			id: 'bikini',
			img: 'https://cdn.shinesty.com/2022-06-22/bikini-line.svg',
			name: 'Bikini',
			printTypes: WOMENS_PRINT_TYPES,
			productType: PRODUCT_TYPE_BIKINI,
			selectedImg: 'https://cdn.shinesty.com/2022-06-22/bikini-filled.svg',
			sizes: SIZES_WOMENS,
			styleNumber: '10096',
		},
		{
			altText: 'Boyshort',
			collection: 'womens-boyshort-underwear',
			id: 'boy',
			img: 'https://cdn.shinesty.com/2022-06-22/boyshort-line.svg',
			name: 'Boyshort',
			printTypes: WOMENS_PRINT_TYPES,
			productType: PRODUCT_TYPE_BOYSHORT,
			selectedImg: 'https://cdn.shinesty.com/2022-06-22/boyshort-filled.svg',
			sizes: SIZES_WOMENS,
			styleNumber: '10096',
		},
		{
			altText: 'Cheeky',
			collection: 'cheeky-underwear',
			id: 'cheeky',
			img: 'https://cdn.shinesty.com/2022-06-22/cheeky-line.svg',
			name: 'Cheeky',
			printTypes: WOMENS_PRINT_TYPES,
			productType: PRODUCT_TYPE_CHEEKY,
			selectedImg: 'https://cdn.shinesty.com/2022-06-22/cheeky-filled.svg',
			sizes: SIZES_WOMENS,
			styleNumber: '10096',
		},
		{
			altText: 'Seamless Thong',
			collection: 'womens-thong-underwear',
			id: 'seamless',
			img: 'https://cdn.shinesty.com/2022-06-22/thong-line.svg',
			name: 'Seamless Thong',
			printTypes: WOMENS_PRINT_TYPES,
			productType: PRODUCT_TYPE_THONGS,
			selectedImg: 'https://cdn.shinesty.com/2022-06-22/thong-filled.svg',
			sizes: SIZES_WOMENS,
			styleNumber: '10096',
		},
		{
			altText: "Women's Boxer",
			collection: 'womens-boxer-briefs',
			id: 'womens-boxer',
			img: 'https://cdn.shinesty.com/2024-04-03/underwearWBoxerOutline.svg',
			name: "Women's Boxer",
			printTypes: WOMENS_PRINT_TYPES,
			productType: PRODUCT_TYPE_WOMENS_BOXER,
			selectedImg: 'https://cdn.shinesty.com/2024-04-03/underwearWBoxerSolid.svg',
			sizes: SIZES_WOMENS,
			styleNumber: '10263',
		},
		{
			altText: 'Thongs - Modal',
			collection: 'modal-thong-underwear-for-women',
			id: 'thongs-modal',
			img: 'https://cdn.shinesty.com/2022-06-22/thong-line.svg',
			name: 'Modal Thong',
			printTypes: WOMENS_PRINT_TYPES,
			productType: PRODUCT_TYPE_THONGS_MODAL,
			selectedImg: 'https://cdn.shinesty.com/2022-06-22/thong-filled.svg',
			sizes: SIZES_WOMENS,
			styleNumber: '10398',
		},
	],
};

const SOCK_PACK_STYLES: PackStyle = {
	id: 'socks',
	sizeRenames: {
		[PRODUCT_TYPE_SOCKS_ANKLE]: {
			M: 'Men: 6-8.5 Women: 5-10',
			L: 'Men: 9-13 Women: 10-13',
		},
		[PRODUCT_TYPE_SOCKS_CREW]: {
			M: 'Men: 6-8.5 Women: 5-10',
			L: 'Men: 9-13 Women: 10-13',
		},
	},
	productTypes: [
		{
			altText: 'Ankle Socks',
			collection: 'heel-hammock-ankle-socks',
			details: 'Low Show',
			id: 'ankle',
			img: 'https://cdn.shinesty.com/2023-11-27/ankle-outline.svg',
			name: 'Ankle Socks',
			productType: PRODUCT_TYPE_SOCKS_ANKLE,
			selectedImg: 'https://cdn.shinesty.com/2023-11-27/ankle-filled.svg',
			sizes: SIZES_SOCKS,
			styleNumber: '10402',
		},
		{
			altText: 'Crew Socks',
			collection: 'heel-hammock-crew-socks',
			details: 'Mid Rise',
			id: 'crew',
			img: 'https://cdn.shinesty.com/2023-11-27/crew-outline.svg',
			name: 'Crew Socks',
			productType: PRODUCT_TYPE_SOCKS_CREW,
			selectedImg: 'https://cdn.shinesty.com/2023-11-27/crew-filled.svg',
			sizes: SIZES_SOCKS,
			styleNumber: '10377',
		},
	],
};

const DEFAULT_PACK: Partial<BuilderConfig> = {
	builderType: 'underwear',
	defaultStyleIndex: 0,
	hidePerPairPrice: false,
	isMixedPackBuilder: false,
	isSpecialPack: false,
	productConfigs: {
		quantity: 1,
	},
	productCountMin: 1,
	showFullTitle: false,
	sizeDescription: "What's your size?",
	useSizeStep: true,
};

const DEFAULT_SPECIAL_PACK: Partial<BuilderConfig> = {
	...DEFAULT_PACK,
	extraItems: [
		{
			handle: 'happy-nuts-comfort-cream-sweat-defense-and-odor-control-lotion',
			quantity: 1,
			priceFixed: 8.99,
		},
	],
	hidePerPairPrice: true,
	isSpecialPack: true,
	productCountMin: 3,
	productCountMax: 3,
	showFullTitle: true,
};

const REVIEWS_PRODUCT_MENS: string = '6545148510277';
const REVIEWS_PRODUCT_WOMENS: string = '4391117586501';

const BUILD_A_PACK_DATA: { [key: string]: BuilderConfig } = {
	'build-a-womens-underwear-3-pack-1': {
		...DEFAULT_SPECIAL_PACK,
		box: {
			handle: 'build-a-womens-underwear-3-pack',
		},
		extraItems: [],
		id: 'build-a-womens-underwear-3-pack',
		reviewsProduct: REVIEWS_PRODUCT_WOMENS,
		styles: [WOMENS_PACK_STYLES],
		subtitle: "Build a Women's Underwear Pack + Christmas Box",
		title: 'The Goodie Box',
	},
	couples: {
		...DEFAULT_PACK,
		hidePerPairPrice: true,
		id: 'couples',
		isMixedPackBuilder: true,
		productConfigs: {
			quantity: 2,
			titles: ['I Wear', 'My Partner Wears'],
		},
		reviewsProduct: REVIEWS_PRODUCT_MENS,
		styles: [MENS_PACK_STYLES, WOMENS_PACK_STYLES],
		title: "Build a Couple's Underwear Pack",
		useSizeStep: false,
	},
	'fathers-day-build-a-ball-hammock-3-pack-happy-nuts-boxer-pack': {
		...DEFAULT_SPECIAL_PACK,
		box: {
			handle: 'fathers-day-build-a-ball-hammock-3-pack-happy-nuts-boxer-packaging',
		},
		id: 'fathers-day-build-a-ball-hammock-3-pack-happy-nuts-boxer-pack',
		reviewsProduct: REVIEWS_PRODUCT_MENS,
		styles: [MENS_PACK_STYLES],
		subtitle: "Father's Day Build a Ball Hammock® 3 Pack + Happy Nuts",
		title: 'The Man Of The House',
	},
	mens: {
		...DEFAULT_PACK,
		id: 'mens',
		reviewsProduct: REVIEWS_PRODUCT_MENS,
		styles: [MENS_PACK_STYLES, WOMENS_PACK_STYLES],
		title: 'Ball Hammock® Pouch Underwear Pack Builder',
	},
	socks: {
		...DEFAULT_PACK,
		builderType: 'socks',
		id: 'socks',
		productCountMin: 3,
		reviewsProduct: '6911699025989',
		sizeDescription: "What's your shoe size?",
		styles: [SOCK_PACK_STYLES],
		title: 'Build a Sock Pack',
	},
	'the-happy-holidays-holiday-2023-build-a-mens-underwear-3-pack': {
		...DEFAULT_SPECIAL_PACK,
		box: {
			handle: 'holiday-2023-mens-underwear-3-pack',
		},
		id: 'the-happy-holidays-holiday-2023-build-a-mens-underwear-3-pack',
		reviewsProduct: REVIEWS_PRODUCT_MENS,
		styles: [MENS_PACK_STYLES],
		subtitle: "Holiday 2023 Build A Men's Underwear 3 Pack",
		title: 'The Happy Holidays',
	},
	'the-happy-holidays-holiday-2023-build-a-womens-underwear-3-pack': {
		...DEFAULT_SPECIAL_PACK,
		box: {
			handle: 'holiday-2023-womens-underwear-3-pack',
		},
		extraItems: [],
		id: 'the-happy-holidays-holiday-2023-build-a-womens-underwear-3-pack',
		reviewsProduct: REVIEWS_PRODUCT_WOMENS,
		styles: [WOMENS_PACK_STYLES],
		subtitle: "Holiday 2023 Build A Women's Underwear 3 Pack",
		title: 'The Happy Holidays',
	},
	'the-holiday-package-build-a-ball-hammock-3-pack-happy-nuts': {
		...DEFAULT_SPECIAL_PACK,
		box: {
			handle: '2021-holiday-boxer-packaging',
		},
		id: 'the-holiday-package-build-a-ball-hammock-3-pack-happy-nuts',
		reviewsProduct: REVIEWS_PRODUCT_MENS,
		styles: [MENS_PACK_STYLES],
		subtitle: 'Build a Ball Hammock® 3 Pack + Happy Nuts',
		title: 'The Ultimate Holiday Package',
	},
	'the-tourist-build-a-mens-underwear-3-pack': {
		...DEFAULT_SPECIAL_PACK,
		box: {
			handle: 'build-a-3-pack-mens-underwear-3-pack',
		},
		id: 'the-tourist-build-a-mens-underwear-3-pack',
		reviewsProduct: REVIEWS_PRODUCT_MENS,
		styles: [MENS_PACK_STYLES],
		subtitle: "Build A Men's Underwear 3 Pack",
		title: 'The Tourist',
	},
	'the-tourist-build-a-womens-underwear-3-pack': {
		...DEFAULT_SPECIAL_PACK,
		box: {
			handle: 'build-a-3-pack-womens-underwear-3-pack',
		},
		extraItems: [],
		id: 'the-tourist-build-a-womens-underwear-3-pack',
		reviewsProduct: REVIEWS_PRODUCT_WOMENS,
		styles: [WOMENS_PACK_STYLES],
		subtitle: "Build A Women's Underwear 3 Pack",
		title: 'The Tourist',
	},
	womens: {
		...DEFAULT_PACK,
		defaultStyleIndex: 1,
		id: 'womens',
		isMixedPackBuilder: false,
		isSpecialPack: false,
		reviewsProduct: REVIEWS_PRODUCT_WOMENS,
		showFullTitle: true,
		styles: [MENS_PACK_STYLES, WOMENS_PACK_STYLES],
		title: "Women's Underwear Pack Builder",
	},
};

module.exports = {
	BUILD_A_PACK_DATA,
	VALID_SITEWIDE_PACK_TYPES,
};
