import { CONFIG_MONEY_BACK } from './configs/config-money-back';

const getEstimatedMoneyBack = (subtotal) => {
	if (subtotal === 0) {
		return {
			estimate: 0.0,
			nextTier: CONFIG_MONEY_BACK.tiers[0],
			nextTierPriceDifference: CONFIG_MONEY_BACK.tiers[0].priceMin,
		};
	}

	let tier;
	let nextTier = CONFIG_MONEY_BACK.tiers[0];

	for (let i = 0; i < CONFIG_MONEY_BACK.tiers.length; i++) {
		if (CONFIG_MONEY_BACK.tiers[i].priceMin <= subtotal) {
			tier = CONFIG_MONEY_BACK.tiers[i];
			nextTier = CONFIG_MONEY_BACK.tiers[i + 1];
		}
	}

	let nextTierPriceDifference;

	if (nextTier) {
		nextTierPriceDifference = nextTier.priceMin - subtotal;
	}

	return {
		estimate: (tier?.moneyBack || 0) * subtotal,
		nextTier,
		nextTierPriceDifference,
		tier,
	};
};

module.exports = {
	getEstimatedMoneyBack,
};
