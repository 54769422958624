import { formatProductsAxon } from './formatters';
import sendEvent from './send-event';
const trackAddToCart = (lines) => {
	try {
		const formattedLines = formatProductsAxon(lines);

		sendEvent('add_to_cart', {
			currency: 'usd',
			items: formattedLines,
		});
	} catch (e) {
		console.log(e);
	}
};
export default trackAddToCart;
