import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const InputGroup = forwardRef(
	(
		{
			adaTitle,
			className = '',
			label = '',
			name = '',
			full,
			type = 'text',
			placeholder = '',
			error = false,
			errorText = '',
			value = '',
			description = '',
			horizontal = false,
			onChange = () => {},
			...newProps
		},
		ref,
	) => {
		// begin with base styles
		let finalClass = 'px-4 py-3 outline-none transition-colors duration-150 ease-in-out ';
		// add border default border if not supplied
		if (!className.includes('border')) {
			finalClass += `border rounded-sm ${
				error || errorText
					? 'border-red-500 border-2 border-opacity-75'
					: 'border-gray-300 focus:border-blue-400'
			}`;
		}
		// make full if full
		if (full) finalClass += ' w-full';
		finalClass += ` ${className}`;

		return (
			<div className={horizontal ? 'sm:flex sm:items-center' : ''}>
				{label && (
					<label
						className={`text-sm text-gray-600 ${error && 'text-primary'} ${
							horizontal && 'sm:w-24'
						}`}
						htmlFor={name}>
						{label}
					</label>
				)}
				<div className={horizontal ? 'sm:flex-1' : ''}>
					<input
						ref={ref}
						type={type}
						name={name}
						label={adaTitle}
						className={finalClass}
						placeholder={placeholder}
						value={value}
						onChange={(e) => onChange(e.currentTarget.value)}
						{...newProps}
					/>
					{description && <span className="mt-2 text-gray-600 text-xs">{description}</span>}
					{errorText && (
						<div className="bg-red-200 mt-2 py-2 px-4 text-xs text-primary rounded-sm">
							{errorText}
						</div>
					)}
				</div>
			</div>
		);
	},
);

InputGroup.displayName = 'InputGroup';

InputGroup.propTypes = {
	adaTitle: PropTypes.string,
	className: PropTypes.string,
	description: PropTypes.string,
	error: PropTypes.bool,
	errorText: PropTypes.string,
	full: PropTypes.bool,
	horizontal: PropTypes.bool,
	label: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	type: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default InputGroup;
