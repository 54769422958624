import Clickable from '/components/Clickable';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '../../../utils/class-names';
import { getItemHref } from '../utils';

const NavLink = ({ item, resetMenu, showShopAll = false, variant }) => {
	const typographyVariant = variant === 'plain' ? 'body' : 'h4';

	const href = getItemHref(item);

	return (
		<Clickable
			heapEventName="Mobile Navigation Link Click"
			heapEventData={{
				Href: href,
				Item: item.title,
			}}
			linkToInternal={href}
			onClick={() => {
				resetMenu();
			}}>
			<Typography
				className={classNames(
					'capitalize p-3',
					variant === 'plain' && 'pl-10',
					variant !== 'plain' && 'font-semibold pl-6 mt-1 text-primary-dark',
				)}
				component="div"
				variant={typographyVariant}>
				{showShopAll && `Shop All ${item.title}`}
				{!showShopAll && `${item.title}`}
			</Typography>
		</Clickable>
	);
};

NavLink.propTypes = {
	item: PropTypes.object,
	resetMenu: PropTypes.func.isRequired,
	showShopAll: PropTypes.boolean,
	variant: PropTypes.string,
};

export default NavLink;
