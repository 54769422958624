import { decodeGid } from '/utils/graph-ql';
import { getFirstProperty } from '/utils/array';

const transformAnalyticsDataProduct = (analyticsDataProduct) => {
	const formatted = {
		price: analyticsDataProduct.price,
		shopifyVariantId: analyticsDataProduct.variantId,
	};

	if (analyticsDataProduct.product) {
		if (analyticsDataProduct.product.images) {
			formatted.image = getFirstProperty(analyticsDataProduct.product, 'images', 'src');
		}
		formatted.productType = analyticsDataProduct.product.productType;
		formatted.shopifyProductId = decodeGid(String(analyticsDataProduct.product.id));
		formatted.title = analyticsDataProduct.product.title?.trim() || '';
	}

	return formatted;
};

export default transformAnalyticsDataProduct;
