import { getCountryByCode } from '../services/static/countries';

export const formatMonetaryValue = (value, country) => {
	if (!value && value !== 0) {
		return '';
	}

	if (!country) {
		country = getCountryByCode('US');
	}

	return Intl.NumberFormat(country.localeCode, {
		maximumFractionDigits: 2,
		minimumFractionDigits: 2,
	}).format(value);
};

export const formatPercentageValue = (value, decimals, multiply) => {
	if (multiply) {
		value = value * 100;
	}

	value = decimals === true ? value.toFixed(2) : value.toFixed(0);

	return `${value.toLocaleString()}%`;
};

export const formatNumber = (value) => {
	const parsed = parseFloat(value);

	if (Number.isInteger(parsed)) {
		return parseInt(parsed).toLocaleString();
	}

	if (!Number.isNaN(parsed)) {
		return parsed.toFixed(2).toLocaleString();
	}

	return value;
};

export const titleCase = (str) => {
	if (!str) {
		return '';
	}
	return str
		.toLowerCase()
		.split(' ')
		.map(function (word) {
			return word.charAt(0).toUpperCase() + word.slice(1);
		})
		.join(' ');
};
