import Clickable from '/components/Clickable';
import Image from '/components/Image';
import Loading from '/components/Loading';
import Price from './Price';
import ProductType from '/components/products/show/product-form/ProductType';
import PropTypes from 'prop-types';
import SelectGroup from '/components/SelectGroup';
import Typography from '/components/Typography';
import { checkProductTags } from '/utils/product-tags';
import classNames from '/utils/class-names';
import { getRecommendedProducts } from '/services/products';
import { logTryCatch } from '/utils/logging';
import { reactChildren } from '/utils/prop-types';
import { useCartStore } from '/state/cart-context';
import { getProductOptions, linesFromVariants } from '/utils/product';
import { useEffect, useState } from 'react';

function processRecommendedProducts(products, sourceProduct) {
	const processedProducts = [];

	const invalidProductTypes = [
		'Shipping Protection',
		'Subscription',
		'Bow Ties',
		'Ties',
		'Suit Pants',
	];

	products.forEach((product) => {
		const sourceProductTitle =
			sourceProduct.titles && sourceProduct?.titles[0]?.split('|')[0].trim();
		const logic = [
			product.productType == "Men's Shorts"
				? product?.titles[0]?.includes(sourceProductTitle)
					? false
					: true
				: true,
			sourceProduct.productType == 'Blazers'
				? product?.titles[0]?.includes(sourceProductTitle)
					? false
					: true
				: true,
			invalidProductTypes.includes(product.productType) ? false : true,
			checkProductTags(product.tags, 'Underwear Packs') ? false : true,
			product?.sizeCurveWeight?.value < 2 ? false : true,
		];
		let includeProduct = true;
		logic.every((item) => {
			includeProduct = item;
			if (includeProduct == false) {
				return false;
			} else {
				return true;
			}
		});

		if (includeProduct) {
			processedProducts.push(product);
		}
	});
	return processedProducts;
}

const CarouselProduct = ({ addToCart, onClick, product, sizeSelected, strategyInfo }) => {
	const [selectedOption, setSelectedOption] = useState('');
	const [selectedTitle, setSelectedTitle] = useState();
	const [addToCartLoading, setAddToCartLoading] = useState(false);

	useEffect(() => {
		async function fetchVariants() {
			const variants = await getProductOptions(product);
			const foundVariant = variants.find(
				(variant) => variant.label == sizeSelected && variant.disabled == false,
			);

			const option = variants.length == 1 ? variants[0]?.value : foundVariant?.value;
			setSelectedOption(option || '');
		}
		if (product) {
			fetchVariants();
		}
	}, [product]);

	if (!product) {
		return null;
	}
	return (
		<div className="basis-[45%] mx-1 flex flex-col">
			<Clickable
				linkToInternal={product.handle}
				heapEventName="Recommended product click"
				onClick={onClick}
				heapEventData={{
					product: product.handle,
				}}>
				<div className="text-center">
					<Image
						src={product.images[0].src || product.images[0].originalSrc}
						alt={product.title}
						height={120}
						width={120}
						objectFit="contain"
					/>
				</div>
			</Clickable>
			<div className="flex flex-col justify-between w-full flex-auto">
				<Typography
					variant="small"
					component="div"
					className="flex-auto text-center flex items-start justify-center">
					{product.title.split(' | ')[0]}
				</Typography>
				<div className="text-center flex items-center align-middle justify-center">
					<ProductType productType={product.productType} />
				</div>
				<div className="flex-initial">
					<Typography className="text-center mt-4 mb-1" component="div" variant="body">
						<Price format={'explicit'} price={product.prices[0]} product={product} />
					</Typography>
					{product.variants && product.variants.length > 1 && (
						<SelectGroup
							value={selectedOption || 'x-placeholder-x'}
							placeholder="Select..."
							variant="short"
							name="Options"
							options={getProductOptions(product)}
							onChange={(val, title) => {
								setSelectedOption(val);
								setSelectedTitle(title);
							}}
						/>
					)}
					<div className="mt-1">
						<Clickable
							size="sm"
							disabled={!selectedOption}
							onClick={() => {
								setAddToCartLoading(true);
								addToCart(
									product,
									selectedOption,
									selectedTitle,
									setAddToCartLoading,
									strategyInfo,
								);
							}}
							variant="filled"
							className="flex items-center justify-center w-full"
							heapEventData={{
								Product: product?.handle,
								Price: product.prices[0],
							}}
							heapEventName="Product Page Upsell Add On">
							<Typography component="p" variant="body">
								Add on
							</Typography>
							{addToCartLoading && <Loading className="ml-2" height="10px" width="10px" />}
						</Clickable>
					</div>
				</div>
			</div>
		</div>
	);
};

export default function CustomersAlsoBoughtTwo({
	header,
	location,
	onClick,
	product,
	sizeSelected,
}) {
	const [products, setProducts] = useState([]);

	const cart = useCartStore();

	useEffect(() => {
		async function getProducts() {
			if (!product.id) {
				return;
			}

			const products = await getRecommendedProducts(product.id);
			setProducts(processRecommendedProducts(products, product));
		}
		getProducts();
	}, [product.id]);

	const addToCart = async (product, variantId, selectedTitle, setAddToCartLoading) => {
		const line = {
			variantId,
			quantity: 1,
		};

		const lines = linesFromVariants([line]);
		const analyticsData = {
			price: product?.prices[0],
			product: {
				id: product.id,
				images: [{ src: product?.images[0]?.originalSrc }],
				prices: product.prices,
				productType: product.productType,
				title: product.titles[0],
				vendor: product.brand || 'Shinesty',
			},
			quantity: 1,
		};

		const decodedVariantId = variantId;
		const variant = product.variants.find((variant) => {
			return variant.id && variant.id.toString() === decodedVariantId;
		});

		if (variant) {
			analyticsData.variantId = variant.id;
			analyticsData.variantSku = variant.sku;
			analyticsData.variantTitle = selectedTitle;
		}

		await cart
			.addItems({
				analytics: [analyticsData],
				error: () => {
					setAddToCartLoading(false);
				},
				lines: lines,
				success: () => {
					setAddToCartLoading(false);
				},
			})
			.catch((e) => {
				setAddToCartLoading(false);
				logTryCatch(e);
			});
	};
	const productToShow = location == 'popup' ? 3 : 2;
	if (products.length === 0) {
		return null;
	}
	return (
		<div className="text-center">
			{header && <>{header}</>}
			<div
				className={classNames(
					'flex',
					location === 'popup' ? 'flex-wrap md:flex-nowrap justify-center' : '',
				)}>
				{products.map((product, i) => {
					if (i > productToShow) {
						return null;
					}
					return (
						<CarouselProduct
							product={product}
							key={i}
							onClick={onClick}
							addToCart={addToCart}
							sizeSelected={sizeSelected}
						/>
					);
				})}
			</div>
		</div>
	);
}

CustomersAlsoBoughtTwo.propTypes = {
	header: reactChildren,
	location: PropTypes.string,
	onClick: PropTypes.func,
	product: PropTypes.object,
	sizeSelected: PropTypes.string,
};

CarouselProduct.propTypes = {
	addToCart: PropTypes.func,
	isLoading: PropTypes.bool,
	onClick: PropTypes.func,
	position: PropTypes.number,
	product: PropTypes.object,
	products: PropTypes.object,
	sizeSelected: PropTypes.string,
	sourceProduct: PropTypes.object,
	strategyInfo: PropTypes.object,
	widgetInfo: PropTypes.object,
};
