import LoadingBar from 'react-top-loading-bar';
import { observer } from 'mobx-react-lite';
import { useGlobalStore } from '/state/global-context';

import { useEffect, useRef } from 'react';

const ShinestyLoadingBar = () => {
	const globalStore = useGlobalStore();
	const ref = useRef(null);

	useEffect(() => {
		if (globalStore.showLoadingBar === true) {
			ref.current.continuousStart();
		} else {
			ref.current.complete();
		}
	}, [globalStore.showLoadingBar]);

	return <LoadingBar color="#FABC3C" ref={ref} />;
};

export default observer(ShinestyLoadingBar);
