import Script from 'next/script';
import { useRouter } from 'next/router';

import { readLocalStorage, removeLocalStorageValue, writeLocalStorage } from '/utils/local-storage';
import { useEffect, useState } from 'react';

const TAG_MANAGER_STORAGE_NAME = 'disableTagManager';

const isTagManagerDisabled = (query = {}) => {
	// disable GTM with query param ?disableTagManager=true;
	// re-enable with query param ?disableTagManager=false;
	const onServer = typeof window === 'undefined';

	const cookie = onServer ? null : readLocalStorage(TAG_MANAGER_STORAGE_NAME);

	return (
		query[TAG_MANAGER_STORAGE_NAME] !== 'false' &&
		(cookie === 'true' || query[TAG_MANAGER_STORAGE_NAME] === 'true')
	);
};

const IncludedScripts = () => {
	const router = useRouter();
	const [tagManagerDisabled, setTagManagerDisabled] = useState(true);

	useEffect(() => {
		const { query } = router;
		const disableTagManager = isTagManagerDisabled(query);

		if (disableTagManager) {
			console.log('Google Tag Manager is disabled.');
			writeLocalStorage(TAG_MANAGER_STORAGE_NAME, 'true');
			setTagManagerDisabled(true);
		} else {
			removeLocalStorageValue(TAG_MANAGER_STORAGE_NAME);
			setTagManagerDisabled(false);
		}
	}, []);

	if (tagManagerDisabled) {
		return null;
	}

	return (
		<>
			{/* Migrate after we finish GA4 */}
			<Script
				id="google-tag-manager"
				strategy="afterInteractive"
				onError={(e) => {
					console.error('Google Tag Manager (1) script failed to load', e);
				}}>
				{`
					try {
						(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
						new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
						j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
						'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
						})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');
					} catch(e) {
						console.log('Could not init google tag manager.');
					}
				`}
			</Script>

			{/*<Script*/}
			{/*	strategy="afterInteractive"*/}
			{/*	src="https://www.googletagmanager.com/gtag/js?id=G-99C5J4EW70"*/}
			{/*	onError={(e) => {*/}
			{/*		console.error('Google Tag Manager (2) script failed to load', e);*/}
			{/*	}}*/}
			{/*/>*/}

			<Script
				id="gtm"
				onError={(e) => {
					console.error('Google Tag Manager (3) script failed to load', e);
				}}>
				{` 
					try {
						window.dataLayer = window.dataLayer || [];
						function gtag(){dataLayer.push(arguments);}
						gtag('js', new Date());
						gtag('config', 'G-99C5J4EW70');
					} catch(e) {
						console.log('Could not init gtm.');
					}
				`}
			</Script>

			<Script
				type="text/javascript"
				id="retention.com script"
				onError={(e) => {
					console.error('Retention.com script failed to load', e);
				}}>
				{`
					try {
						!function(){var geq=window.geq=window.geq||[];if(geq.initialize) return;if (geq.invoked){if (window.console && console.error) {console.error("GE snippet included twice.");}return;}geq.invoked = true;geq.methods = ["page", "suppress", "trackOrder", "identify", "addToCart", "callBack", "event"];geq.factory = function(method){return function(){var args = Array.prototype.slice.call(arguments);args.unshift(method);geq.push(args);return geq;};};for (var i = 0; i < geq.methods.length; i++) {var key = geq.methods[i];geq[key] = geq.factory(key);}geq.load = function(key){var script = document.createElement("script");script.type = "text/javascript";script.async = true;if (location.href.includes("vge=true")) {script.src = "https://s3-us-west-2.amazonaws.com/jsstore/a/" + key + "/ge.js?v=" + Math.random();} else {script.src = "https://s3-us-west-2.amazonaws.com/jsstore/a/" + key + "/ge.js";}var first = document.getElementsByTagName("script")[0];first.parentNode.insertBefore(script, first);};geq.SNIPPET_VERSION = "1.6.1";
						geq.load("NXGHGVY");}();
					} catch(e) {
						console.log('Could not init retention.com');
					}
				`}
			</Script>

			<Script
				type="text/javascript"
				id="everflow script"
				onError={(e) => {
					console.error('Everflow script failed to load', e);
				}}>
				{`
					var script = document.createElement('script');
					script.src = "https://www.lpjjoki7htrk.com/scripts/sdk/everflow.js";
					script.defer = true;
					script.onload = function() {
						EF.configure({tld: "shinesty.com"});
						EF.click({
						offer_id: EF.urlParameter("oid"),
						affiliate_id: EF.urlParameter("affid"),
						sub1: EF.urlParameter("sub1"),
						sub2: EF.urlParameter("sub2"),
						sub3: EF.urlParameter("sub3"),
						sub4: EF.urlParameter("sub4"),
						sub5: EF.urlParameter("sub5"),
						uid: EF.urlParameter("uid"),
						source_id: EF.urlParameter("source_id"),
						transaction_id: EF.urlParameter("_ef_transaction_id")
					});
					}
					document.body.append(script);
				`}
			</Script>

			<Script id="axon-pixel" strategy="afterInteractive">
				{`
					var AXON_EVENT_KEY="60245b16-5064-471f-ae4a-df28cdae9923";
					!function(e,r){var t=["https://s.axon.ai/pixel.js","https://c.albss.com/p/l/loader.iife.js"];if(!e.axon){var a=e.axon=function(){a.performOperation?a.performOperation.apply(a,arguments):a.operationQueue.push(arguments)};a.operationQueue=[],a.ts=Date.now(),a.eventKey=AXON_EVENT_KEY;for(var n=r.getElementsByTagName("script")[0],o=0;o<t.length;o++){var i=r.createElement("script");i.async=!0,i.src=t[o],n.parentNode.insertBefore(i,n)}}}(window,document);
					axon("init");
				`}
			</Script>

			<Script
				type="text/javascript"
				src="https://api.goaffpro.com/loader.js?shop=shinesty.myshopify.com"
				id="affpro script"
				onError={(e) => {
					console.error('affpro script failed to load', e);
				}}></Script>

			<Script
				id="retention.com pageview"
				onError={(e) => {
					console.error('Retention page view script failed to load', e);
				}}>{`
				try {
					geq.page();
				} catch(e) {
					console.log('Could not track retention.page');
				}
			`}</Script>

			<Script
				id="heap-sitewide"
				type="text/javascript"
				script-src="https://cdn.heapanalytics.com https://heapanalytics.com"
				unsafe-inline="true"
				unsafe-eval="true"
				img-src="https://heapanalytics.com"
				style-src="https://heapanalytics.com"
				connect-src="https://heapanalytics.com"
				font-src="https://heapanalytics.com"
				onError={(e) => {
					console.error('Heap script failed to load', e);
				}}>
				{`
					try {
						window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=t.forceSSL||"https:"===document.location.protocol,a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src=(r?"https:":"http:")+"//cdn.heapanalytics.com/js/heap-"+e+".js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(a,n);for(var o=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],c=0;c<p.length;c++)heap[p[c]]=o(p[c])};
						heap.load("${process.env.NEXT_PUBLIC_HEAP_ENVIRONMENT}");
					} catch(e) {
						console.log('Could not init heap.');
					}
				`}
			</Script>

			<Script
				id="hide-gorgias-branding"
				onError={(e) => {
					console.error('Gorgias branding script failed to load', e);
				}}>
				{`
					try {
						var HIDE_POWERED_BY_GORGIAS = true;
					} catch(e) {
						console.log('Could not hide gorgias branding');
					}
				`}
			</Script>

			<Script
				id="gorgias-sitewide"
				strategy="afterInteractive"
				src="https://config.gorgias.chat/bundle-loader/01GYCBPS1254CMQ5NFZD08W6Q4"
				onError={(e) => {
					console.error('Gorgias Sitewide script failed to load', e);
				}}
			/>

			<Script
				id="brandlock"
				async
				src="//dr4wy5fd9sc82.cloudfront.net/8d7d9827.js"
				onError={(e) => {
					console.error('Brandlock script failed to load', e);
				}}
			/>

			<Script
				id="northbeam"
				onError={(e) => {
					console.error('Northbeam script failed to load', e);
				}}>{`
				try {
					var r;(e=r=r||{}).A="identify",e.B="trackPageView",e.C="fireEmailCaptureEvent",e.D="fireCustomGoal",e.E="firePurchaseEvent";var e="//j.northbeam.io/ota-sp/7475564f-ef98-45c8-88d4-8f38e7949c69.js";function t(e){for(var n=[],r=1;r<arguments.length;r++)n[r-1]=arguments[r];a.push({fnName:e,args:n})}var a=[],n=((n={_q:a})[r.A]=function(e,n){return t(r.A,e,n)},n[r.B]=function(){return t(r.B)},n[r.C]=function(e,n){return t(r.C,e,n)},n[r.D]=function(e,n){return t(r.D,e,n)},n[r.E]=function(e){return t(r.E,e)},window.Northbeam=n,document.createElement("script"));n.async=!0,n.src=e,document.head.appendChild(n);
				} catch(e) {
					console.log('Could not init northbeam.');
				}	
			`}</Script>

			<Script
				id="sharesale affiliate"
				src="https://www.dwin1.com/49179.js"
				type="text/javascript"
				defer="defer"
				onError={(e) => {
					console.error('Share-a-sale script failed to load', e);
				}}
			/>

			<Script
				id="grin"
				onError={(e) => {
					console.error('Grin script failed to load', e);
				}}>{`
				try {
					(function () {Grin = window.Grin || (window.Grin = []);var s = document.createElement('script');s.type = 'text/javascript';s.async = true;s.src = 'https://d38xvr37kwwhcm.cloudfront.net/js/grin-sdk.js';var x = document.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);})();
				} catch(e) {
					console.log('Could not init grin');
				}
			`}</Script>

			<Script
				id="audacy"
				onError={(e) => {
					console.error('Audacy script failed to load', e);
				}}>{`
				var ordnumber = Math.random() * 10000000000000;
				var sscUrl = ("https:" == document.location.protocol ? "https://" : "http://") + "trkn.us/pixel/c?ppt=24332&g=sitewide&gid=60336&ord="+ordnumber+"&v=120";
				var x = document.createElement("IMG");
				x.setAttribute("src", sscUrl);
				x.setAttribute("width", "1");
				x.setAttribute("height", "1");
				document.body.appendChild(x);
			`}</Script>
		</>
	);
};

export default IncludedScripts;
