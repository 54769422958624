import Algolia from '/services/analytics/algolia';
import Axon from './axon';
import Blotout from './blotout';
import Everflow from './everflow';
import Tatari from './tatari';
import Wunderkind from './wunderkind/index';
import transformAlgoliaProduct from './helpers/transform-algolia-product';
import transformAnalyticsDataProduct from './helpers/transform-analytics-data-product';
import transformCartLine from './helpers/transform-cart-line';
import transformShopifyProduct from './helpers/transform-shopify-product';

const init = () => {
	Axon.trackPageView();
	Blotout.trackPageView();
	Tatari.trackPageView();
};

const trackAddToCart = (
	lines = [],
	analyticsDataProducts = [],
	analyticsProduct,
	checkoutUrl,
	cartId,
) => {
	const products = analyticsDataProducts.map((analyticsDataProduct) => {
		return transformAnalyticsDataProduct(analyticsDataProduct);
	});

	const lineProducts = lines.map((line) => {
		return transformCartLine(line, products);
	});

	if (checkoutUrl || cartId) {
		const cartData = lineProducts.reduce(
			(acc, item) => {
				acc.totalQuantity += item.quantity;
				acc.totalPrice += parseFloat(item?.price);
				return acc;
			},
			{ totalQuantity: 0, totalPrice: 0 },
		);
		Wunderkind.trackCartUpdate(cartId, cartData.totalPrice, cartData.totalQuantity, checkoutUrl);
	}

	Axon.trackAddToCart(lineProducts);
	Blotout.trackAddToCart(lineProducts, checkoutUrl);
	Everflow.trackAddToCart();
	Tatari.trackAddToCart(lineProducts);
	Wunderkind.trackAddToCart(lineProducts);
	Algolia.trackAddToCart(lineProducts);
};

// eslint-disable-next-line no-unused-vars
const trackAddToPack = (products, variant) => {
	//to do: what do we do with add to pack?
	// const payload = {};
	// Blotout.trackAddToCart(payload);
	// Wunderkind.trackAddToPack(payload);
};

const trackBackInStock = (product) => {
	Wunderkind.trackBackInStock(product);
};

const trackCartEmpty = () => {
	Wunderkind.trackCartEmpty();
};

const trackFilterClick = (selections = []) => {
	Blotout.trackFilterClick(selections);
};

const trackInitiateCheckout = (cart) => {
	Tatari.trackInitiateCheckout(cart);
};

const trackLead = (email = '', phone = '') => {
	Blotout.trackLead(email, phone);
};

const trackPageView = () => {
	Axon.trackPageView();
	Blotout.trackPageView();
	Tatari.trackPageView();
};

const trackProductClick = (payload) => {
	const formatted = transformAlgoliaProduct(payload.product);
	Blotout.trackProductClick([formatted]);
	Algolia.trackProductClick(formatted, payload.rank);
};

const trackRemoveFromCart = (lines) => {
	const formatted = lines.map((line) => {
		return transformShopifyProduct({ ...line.merchandise.product, price: line.price });
	});

	Blotout.trackRemoveFromCart(formatted);
};

const trackSearchPage = (products, searchTerm) => {
	const formattedProducts = products.map((algoliaProduct) => {
		return transformAlgoliaProduct(algoliaProduct);
	});
	Blotout.trackSearch(products, searchTerm);
	Wunderkind.trackViewItemList(formattedProducts);
};

const trackSubscriptionSelect = (subscriptionType) => {
	Wunderkind.trackSubscriptionSelect(subscriptionType);
};

const trackVariantSelect = (productId, variantId) => {
	Wunderkind.trackVariantSelect(productId, variantId);
};

const trackProductView = (products, isSubscription = false, estimatedValue = 0) => {
	const formattedProducts = products.map((product) => {
		return transformShopifyProduct(product);
	});
	Axon.trackViewItem(formattedProducts);
	Blotout.trackViewItem(formattedProducts);
	Tatari.trackViewItem(formattedProducts);
	Wunderkind.trackViewItem(formattedProducts, isSubscription, estimatedValue);
};

const trackViewItemList = (algoliaProducts = [], collectionHandle = '') => {
	const formattedProducts = algoliaProducts.map((algoliaProduct) => {
		return transformAlgoliaProduct(algoliaProduct);
	});

	Blotout.trackViewItemList(formattedProducts);
	Wunderkind.trackViewItemList(formattedProducts, collectionHandle);
};

const trackViewMiniCart = (payload = {}) => {
	Blotout.trackViewCart(payload);
	// Wunderkind.trackViewCart(payload);
};

module.exports = {
	init,
	trackAddToCart,
	trackAddToPack,
	trackBackInStock,
	trackCartEmpty,
	trackFilterClick,
	trackInitiateCheckout,
	trackLead,
	trackPageView,
	trackProductClick,
	trackProductView,
	trackRemoveFromCart,
	trackSearchPage,
	trackSubscriptionSelect,
	trackVariantSelect,
	trackViewItemList,
	trackViewMiniCart,
};
