import PropTypes from 'prop-types';
import React from 'react';
import { reactChildren } from '/utils/prop-types';

const CartContext = React.createContext(null);

export const CartProvider = ({ value, children }) => {
	return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export const useCartStore = () => React.useContext(CartContext);

CartProvider.propTypes = {
	children: reactChildren,
	value: PropTypes.object,
};
