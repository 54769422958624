import { CURRENCY } from './constants';
import { formatProducts } from './formatters';
import sendEvent from './send-event';

const trackInitiateCheckout = (payload, subtotal, checkoutUrl) => {
	const products = formatProducts(payload.ecommerce?.checkout?.products);

	sendEvent('InitiateCheckout', {
		checkoutUrl: checkoutUrl,
		contents: products?.contents,
		currency: CURRENCY,
		value: subtotal,
	});
};

export default trackInitiateCheckout;
