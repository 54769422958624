import PropTypes from 'prop-types';

const Badge = ({
	className = '',
	text = '',
	color = 'primary',
	variant,
	onClose = null,
	...rest
}) => {
	let finalClass = `${className} rounded-full font-medium`;

	if (variant === 'mini') {
		finalClass += ' px-1.5 text-xs';
	} else if (variant === 'cart') {
		finalClass += ' px-1.5 text-xs animate-bounce';
	} else {
		finalClass += ' py-1 px-3 text-sm';
	}

	if (color === 'primary') finalClass += ' text-primary-content bg-primary';
	else if (color === 'secondary') finalClass += ' text-secondary-content bg-secondary';
	else if (color === 'success') finalClass += ' text-white bg-green-600';
	else if (color === 'danger') finalClass += ' text-white bg-red-600';
	else if (color === 'warning') finalClass += ' text-black bg-yellow-400';
	else if (color === 'info') finalClass += ' text-white bg-indigo-300';
	else if (color === 'light') finalClass += ' text-black bg-gray-100 border border-gray-300';
	else if (color === 'dark') finalClass += ' text-white bg-gray-900';
	return (
		<span className={finalClass} {...rest}>
			{onClose && (
				<button className="mr-2 text-base cursor-pointer" onClick={onClose}>
					&times;
				</button>
			)}
			{text}
		</span>
	);
};

Badge.propTypes = {
	className: PropTypes.string,
	color: PropTypes.string,
	onClose: PropTypes.func,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	variant: PropTypes.string,
};

export default Badge;
