import PropTypes from 'prop-types';

import classNames from '/utils/class-names';

import { reactChildren } from '/utils/prop-types';

const ProductCardWrapper = ({
	children,
	className = '',
	onMouseLeave,
	onMouseOver,
	showQuickAdd,
	style = {},
}) => {
	return (
		<li
			className={classNames(
				'inline-flex  md:w-64 flex-col lg:w-auto ',
				className,
				showQuickAdd ? 'shadow-lg rounded-t-lg' : 'rounded-md md:rounded-lg',
			)}
			onMouseOver={onMouseOver}
			onMouseLeave={onMouseLeave}
			style={style}>
			{children}
		</li>
	);
};

ProductCardWrapper.propTypes = {
	children: reactChildren,
	className: PropTypes.string,
	onMouseLeave: PropTypes.func,
	onMouseOver: PropTypes.func,
	showQuickAdd: PropTypes.bool,
	style: PropTypes.object,
};

export default ProductCardWrapper;
