import { CURRENCY } from './constants';
import { formatProducts } from './formatters';
import sendEvent from './send-event';

const trackProductClick = (products) => {
	const formattedData = formatProducts(products);

	sendEvent('ProductClick', {
		contents: formattedData.contents,
		currency: CURRENCY,
		value: formattedData.totalValue,
	});
};

export default trackProductClick;
